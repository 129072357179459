import styled from "styled-components";

export default function BenefitsHomeCard({ children, marginRight }) {
    return <Card marginRight={marginRight}>{children}</Card>;
}

const Card = styled.div`
    width: 340px;
    height: 155px;

    border-radius: 10px;
    margin-right: ${(props) => props.marginRight};

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    > img {
        width: 100%;
        object-fit: cover;

        border-radius: 10px;
    }
`;
