import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import axios from "axios";

import BalanceContext from "../../contexts/BalanceContext";
import SignInContext from "../../contexts/SignInContext";

import product from "../../assets/angels_imagens/popups/produto.svg";
import buy from "../../assets/angels_imagens/popups/comprar.svg";
import confirmedPurchase from "../../assets/angels_imagens/popups/processando_confirmada.svg";
import purchaseError from "../../assets/angels_imagens/popups/comprar_erro.svg";

import InsideBenefitHeader from "../../components/Benefits/InsideBenefitHeader";
import BenefitsHomeCard from "../../components/Benefits/BenefitsHomeCard";
import Container from "../../components/Container";
import Button from "../../components/Button";
import {
    NumberContainer,
    IntegerPart,
    DecimalSeparator,
    DecimalPart,
} from "../../components/PriceFormatting";
import OneButtonModal from "../../components/Modals/OneButtonModal";
import TwoButtonModal from "../../components/Modals/TwoButtonModal";

export default function Benefits() {
    const { benefitId, benefitType } = useParams();
    const navigate = useNavigate();
    const { setRefreshBalance } = useContext(BalanceContext);
    const { isLogged } = useContext(SignInContext);
    const carouselRef = useRef(null);
    const defaultScrollAmount = 400;

    const oneButtons = {
        delivery: {
            imageSrc: product,
            mainText: "Sobre a entrega",
            subText:
                "Após efetuar a compra você receberá uma mensagem por WhatsApp no seu número cadastrado.",
            buttonText: "Fechar",
            buttonWidth: "80%",
            buttonActionFunc: () => {
                setOneButtonModalIsOpen(false);
            },
        },
        processing: {
            imageSrc: "Bars",
            mainText: "Processando na chain",
            subText:
                "A sua ordem está sendo processada de maneira segura diretamente na blockchain descentralizada da Polygon.",
            buttonText: "",
            buttonWidth: "0px",
            buttonBackground: "#ffffff",
            hideX: true,
            hideButton: true,
        },
        confirmed: {
            imageSrc: confirmedPurchase,
            mainText: "Compra confirmada",
            subText:
                "Dentro de algumas horas entraremos em contato através do seu número cadastrado.",
            buttonText: "Voltar para a home",
            buttonWidth: "80%",
            buttonActionFunc: () => {
                navigate("/home");
            },
        },
        error: {
            imageSrc: purchaseError,
            mainText: "Saldo insuficiente",
            subText: "Compre mais LSB’s para poder realizar a sua compra. ",
            buttonText: "Ir para a corretora",
            buttonWidth: "80%",
            buttonActionFunc: () => {
                navigate("/market");
            },
        },
        sizeNotAvailable: {
            imageSrc: purchaseError,
            mainText: "Tamanho não disponível",
            subText:
                "Infelizmente esse tamanho não está disponível no momento. ",
            buttonText: "Fechar",
            buttonWidth: "80%",
            buttonActionFunc: () => {
                setOneButtonModalIsOpen(false);
            },
        },
    };

    const [benefit, setBenefit] = useState({});
    const [benefitIsLoading, setBenefitIsLoading] = useState(true);

    const [oneButtonModalIsOpen, setOneButtonModalIsOpen] = useState(false);
    const [twoButtonModalIsOpen, setTwoButtonModalIsOpen] = useState(false);

    const [oneButtonModalType, setOneButtonModalType] = useState("delivery");
    const [productOnFocus, setProductOnFocus] = useState(0);

    const [selectedSize, setSelectedSize] = useState(0);

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }

        const url = `${returnBackUrl()}/product/${benefitType}/${benefitId}`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const getBenefitById = axios.get(url, config);

        getBenefitById
            .then((res) => {
                setBenefit({ ...res.data });
                setBenefitIsLoading(false);
            })
            .catch(() => {
                setBenefitIsLoading(false);
            });
    }, []);

    function sendBenefitBuy() {
        setTwoButtonModalIsOpen(false);
        setOneButtonModalType("processing");
        setOneButtonModalIsOpen(true);

        const url = `${returnBackUrl()}/product/${benefitType}/${benefitId}`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        let consumeBenefitRequest = null;
        if (benefitType === "clothing") {
            const body = {
                size: returnSize(),
            };

            consumeBenefitRequest = axios.post(url, body, config);
        } else {
            consumeBenefitRequest = axios.post(url, {}, config);
        }

        consumeBenefitRequest
            .then((res) => {
                setOneButtonModalType("confirmed");
                setRefreshBalance((prevState) => !prevState);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOneButtonModalType("sizeNotAvailable");
                    return;
                }

                setOneButtonModalType("error");
            });
    }

    function FormattedNumber(value) {
        if (!value) {
            value = 0.0;
        }

        const [integerPart, decimalPart] = value.toString().split(".");

        return (
            <NumberContainer>
                <IntegerPart>{integerPart}</IntegerPart>
                <DecimalSeparator>.</DecimalSeparator>
                <DecimalPart>{decimalPart || "00"}</DecimalPart>
            </NumberContainer>
        );
    } //tks GPT4

    const scroll = (direction) => {
        if (direction === "left") {
            carouselRef.current.scrollBy({
                left: -defaultScrollAmount,
                behavior: "smooth",
            });
            setProductOnFocus((prevState) => {
                if (prevState > 0) {
                    return prevState - 1;
                } else {
                    return prevState;
                }
            });
        } else {
            carouselRef.current.scrollBy({
                left: defaultScrollAmount,
                behavior: "smooth",
            });
            setProductOnFocus((prevState) => {
                if (prevState !== benefit.images.length - 1) {
                    return prevState + 1;
                } else {
                    return prevState;
                }
            });
        }
    }; //tks GPT4

    function getButtonHiddenClass(direction) {
        if (!benefit.images || benefit.images.length === 0) return "hidden";

        if (direction === "left" && productOnFocus === 0) {
            return "hidden";
        }

        if (
            direction === "right" &&
            productOnFocus === benefit.images.length - 1
        ) {
            return "hidden";
        }

        return "";
    }

    function returnSize() {
        switch (selectedSize) {
            case "0":
                return "P";
            case "1":
                return "M";
            case "2":
                return "G";
            case "3":
                return "GG";
            default:
                return "P";
        }
    }

    return (
        <Container>
            <Interface>
                <InsideBenefitHeader benefit={benefit} />
                {benefitIsLoading || !benefit ? (
                    <Bars height="50px" color="#000000" />
                ) : (
                    <>
                        <ImagesCarousel>
                            <div className="carousel-container">
                                <button
                                    className={`carousel-button carousel-button-left black-button ${getButtonHiddenClass(
                                        "left"
                                    )}`}
                                    onClick={() => scroll("left")}
                                >
                                    &lt;
                                </button>

                                <div className="carousel" ref={carouselRef}>
                                    {benefit.images.length > 0 ? (
                                        benefit.images.map((item, index) => {
                                            return (
                                                <BenefitImage key={index}>
                                                    <img src={item.imageURL} />
                                                </BenefitImage>
                                            );
                                        })
                                    ) : (
                                        <>Nenhum produto encontrado</>
                                    )}
                                </div>

                                <button
                                    className={`carousel-button carousel-button-right black-button ${getButtonHiddenClass(
                                        "right"
                                    )}`}
                                    onClick={() => scroll("right")}
                                >
                                    &gt;
                                </button>
                            </div>
                        </ImagesCarousel>

                        <BenefitInfoSection marginTop="50px">
                            <h1>LSB {FormattedNumber(benefit.price)}</h1>
                            <Details
                                onClick={() => {
                                    setOneButtonModalIsOpen(true);
                                    setOneButtonModalType("delivery");
                                }}
                            >
                                Mais detalhes
                            </Details>
                        </BenefitInfoSection>

                        <BenefitInfoSection marginTop="10px">
                            <h2>{benefit.description}</h2>
                        </BenefitInfoSection>

                        {benefitType === "clothing" ? (
                            <SizeSelect>
                                <select
                                    value={selectedSize}
                                    onChange={(e) =>
                                        setSelectedSize(e.target.value)
                                    }
                                >
                                    <option value="0">P</option>
                                    <option value="1">M</option>
                                    <option value="2">G</option>
                                    <option value="3">GG</option>
                                </select>
                            </SizeSelect>
                        ) : (
                            <> </>
                        )}

                        <Button
                            background="#0069F4"
                            border="1px solid #0069F4"
                            text="COMPRAR"
                            fontColor="#ffffff"
                            onClickFunc={() => setTwoButtonModalIsOpen(true)}
                        />
                    </>
                )}
            </Interface>

            <OneButtonModal
                modalIsOpen={oneButtonModalIsOpen}
                setModalIsOpen={setOneButtonModalIsOpen}
                modalInfo={oneButtons[oneButtonModalType]}
            />

            <TwoButtonModal
                modalIsOpen={twoButtonModalIsOpen}
                setModalIsOpen={setTwoButtonModalIsOpen}
                buttonActionFunc
                modalInfo={{
                    imageSrc: buy,
                    whiteButtonText: "cancelar",
                    blueButtonText: "Confirmar",
                    whiteButtonFunc: () => {
                        setTwoButtonModalIsOpen(false);
                    },
                    blueButtonFunc: sendBenefitBuy,
                    mainText: "Confirmar compra",
                    subText: `Deseja confirmar a sua compra por ${
                        benefit.price || 0
                    } LSBs?`,
                }}
            />
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 100px;
`;

const BenefitInfoSection = styled.div`
    width: 340px;
    height: auto;

    margin: 10px 0;
    margin-top: ${(props) => props.marginTop};

    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
    }

    h2 {
        width: 100%;

        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000000;

        word-wrap: break-word;
        overflow-wrap: break-word;
    }
`;

const Details = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 109px;
    height: 27px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #000000;
`;

const ImagesCarousel = styled.div`
    width: 100%;

    overflow-x: scroll;
`;

const BenefitImage = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    img {
        width: 340px;
        height: 480px;
        object-fit: cover;
        border-radius: 10px;
    }

    & + & {
        margin-left: 20px;
    }
`;

const SizeSelect = styled.div`
    width: 100%;
    height: 50px;

    margin-top: 10px;
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 12px;

    padding: 0 10px;

    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;

    select {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        font-size: 15px;
        color: #000000;
    }

    select:focus {
        outline: none;
    }

    option {
        font-size: 15px;
        color: #000000;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 0 10px;
    }
`;
