import React from "react";
import styled from "styled-components";

export default function TextBox({
    children,
    width = "137px",
    height = "39px",
}) {
    return (
        <TextContainer width={width} height={height}>
            {children}
        </TextContainer>
    );
}

const TextContainer = styled.div`
    width: ${({ width }) => `${width}`};
    height: ${({ height }) => `${height}`};

    margin-bottom: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;

    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
`;
