import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import styled from "styled-components";
import { useState } from "react";
import { ReactSVG } from "react-svg";

import warning from "../../../assets/angels_imagens/vender/Suas vendas/icone_entrega_pendente.svg";
import check from "../../../assets/angels_imagens/vender/Suas vendas/icone_entregas_realizadas.svg";

export default function PendingSelector({ displayPending, setDisplayPending }) {
    const [open, setOpen] = useState(false);

    const options = [
        { text: "Entregas pendentes", image: warning, displayPending: true },
        { text: "Entregas realizadas", image: check, displayPending: false },
    ];

    return (
        <Container>
            <Visible onClick={() => setOpen((prevState) => !prevState)}>
                <h1>
                    {displayPending
                        ? "Entregas pendentes"
                        : "Entregas realizadas"}
                </h1>
                {open ? <AiOutlineUp /> : <AiOutlineDown />}
            </Visible>
            {open ? (
                <Invisible onClick={() => setOpen(false)}>
                    {options
                        .filter(
                            (option) => option.displayPending !== displayPending
                        )
                        .map((option, index) => {
                            return (
                                <Option
                                    key={index}
                                    onClick={() =>
                                        setDisplayPending(
                                            option.text === "Entregas pendentes"
                                                ? true
                                                : false
                                        )
                                    }
                                >
                                    <div>{option.text}</div>
                                    <ReactSVG
                                        src={option.image}
                                        beforeInjection={(svg) => {
                                            svg.setAttribute(
                                                "style",
                                                `width: 20px;height:20px;`
                                            );
                                        }}
                                    />
                                </Option>
                            );
                        })}
                </Invisible>
            ) : (
                <></>
            )}
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    min-height: 40px;
    background: #0069f4;
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 14px;

    position: relative;
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
`;

const Visible = styled.div`
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 15px;

    z-index: 2;

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;

        color: #ffffff;
    }

    > svg {
        color: #ffffff;
        font-size: 17px;
    }
`;

const Invisible = styled.div`
    padding-top: 50px;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    background: #0069f4;

    border-radius: 14px;
    z-index: 1;
`;

const Option = styled.div`
    height: 40px;
    width: 100%;
    background-color: #0069f4;
    padding: 0 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #ffffff;
    font-weight: 400;
    font-size: 15px;

    &:last-child {
        border-radius: 0 0 14px 14px;
    }
`;
