import logo from "../../assets/angels_imagens/logo_branca_angels.svg";
import TurnOff from "../../assets/angels_imagens/turn_off.svg";
import GoBack from "../../assets/angels_imagens/go_back.svg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

import { HeaderContainer } from "../Headers/HeaderContainer";

export default function MenuHeader({ setModalIsOpen }) {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <ReactSVG
                src={logo}
                beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 64px;height:64px;");
                }}
                onClick={() => {
                    navigate("/home");
                }}
            />

            <CommandsBox>
                <Circle onClick={() => setModalIsOpen(true)}>
                    <ReactSVG
                        src={TurnOff}
                        beforeInjection={(svg) => {
                            svg.setAttribute(
                                "style",
                                "width: 25px;height:25px;"
                            );
                        }}
                    />
                </Circle>

                <Circle
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <ReactSVG
                        src={GoBack}
                        beforeInjection={(svg) => {
                            svg.setAttribute(
                                "style",
                                "width: 25px;height:25px;"
                            );
                        }}
                    />
                </Circle>
            </CommandsBox>
        </HeaderContainer>
    );
}

const CommandsBox = styled.div`
    width: 90px;

    display: flex;
    justify-content: space-between;
`;

const Circle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0069f4;

    cursor: pointer;

    svg {
        transform: rotateY(180deg);
    }
`;
