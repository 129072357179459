import { IoIosAdd } from "react-icons/io";
import styled from "styled-components";
import { Bars } from "react-loader-spinner";

import logo from "../../../assets/angels_imagens/logo_azul_angels.svg";

import Container from "../../../components/Container";
import ArrowAndLogoHeader from "../../../components/Headers/ArrowAndLogoHeader";
import Button from "../../../components/Button";
import Episode from "../../../components/Marketplace/Courses/Episode";
import ErrorMessage from "../../../components/ErrorMessage";

export default function CourseCreateEpisodes({
    episodes,
    setEpisodes,
    setStep,
    confirmPublishCourse,
    errorMessage,
    setErrorMessage,
    requestIsLoading,
}) {
    function addEpisode() {
        let error = false;

        setErrorMessage("");

        episodes.map((episode, index) => {
            if (!episode.title) {
                setErrorMessage(
                    `O título do episódio ${index + 1} é obrigatório.`
                );
                error = true;
            } else if (!episode.description) {
                setErrorMessage(
                    `A descrição do episódio ${index + 1} é obrigatória.`
                );
                error = true;
            } else if (!episode.link) {
                setErrorMessage(
                    `O link do episódio ${index + 1} é obrigatório.`
                );
                error = true;
            }
        });

        if (!error) {
            setEpisodes([
                ...episodes,
                { name: "", description: "", videoLink: "" },
            ]);
        }
    }

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    logo={logo}
                    color={"#095ece"}
                    onClickFunc={() => {
                        setStep(0);
                    }}
                />

                <Button
                    text="Crie seu curso"
                    onClickFunc={() => {}}
                    background="#0069F4"
                    border="1px solid #0069F4"
                    fontColor="#FFFFFF"
                    width={"164px"}
                    height={"35px"}
                    fontSize={"17px"}
                />

                {episodes.map((episode, index) => (
                    <Episode
                        key={index}
                        index={index}
                        setEpisodes={setEpisodes}
                        episode={episode}
                    />
                ))}

                <AddEpisode onClick={addEpisode}>
                    <IoIosAdd size={17} />
                    <p>Adicionar mais um episódio</p>
                </AddEpisode>

                {errorMessage ? <ErrorMessage text={errorMessage} /> : <></>}

                <Divider />

                <Button
                    text={
                        requestIsLoading ? (
                            <Bars height="35px" color="#ffffff" />
                        ) : (
                            "Publicar curso"
                        )
                    }
                    onClickFunc={
                        requestIsLoading ? () => {} : confirmPublishCourse
                    }
                    background="#0069F4"
                    border="1px solid #0069F4"
                    fontColor="#FFFFFF"
                    fontSize={"18px"}
                />
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    margin-bottom: 30px;
`;

const AddEpisode = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 160px;
    height: 22.31px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 7px;
    font-weight: 400;
    font-size: 11px;

    margin-bottom: 20px;

    cursor: pointer;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 10px 0;
`;
