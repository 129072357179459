import logo from "../../assets/angels_imagens/logo_preta_angels.svg";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

export default function LogoHeader() {
    return (
        <SVGContainer>
            <ReactSVG src={logo} />
        </SVGContainer>
    );
}

const SVGContainer = styled.div`
    width: 100%;
    padding-top: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
`;
