import { useContext, useState, useEffect } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import axios from "axios";

import LogoHeader from "../../components/Headers/OnlyLogoHeader";
import Container from "../../components/Container";
import RequestCode from "./RequestCode";
import PasswordChange from "./PasswordChange";

import SignInContext from "../../contexts/SignInContext";

export default function PasswordRecovery() {
    const { isLogged, setIsLogged } = useContext(SignInContext);
    const navigate = useNavigate();

    const [page, setPage] = useState(0);

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (isLogged) {
            navigate("/home");
        }
    }, []);

    function requestCode() {
        setIsLoading(true);

        const body = {
            email,
        };

        const request = axios.post(`${returnBackUrl()}/password/code`, body);

        request
            .then((res) => {
                setIsLoading(false);
                setPage(1);
            })
            .catch((err) => {
                setIsLoading(false);
                setPage(1);
            });
    }

    function changePassword() {
        setIsLoading(true);

        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\!@#\$%\^&\*\(\)\-_=\+[\]{};':"\\|,.<>\/?]).{8,}$/;
        if (code === "") {
            setErrorMessage("Preencha o campo de código");
            setIsLoading(false);
            return;
        } else if (password === "") {
            setErrorMessage("Preencha o campo senha");
            setIsLoading(false);
            return;
        } else if (!passwordRegex.test(password)) {
            setErrorMessage("A senha deve cumprir todos os requisitos");
            setIsLoading(false);
            return;
        } else if (confirmPassword === "") {
            setErrorMessage("Preencha o campo confirmação de senha");
            setIsLoading(false);
            return;
        }

        const body = {
            email,
            code,
            password,
            confirmPassword,
        };

        const request = axios.put(`${returnBackUrl()}/password/change`, body);

        request
            .then((res) => {
                setIsLoading(false);
                navigate("/sign-in");
            })
            .catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.response.data);
            });
    }

    function returnPage() {
        switch (page) {
            case 0:
                return (
                    <RequestCode
                        email={email}
                        setEmail={setEmail}
                        isLoading={isLoading}
                        requestCode={requestCode}
                    />
                );
            case 1:
                return (
                    <PasswordChange
                        email={email}
                        code={code}
                        password={password}
                        confirmPassword={confirmPassword}
                        isLoading={isLoading}
                        setCode={setCode}
                        setPassword={setPassword}
                        setConfirmPassword={setConfirmPassword}
                        onClickFunc={changePassword}
                        setPage={setPage}
                        errorMessage={errorMessage}
                    />
                );

            default:
                return (
                    <RequestCode
                        email={email}
                        setEmail={setEmail}
                        isLoading={isLoading}
                        requestCode={requestCode}
                    />
                );
        }
    }

    return (
        <Container>
            <Interface>
                <LogoHeader />

                {returnPage()}
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;
    height: 70vh;

    display: flex;
    flex-direction: column;
`;
