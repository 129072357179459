import { returnBackUrl } from "../../../utils/backUrl";
import { Bars } from "react-loader-spinner";
import { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

import Button from "../../Button";
import ErrorMessage from "../../ErrorMessage";

export default function DeliverProductModal({
    product,
    setProduct,
    setRefresh,
}) {
    const [fullProductInfo, setFullProductInfo] = useState(null);
    const [deliverCode, setDeliverCode] = useState("");

    const [error, setError] = useState("");
    const [requestIsLoading, setRequestIsLoading] = useState(false);

    useEffect(() => {
        if (!product) return;

        const url = `${returnBackUrl()}/product/${product.type}/selling/${
            product.buyingId
        }`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.get(url, config);

        request
            .then((response) => {
                const productData = response.data[product.type];
                const buyerData = response.data.buyer;
                setFullProductInfo({
                    ...fullProductInfo,
                    productTitle: productData.title,
                    productDescription: productData.description,
                    productPrice: response.data.priceInLSB,
                    productImage: productData.coverImageURL,
                    buyerName: buyerData.fullName,
                    buyerPhone: buyerData.phone,
                    buyerEmail: buyerData.email,
                    buyDate: new Date(response.data.createdAt).toLocaleString(
                        "pt-BR",
                        {
                            timeZone: "America/Sao_Paulo",
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                        }
                    ),
                    clothingSize: response.data.size,
                    delivered: !Boolean(response.data.deliverCode),
                });
            })
            .catch((error) => {});
    }, [product]);

    function sendDeliverCode() {
        setRequestIsLoading(true);

        if (!deliverCode) {
            setError("Digite o código de entrega");
            return;
        }

        const url = `${returnBackUrl()}/product/${product.type}/delivery/${
            product.buyingId
        }`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.post(url, { deliverCode }, config);

        request
            .then(() => {
                setProduct(null);
                setDeliverCode("");
                setRefresh((prevState) => !prevState);
                setRequestIsLoading(false);
            })
            .catch((error) => {
                setError("Código inválido");
                setRequestIsLoading(false);
            });
    }

    return (
        <Modal
            isOpen={!!product}
            overlayClassName="modal-overlay"
            className="modal-content"
        >
            {fullProductInfo ? (
                <Container>
                    <ProductDescription>
                        <img
                            src={fullProductInfo.productImage}
                            alt="product cover"
                        />
                        <div>
                            <h1>{fullProductInfo.productTitle}</h1>
                            <p>{fullProductInfo.productDescription}</p>
                        </div>
                    </ProductDescription>

                    <BuyingData>
                        <div>
                            <p>
                                <span>Comprador:</span>{" "}
                                {fullProductInfo.buyerName}
                            </p>

                            <p>
                                <span>Email:</span> {fullProductInfo.buyerEmail}
                            </p>

                            <p>
                                <span>Telefone:</span>{" "}
                                {fullProductInfo.buyerPhone}
                            </p>

                            <p>
                                <span>Data:</span> {fullProductInfo.buyDate}
                            </p>
                        </div>
                        <div>
                            <p>
                                <span>Preço:</span>{" "}
                                {fullProductInfo.productPrice} LSBs
                            </p>

                            <p>
                                <span>Tamanho:</span>{" "}
                                {fullProductInfo.clothingSize || "-"}
                            </p>
                        </div>
                    </BuyingData>

                    <DeliveryData>
                        {fullProductInfo.delivered ? (
                            <>
                                <p>
                                    <span>Status: </span> Entregue
                                </p>
                                <Button
                                    onClickFunc={() => setProduct(null)}
                                    background="#0069F4"
                                    text={"Voltar"}
                                    fontColor="#FFFFFF"
                                />
                            </>
                        ) : (
                            <>
                                <CodeInput>
                                    <p>
                                        <span>Código de entrega:</span>
                                    </p>
                                    <input
                                        type="text"
                                        placeholder="Digite aqui o código"
                                        value={deliverCode}
                                        onChange={(e) =>
                                            setDeliverCode(e.target.value)
                                        }
                                    />
                                </CodeInput>

                                <ErrorBox>
                                    <ErrorMessage text={error} />
                                </ErrorBox>

                                <Buttons>
                                    <Button
                                        onClickFunc={() => setProduct(null)}
                                        background="#FFFFFF"
                                        border="1px solid #0069F4;"
                                        fontColor="#000000"
                                        width={"40%"}
                                        text={"voltar"}
                                    />
                                    <Button
                                        onClickFunc={sendDeliverCode}
                                        background="#0069F4"
                                        border="1px solid #0069F4;"
                                        fontColor="#ffffff"
                                        width={"55%"}
                                        text={
                                            requestIsLoading ? (
                                                <Bars
                                                    height="35px"
                                                    color="#ffffff"
                                                />
                                            ) : (
                                                "Confirmar"
                                            )
                                        }
                                    />
                                </Buttons>
                            </>
                        )}
                    </DeliveryData>
                </Container>
            ) : (
                <Loading>
                    <Bars height="55px" color="#000000" />
                </Loading>
            )}
        </Modal>
    );
}

const Loading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #000000;
    }

    span {
        font-weight: 700;
    }
`;

const ProductDescription = styled.div`
    width: 100%;
    height: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
        width: 100%;
        object-fit: cover;

        border-radius: 20px;
    }

    > div {
        width: 100%;

        margin-top: 10px;
    }

    h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        /* identical to box height */

        text-align: center;

        color: #000000;

        margin-bottom: 5px;
    }

    p {
        width: 100%;

        font-weight: 400;
        font-size: 11px;
        text-align: center;
        line-height: 14px;

        color: rgba(0, 0, 0, 0.6);

        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }
`;

const BuyingData = styled.div`
    width: 100%;
    height: 25%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    > div {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;

        padding: 0 2px;
    }

    > div:last-child {
        width: 40%;
    }
`;

const DeliveryData = styled.div`
    width: 100%;
    height: 25%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    input {
        width: 126px;
        height: 30px;

        background: #ffffff;
        border: 1px solid #0069f4;
        border-radius: 12px;

        margin-left: 10px;

        padding: 0 10px;
    }

    input::placeholder {
        font-size: 11px;
        line-height: 18px;

        text-align: center;

        color: #000000;

        opacity: 0.6;
    }
`;

const CodeInput = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
`;

const Buttons = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
`;

const ErrorBox = styled.div`
    width: 100%;

    margin: 10px 0;

    display: flex;
    justify-content: center;
    align-items: center;
`;
