import Input from "../Input";
import { InputContainer } from "./InputContainer";

export default function EmailStep({ state, setState }) {
    const stepData = {
        type: "Email",
        text: "Informe seu Email:",
        subText: "Você irá utilizá-lo para acessar sua conta.",
        state,
        setState,
    };

    return (
        <InputContainer>
            <Input
                text={stepData.text}
                type={stepData.type}
                state={stepData.state}
                setState={stepData.setState}
                subText={stepData.subText}
            />
        </InputContainer>
    );
}
