import { IoIosArrowBack } from "react-icons/io";
import styled from "styled-components";

export default function PreviousPage({ changeStep, currentStep }) {
    return (
        <PreviousPageContainer>
            <IoIosArrowBack
                onClick={changeStep}
                visibility={currentStep === 0 ? "hidden" : "visible"}
            />
        </PreviousPageContainer>
    );
}

const PreviousPageContainer = styled.div`
    width: 100%;
    height: 20px;

    display: flex;
    justify-content: flex-start;

    margin: 15px 0;

    > svg {
        color: #000000;
        font-size: 25px;

        visibility: ${(props) => props.display};

        cursor: pointer;
    }
`;
