import { useEffect, useState } from "react";
import { returnBackUrl } from "../../utils/backUrl";

import styled from "styled-components";
import axios from "axios";

import BenefitHeader from "../../components/Benefits/BenefitsHeader";
import Container from "../../components/Container";
import BenefitCarousel from "../../components/Benefits/BenefitCarousel";

export default function BenefitsHome() {
    const [courses, setCourses] = useState(null);
    const [experiences, setExperiences] = useState(null);
    const [products, setProducts] = useState(null);
    const [mentorships, setMentorships] = useState(null);

    useEffect(() => {
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const marketplaceProductsUrl = `${returnBackUrl()}/marketplace`;
        const getProducts = axios.get(marketplaceProductsUrl, config);

        getProducts
            .then((res) => {
                setCourses([...res.data.courses]);
                setExperiences([...res.data.experiences]);
                setProducts([...res.data.products]);
                setMentorships([...res.data.mentorships]);
            })
            .catch(() => {});
    }, []);

    return (
        <Container>
            <Interface>
                <BenefitHeader />

                <BenefitCarousel title={"Cursos"} benefits={courses} />
                <BenefitCarousel title={"Produtos"} benefits={products} />
                <BenefitCarousel title={"Mentorias"} benefits={mentorships} />
                <BenefitCarousel
                    title={"Experiências"}
                    benefits={experiences}
                />
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
