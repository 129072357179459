import { BsFillXSquareFill as DeleteIcon } from "react-icons/bs";
import { returnBackUrl } from "../../utils/backUrl";
import styled from "styled-components";
import { useState } from "react";
import axios from "axios";

import warning from "../../assets/angels_imagens/confirmacao_de_acao.svg";
import TwoButtonModal from "../Modals/TwoButtonModal";
import OneButtonModal from "../Modals/OneButtonModal";

export default function Line({ activeButton, orderData, setRefresh, index }) {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);

    function deleteOrder() {
        setDeleteModalIsOpen(false);
        setLoadingModalIsOpen(true);

        const { token } = JSON.parse(localStorage.getItem("userData"));
        const url = `${returnBackUrl()}/orders/${orderData.id}`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const deleteOrderPromise = axios.delete(url, config);

        deleteOrderPromise
            .then((res) => {
                setDeleteModalIsOpen(false);
                setRefresh((prevState) => !prevState);
                setLoadingModalIsOpen(false);
            })
            .catch((err) => setLoadingModalIsOpen(false));
    }

    function formatNumberFromCents(numCentavos) {
        let numReais = numCentavos / 100;
        if (numReais < 1000) {
            return numReais.toFixed(2);
        } else if (numReais >= 1000 && numReais < 1000000) {
            return (numReais / 1000).toFixed(1) + "K";
        } else {
            return (numReais / 1000000).toFixed(1) + "M";
        }
    }

    return (
        <>
            <LineContainer
                background={
                    index % 2 === 0 ? "#ffffff" : "rgba(217, 217, 217, 0.2);"
                }
            >
                <div>
                    {orderData.availableAmount}/{orderData.initialAmount}
                </div>

                <PriceBox background={"#0069f4"} fontColor={"#ffffff"}>
                    <div>{formatNumberFromCents(orderData.unitPrice)}</div>
                </PriceBox>

                <PriceBox background={"#F1F0F5"} fontColor={"#000000"}>
                    <div>
                        {formatNumberFromCents(
                            orderData.initialAmount * orderData.unitPrice
                        )}
                    </div>
                </PriceBox>

                <div>
                    <DeleteIcon onClick={() => setDeleteModalIsOpen(true)} />
                </div>
            </LineContainer>

            <OneButtonModal
                modalIsOpen={loadingModalIsOpen}
                setModalIsOpen={setLoadingModalIsOpen}
                modalInfo={{
                    imageSrc: "Bars",
                    mainText: "Deletando...",
                    subText: "A sua ordem está sendo deletada.",
                    buttonText: "",
                    buttonWidth: "0px",
                    buttonBackground: "#ffffff",
                    hideX: true,
                    hideButton: true,
                }}
            />

            <TwoButtonModal
                modalIsOpen={deleteModalIsOpen}
                setModalIsOpen={setDeleteModalIsOpen}
                modalInfo={{
                    imageSrc: warning,
                    whiteButtonText: "excluir",
                    blueButtonText: "Manter",
                    whiteButtonFunc: deleteOrder,
                    blueButtonFunc: () => {
                        setDeleteModalIsOpen(false);
                    },
                    mainText: "Confirmar exclusão",
                    subText: `Você tem certeza que deseja excluir a sua ordem de venda?`,
                }}
            />
        </>
    );
}

const LineContainer = styled.div`
    width: 100%;
    height: 32px;

    & + & {
        margin-top: 8px;
    }

    padding: 0 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: ${(props) => props.background};
    border-radius: 6px;

    > div {
        width: 25%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        color: #0069f4;
        font-size: 20px;
        background-color: white;

        cursor: pointer;
    }
`;

const PriceBox = styled.div`
    > div {
        width: 60px;
        height: 25px;
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: ${(props) => props.background};
        color: ${(props) => props.fontColor};
    }
`;
