import styled from "styled-components";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

export default function DateIntervalSelector({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) {
    const [open, setOpen] = useState(false);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    function parseDate(date) {
        let day = ("0" + date.getDate()).slice(-2);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let year = date.getFullYear().toString().substr(-2);

        return `${day}/${month}/${year}`;
    }

    function getDateRange() {
        if (!startDate) return "Selecione um intervalo";

        if (!endDate) return parseDate(startDate);

        if (
            startDate.getDate() === endDate.getDate() &&
            startDate.getMonth() === endDate.getMonth() &&
            startDate.getFullYear() === endDate.getFullYear()
        ) {
            return parseDate(startDate);
        } else {
            return `${parseDate(startDate)} \n ${parseDate(endDate)}`;
        }
    } //tks GPT4

    return (
        <CustomInput borderRadius={open ? "14px 14px 0 0" : "14px"}>
            <Visible onClick={() => setOpen((prevState) => !prevState)}>
                <h1>{getDateRange()}</h1>{" "}
                {open ? <AiOutlineUp /> : <AiOutlineDown />}
            </Visible>
            {open ? (
                <Invisible>
                    <div className="date-picker-wrapper">
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            dateFormat="dd/mm/yyyy"
                        />
                    </div>
                </Invisible>
            ) : (
                <></>
            )}
        </CustomInput>
    );
}

const CustomInput = styled.div`
    width: 166px;
    min-height: 40px;

    background: #0069f4;
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: ${(props) => props.borderRadius};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;

    position: relative;
`;

const Visible = styled.div`
    width: 100%;
    min-height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 15px;

    > h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;

        color: #ffffff;
    }

    > svg {
        color: #ffffff;
        font-size: 17px;
    }

    z-index: 1;
`;

const Invisible = styled.div`
    position: absolute;
    top: 40px;
    right: 0;

    width: 100%;
    border-radius: 0 0 14px 14px;
`;
