import styled from "styled-components";
import { useState, useEffect } from "react";

import Input from "../../Input";
import Button from "../../Button";

export default function Episode({ episode, index, setEpisodes }) {
    const [title, setTitle] = useState(episode.title);
    const [description, setDescription] = useState(episode.description);
    const [link, setLink] = useState(episode.link);

    useEffect(() => {
        setEpisodes((episodes) => {
            const newEpisodes = [...episodes];
            newEpisodes[index] = {
                title,
                description,
                link,
            };
            return [...newEpisodes];
        });
    }, [title, description, link]);

    return (
        <EpisodeContainer>
            <h1>Episódio {index + 1}</h1>
            <Input
                text={"Nome"}
                subText={"Utilize no máximo 28 caracteres"}
                type="text"
                state={title}
                setState={setTitle}
                placeholder=""
                maxChar={28}
            />

            <Divider />

            <Input
                text={"Descrição"}
                subText={"Utilize no máximo 160 caracteres."}
                type="text"
                state={description}
                setState={setDescription}
                placeholder=""
                maxChar={160}
            />

            <Divider />

            <Input
                text={"Link do episódio"}
                subText={
                    "Carregue o vídeo no Youtube, coloque como não listado e cole o link abaixo. A thumbnail do Youtube será a capa do seu vídeo."
                }
                type="text"
                state={link}
                setState={setLink}
                placeholder=""
            />
        </EpisodeContainer>
    );
}

const EpisodeContainer = styled.div`
    width: 100%;
    padding-top: 20px;

    > h1 {
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        margin-bottom: 15px;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 20px 0;
`;
