import { returnBackUrl } from "../../../utils/backUrl";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import CourseEditMain from "./CourseEditMain";
import CourseCreateEpisodes from "./CourseCreateEpisodes";
import TwoButtonModal from "../../../components/Modals/TwoButtonModal";
import warning from "../../../assets/angels_imagens/confirmacao_de_acao.svg";

export default function CourseEdit() {
    const { courseId } = useParams();
    const navigate = useNavigate();

    const [step, setStep] = useState(0);

    const [toggleState, setToggleState] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [coverImage, setCoverImage] = useState([]);
    const [posterImage, setPosterImage] = useState([]);
    const [price, setPrice] = useState();
    const [trailerLink, setTrailerLink] = useState("");
    const [episodes, setEpisodes] = useState([
        { title: "", description: "", link: "" },
    ]);

    const [errorMessage, setErrorMessage] = useState("");
    const [requestIsLoading, setRequestIsLoading] = useState(false);

    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [toggleAvailabilityModalIsOpen, setToggleAvailabilityModalIsOpen] =
        useState(false);

    useEffect(() => {
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const url = `${returnBackUrl()}/product/course/edit/${courseId}`;
        const request = axios.get(url, config);

        request
            .then((res) => {
                setName(res.data.title);
                setDescription(res.data.description);

                setPrice(res.data.price);
                setToggleState(res.data.isAvailable);
                setTrailerLink(res.data.trailerLink);

                setEpisodes([...res.data.episodes]);
            })
            .catch((err) => {});
    }, []);

    function editCourse() {
        setConfirmModalIsOpen(false);
        setErrorMessage("");
        setRequestIsLoading(true);
        if (!checkInputs()) {
            setRequestIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("images", posterImage[0]);
        formData.append("coverImage", coverImage[0]);
        formData.append("title", name);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("trailer", trailerLink);
        formData.append("episodes", JSON.stringify(episodes));
        formData.append("isAvailable", toggleState);

        const body = formData;

        const url = `${returnBackUrl()}/product/course/${courseId}`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.put(url, body, config);

        request
            .then(() => {
                navigate("/sell");
            })
            .catch((error) => {
                setRequestIsLoading(false);
            });
    }

    function confirmEditCourse() {
        setConfirmModalIsOpen(true);
    }

    function checkInputs() {
        if (!name) {
            setErrorMessage("O nome é obrigatório.");
            return false;
        } else if (!description) {
            setErrorMessage("A descrição é obrigatória.");
            setStep(0);
            return false;
        } else if (coverImage.length === 0) {
            setErrorMessage("A imagem de venda é obrigatória.");
            setStep(0);
            return false;
        } else if (posterImage.length === 0) {
            setErrorMessage("A imagem de pôster é obrigatória.");
            setStep(0);
            return false;
        } else if (!price || isNaN(price)) {
            setErrorMessage("O preço é obrigatório.");
            setStep(0);
            return false;
        } else if (!trailerLink) {
            setErrorMessage("O link do trailer é obrigatório.");
            setStep(0);
            return false;
        } else if (episodes.length === 0) {
            setErrorMessage("É necessário adicionar pelo menos um episódio.");
            return false;
        }

        episodes.forEach((episode, index) => {
            if (!episode.title) {
                setErrorMessage(
                    `O título do episódio ${index + 1} é obrigatório.`
                );
                return false;
            } else if (!episode.description) {
                setErrorMessage(
                    `A descrição do episódio ${index + 1} é obrigatória.`
                );
                return false;
            } else if (!episode.link) {
                setErrorMessage(
                    `O link do episódio ${index + 1} é obrigatório.`
                );
                return false;
            }
        });

        return true;
    }

    return (
        <>
            {step === 0 ? (
                <CourseEditMain
                    name={name}
                    description={description}
                    coverImage={coverImage}
                    posterImage={posterImage}
                    price={price}
                    trailerLink={trailerLink}
                    setName={setName}
                    setDescription={setDescription}
                    setCoverImage={setCoverImage}
                    setPosterImage={setPosterImage}
                    setPrice={setPrice}
                    setTrailerLink={setTrailerLink}
                    setStep={setStep}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    toggle={toggleState}
                    setToggleAvailabilityModalIsOpen={
                        setToggleAvailabilityModalIsOpen
                    }
                />
            ) : (
                <CourseCreateEpisodes
                    setEpisodes={setEpisodes}
                    setStep={setStep}
                    episodes={episodes}
                    confirmPublishCourse={confirmEditCourse}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    requestIsLoading={requestIsLoading}
                />
            )}

            <TwoButtonModal
                modalIsOpen={confirmModalIsOpen}
                setModalIsOpen={setConfirmModalIsOpen}
                modalInfo={{
                    imageSrc: warning,
                    mainText: "Deseja atualizar seu curso?",
                    subText:
                        "Certifique-se de ter adicionado todos os episódios antes de confirmar a alteração do seu curso.",
                    blueButtonFunc: () => {
                        setConfirmModalIsOpen(false);
                    },
                    blueButtonText: "Voltar",
                    whiteButtonFunc: () => {
                        editCourse();
                    },
                    whiteButtonText: "Alterar",
                }}
            />

            <TwoButtonModal
                modalIsOpen={toggleAvailabilityModalIsOpen}
                setModalIsOpen={setToggleAvailabilityModalIsOpen}
                modalInfo={{
                    imageSrc: warning,
                    mainText: toggleState
                        ? "Deseja desativar seu curso?"
                        : "Deseja ativar seu curso?",
                    subText: toggleState
                        ? "Atenção! Você está prestes a desativar seu curso. Caso essa seja a intenção, clique no botão ‘Desativar’ abaixo."
                        : "Atenção! Você está prestes a ativar seu curso. Caso essa seja a intenção, clique no botão ‘Ativar’ abaixo.",
                    blueButtonFunc: () => {
                        setToggleAvailabilityModalIsOpen(false);
                    },
                    whiteButtonText: toggleState ? "Desativar" : "Ativar",
                    whiteButtonFunc: () => {
                        setToggleState(!toggleState);
                        setToggleAvailabilityModalIsOpen(false);
                    },
                    blueButtonText: toggleState
                        ? "Manter disponível"
                        : "Manter inativo",
                }}
            />
        </>
    );
}
