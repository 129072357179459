import Input from "../Input";
import { InputContainer } from "./InputContainer";

export default function CPFStep({ state, setState }) {
    const stepData = {
        type: "tel",
        text: "CPF:",
        subText: "Essa será a sua única conta.",
        state,
        setState,
    };

    return (
        <InputContainer>
            <Input
                text={stepData.text}
                type={stepData.type}
                state={stepData.state}
                setState={stepData.setState}
                subText={stepData.subText}
                maxChar={14}
            />
        </InputContainer>
    );
}
