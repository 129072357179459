import { returnBackUrl } from "./backUrl";
import { io } from "socket.io-client";

const userData = JSON.parse(localStorage.getItem("userData"));

const userId = userData ? userData.id : null;
const token = userData ? userData.token : null;

const socket = io(`${returnBackUrl()}`, {
    query: {
        userId,
        token,
    },
});

export default socket;
