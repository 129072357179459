import styled from "styled-components";
import Input from "../Input";
import { InputContainer } from "./InputContainer";

import PasswordRequirement from "./PasswordRequirement";

export default function PasswordStep({ state, setState }) {
    const stepData = {
        type: "password",
        text: "Senha:",
        subText: "Guarde ela somente para você.",
        state,
        setState,
    };

    return (
        <InputContainer>
            <Input
                text={stepData.text}
                type={stepData.type}
                state={stepData.state}
                setState={stepData.setState}
                subText={stepData.subText}
            />
            <PasswordRequirementsContainer>
                <div>
                    <PasswordRequirement
                        password={state}
                        text="Letra maiúscula"
                        regex={/[A-Z]/}
                    />
                    <PasswordRequirement
                        password={state}
                        text="Caractere especial"
                        regex={/[\!@#\$%\^&\*\(\)\-_=\+[\]{};':"\\|,.<>\/?]/}
                    />
                </div>

                <div>
                    <PasswordRequirement
                        password={state}
                        text="Letra minúscula"
                        regex={/[a-z]/}
                    />
                    <PasswordRequirement
                        password={state}
                        text="Mín. 8 caracteres"
                        regex={/^.{8,}$/}
                    />
                </div>

                <div>
                    <PasswordRequirement
                        password={state}
                        text="Número"
                        regex={/[0-9]/}
                    />
                </div>
            </PasswordRequirementsContainer>
        </InputContainer>
    );
}

const PasswordRequirementsContainer = styled.div`
    width: 100%;
    height: 55px;

    display: flex;
    justify-content: space-between;

    > div {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    > div:last-child {
        justify-content: flex-end;
        width: 20%;
    }
`;
