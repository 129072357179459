import SignInContext from "../../contexts/SignInContext";
import { useContext, useState, useEffect } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import axios from "axios";

import BalanceContext from "../../contexts/BalanceContext";
import LogoHeader from "../../components/Headers/OnlyLogoHeader";
import ErrorMessage from "../../components/ErrorMessage";
import AuthNavigation from "../../components/Navigation";
import InputModal from "../../components/Modals/InputModal";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Input from "../../components/Input";

import phone2fa from "../../assets/angels_imagens/popups/verificacao.svg";

export default function SignIn() {
    const { isLogged, setIsLogged } = useContext(SignInContext);
    const { setRefreshBalance } = useContext(BalanceContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged) {
            navigate("/home");
        }
    }, []);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessageText, setErrorMessageText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [inputModalIsOpen, setInputModalIsOpen] = useState(false);
    const [userAuthCode, setUserAuthCode] = useState("");
    const [authError, setAuthError] = useState(false);

    function login() {
        setIsLoading(true);
        setAuthError(false);

        const url = `${returnBackUrl()}/sign-in`;
        const body = { email, password };
        const promise = axios.post(url, body);

        promise
            .then((res) => {
                if (res.data.token) {
                    setIsLogged(true);
                    setErrorMessageText("");
                    localStorage.setItem("userData", JSON.stringify(res.data));
                    setIsLoading(false);
                    setRefreshBalance((prevState) => !prevState);
                    navigate("/home");
                } else {
                    setIsLoading(false);
                    setInputModalIsOpen(true);
                }
            })
            .catch((res) => {
                setIsLoading(false);
                setErrorMessageText(res.response.data);
            });
    }

    function validate2FA() {
        setAuthError(false);
        setIsLoading(true);

        const url = `${returnBackUrl()}/2FA/validateSignIn`;
        const body = {
            totp: userAuthCode,
            email,
        };
        const validate2FARequest = axios.post(url, body);

        validate2FARequest
            .then((res) => {
                setIsLogged(true);
                setErrorMessageText("");
                localStorage.setItem("userData", JSON.stringify(res.data));
                setIsLoading(false);
                setRefreshBalance((prevState) => !prevState);
                navigate("/home");
            })
            .catch(() => {
                setAuthError(true);
                setIsLoading(false);
            });
    }

    return (
        <Container>
            <Interface>
                <LogoHeader />
                <Form>
                    <Input
                        text="Email"
                        type="email"
                        state={email}
                        setState={setEmail}
                    />

                    <Input
                        text={"Senha"}
                        type="password"
                        state={password}
                        setState={setPassword}
                    />

                    <ErrorMessage text={errorMessageText} />
                </Form>

                <CommandsContainer>
                    <Button
                        background="#0069F4"
                        border="1px solid #0069F4"
                        text={
                            isLoading ? (
                                <Bars height="35px" color="#ffffff" />
                            ) : (
                                "Entrar"
                            )
                        }
                        fontColor="#ffffff"
                        onClickFunc={login}
                    />

                    <AuthNavigation
                        text={"Ainda não possui uma conta? Cadastrar agora."}
                        onClickFunc={() => navigate("/sign-up")}
                    />

                    <AuthNavigation
                        text={"Esqueci minha senha"}
                        onClickFunc={() => navigate("/password")}
                    />
                </CommandsContainer>
            </Interface>

            <InputModal
                isLoading={isLoading}
                modalIsOpen={inputModalIsOpen}
                setModalIsOpen={setInputModalIsOpen}
                inputState={userAuthCode}
                setState={setUserAuthCode}
                errorMessage={authError ? "Código inválido." : ""}
                modalInfo={{
                    imageSrc: phone2fa,
                    whiteButtonText: "voltar",
                    blueButtonText: "Verificar",
                    whiteButtonFunc: () => {
                        setInputModalIsOpen(false);
                    },
                    blueButtonFunc: () => {
                        validate2FA();
                    },
                    mainText: "Verificação em duas etapas",
                    subText: `Insira abaixo o código de verificação do app Google Authenticator.`,
                }}
            />
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;
    height: 70vh;

    display: flex;
    flex-direction: column;
`;

const CommandsContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Form = styled.div`
    width: 100%;
    height: 250px;
    margin-top: 100px;

    padding: 0 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
