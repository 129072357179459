import { IoIosClose } from "react-icons/io";
import styled from "styled-components";
import Modal from "react-modal";

import Button from "../Button";

export default function ScrollModal({
    modalIsOpen,
    setModalIsOpen,
    scrollableText,
    modalInfo,
}) {
    return (
        <Modal
            isOpen={modalIsOpen}
            overlayClassName="modal-overlay"
            className="modal-content"
        >
            <CloseModal>
                <div
                    onClick={() => {
                        setModalIsOpen(false);
                    }}
                >
                    <IoIosClose />
                </div>
            </CloseModal>

            <ModalTitle>
                <h1>{modalInfo.title}</h1>
                <h2>{modalInfo.subtitle}</h2>
            </ModalTitle>
            <ScrollableBox>{scrollableText}</ScrollableBox>

            <Buttons>
                <Button
                    onClickFunc={() => setModalIsOpen(false)}
                    background="#0069F4"
                    border="1px solid #0069F4;"
                    fontColor="#ffffff"
                    width={"80%"}
                    text="Fechar"
                />
            </Buttons>
        </Modal>
    );
}

const CloseModal = styled.div`
    width: 100%;

    display: flex;
    justify-content: flex-end;

    cursor: pointer;

    svg {
        font-size: 35px;
        color: #051532;
    }
`;

const ScrollableBox = styled.div`
    width: 100%;
    height: 500px;
    overflow-y: auto;
    margin: 10px 0;

    > h1 {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;

        margin-bottom: 6px;

        color: #000000;
    }

    > h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;

        color: #000000;

        margin-bottom: 3px;
    }

    > p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: #a9a9a9;
    }
`;

const Buttons = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

const ModalTitle = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
    }

    > h2 {
        font-weight: 600;
        font-size: 11px;
        line-height: 12px;
        text-align: center;

        color: #a9a9a9;
    }
`;
