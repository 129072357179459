import styled from "styled-components";

//lsb formatting - tks GPT4
export const NumberContainer = styled.div`
    display: inline-flex;
    align-items: baseline;
`;

export const IntegerPart = styled.span`
    font-size: 34px;
`;

export const DecimalSeparator = styled.span`
    font-size: 18px;
`;

export const DecimalPart = styled.span`
    font-size: 18px;
`;
