import styled from "styled-components";

import logo from "../../assets/angels_imagens/logo_azul_angels.svg";
import shark from "../../assets/angels_imagens/vender/minhas_vendas.svg";
import owl from "../../assets/angels_imagens/vender/cursos.svg";
import peacock from "../../assets/angels_imagens/vender/produtos.svg";
import lion from "../../assets/angels_imagens/vender/mentorias.svg";
import butterfly from "../../assets/angels_imagens/vender/experiencias.svg";

import LogoAndMenuHeader from "../../components/Headers/LogoAndMenuHeader";
import Container from "../../components/Container";
import SectionCard from "../../components/Marketplace/SectionCard";

export default function SellProducts() {
    return (
        <Container>
            <Interface>
                <LogoAndMenuHeader
                    logo={logo}
                    color={"#ffffff"}
                    background={"#0069f4"}
                />
                <SectionCard
                    image={shark}
                    background={"#0069f4"}
                    fontColor="#ffffff"
                    text={"Suas vendas"}
                    navigateTo={"/sell/dashboard"}
                />
                <SectionCard
                    image={owl}
                    background={"#f1f0f5"}
                    fontColor="#000000"
                    text={"Cursos"}
                    navigateTo={"/course/create"}
                />
                <SectionCard
                    image={peacock}
                    background={"#f1f0f5"}
                    fontColor="#000000"
                    text={"Produtos"}
                    navigateTo={"/product/create"}
                />
                <SectionCard
                    image={lion}
                    background={"#f1f0f5"}
                    fontColor="#000000"
                    text={"Mentorias"}
                    navigateTo={"/mentorship/create"}
                />
                <SectionCard
                    image={butterfly}
                    background={"#f1f0f5"}
                    fontColor="#000000"
                    text={"Experiências"}
                    navigateTo={"/experience/create"}
                />
            </Interface>
        </Container>
    );
}

const Interface = styled.div``;
