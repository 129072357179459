import styled from "styled-components";

//tks GPT4

export default function ToggleInput({ state, setState, mainText, subText }) {
    const theme = {
        sliderBackground: "#ccc",
        checkedSliderBackground: "#0069F4",
        sliderHandle: "white",
    };

    const handleChange = () => {
        setState(true);
    };

    return (
        <Container>
            <h1>{mainText}</h1>
            <h2>{subText}</h2>
            <div>
                <SliderContainer>
                    <Checkbox
                        checked={state}
                        theme={theme}
                        onChange={() => {}}
                    />
                    <Slider onClick={handleChange} theme={theme} />
                </SliderContainer>
                <h3>Disponível</h3>
            </div>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        /* or 175% */

        color: #000000;
        margin-bottom: 15px;
    }

    h2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        color: #a9a9a9;
        margin-bottom: 15px;
    }

    h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
        /* or 194% */

        color: #000000;

        margin-left: 20px;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
`;

// Define styled components
const SliderContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 50px;
    height: 26px;
`;

const Slider = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.sliderBackground || "#ccc"};
    cursor: pointer;
    border-radius: 26px;
    transition: all 0.4s;

    &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 3px;
        background-color: ${({ theme }) => theme.sliderHandle || "white"};
        border-radius: 50%;
        transition: all 0.4s;
    }
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
    display: none;

    &:checked + ${Slider} {
        background-color: ${({ theme }) =>
            theme.checkedSliderBackground || "#2196F3"};
    }

    &:checked + ${Slider}:before {
        transform: translateX(24px);
    }
`;
