import { useEffect, useState, useContext } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import logo from "../../assets/angels_imagens/logo_preta_angels.svg";

import LogoAndMenuHeader from "../../components/Headers/LogoAndMenuHeader";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Line from "../../components/MyOrders/Line";

import SignInContext from "../../contexts/SignInContext";

export default function MyOrders() {
    const { isLogged } = useContext(SignInContext);
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState("buyings");

    const [activeToken, setActiveToken] = useState("LSB");

    const [lsbBuyings, setLsbBuyings] = useState([]);
    const [lsbSellings, setLsbSellings] = useState([]);

    const [link3Buyings, setLink3Buyings] = useState([]);
    const [link3Sellings, setLink3Sellings] = useState([]);

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }

        getUserOrders();
    }, [refresh]);

    function getUserOrders() {
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const url = `${returnBackUrl()}/orders/market/user`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const getorders = axios.get(url, config);

        getorders
            .then((res) => {
                setLsbBuyings(res.data.userBuyings.lsbBuyings);
                setLsbSellings(res.data.userSellings.lsbSellings);
                setLink3Buyings(res.data.userBuyings.link3Buyings);
                setLink3Sellings(res.data.userSellings.link3Sellings);
            })
            .catch((err) => {});
    }

    function renderOrders() {
        let array;
        if (activeToken === "LSB") {
            array = selected === "buyings" ? lsbBuyings : lsbSellings;
        } else {
            array = selected === "buyings" ? link3Buyings : link3Sellings;
        }

        if (array.length === 0) {
            return (
                <NoOrders>
                    Você não tem
                    {selected === "buyings" ? " compras " : " vendas "} ativas
                    no momento.
                </NoOrders>
            );
        }

        return array.map((order, index) => {
            return (
                <Line
                    key={index}
                    index={index}
                    activeButton={selected}
                    orderData={order}
                    setRefresh={setRefresh}
                />
            );
        });
    }

    return (
        <Container>
            <div>
                <LogoAndMenuHeader
                    logo={logo}
                    color={"#ffffff"}
                    background={"#000000"}
                />
            </div>
            <Interface>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {/* <SelectWrapper>
                        <SelectToken
                            value={activeToken}
                            onChange={(event) => {
                                setActiveToken(event.target.value);
                            }}
                        >
                            <option value="LINK3">LINK3</option>
                            <option value="LSB">LSB</option>
                        </SelectToken>
                    </SelectWrapper> */}
                </div>
                <Title>
                    <h1>
                        {selected === "buyings"
                            ? "Suas posições de compra"
                            : "Suas posições de venda"}
                    </h1>
                </Title>

                <Orders>
                    <OrderHeaders>
                        <div>Qtd</div>
                        <div>Preço</div>
                        <div>Valor total</div>
                        <div></div>
                    </OrderHeaders>
                    {renderOrders()}
                </Orders>

                <Buttons>
                    <div>
                        <Button
                            text="Compras"
                            background={
                                selected === "buyings" ? "#0069F4" : "#ffffff"
                            }
                            border={
                                selected === "buyings"
                                    ? "#0069F4"
                                    : "1px solid rgba(0, 0, 0, 0.1)"
                            }
                            fontColor={
                                selected === "buyings" ? "#ffffff" : "#000000"
                            }
                            width="172px"
                            height="52px"
                            borderRadius="8px"
                            onClickFunc={() => setSelected("buyings")}
                        >
                            Compras
                        </Button>

                        <Button
                            text="Vendas"
                            background={
                                selected === "sellings" ? "#0069F4" : "#ffffff"
                            }
                            border={
                                selected === "sellings"
                                    ? "#0069F4"
                                    : "1px solid rgba(0, 0, 0, 0.1)"
                            }
                            fontColor={
                                selected === "sellings" ? "#ffffff" : "#000000"
                            }
                            width="172px"
                            height="52px"
                            borderRadius="8px"
                            onClickFunc={() => setSelected("sellings")}
                        >
                            Compras
                        </Button>
                    </div>
                </Buttons>
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;

    padding: 0 !important;
`;

const Title = styled.div`
    width: 100%;

    display: flex;
    justify-content: flex-start;

    padding: 0 30px;

    font-weight: 300;
    font-size: 24px;
    line-height: 27px;

    color: #000000;

    > h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
        color: #000000;
    }
`;

const Orders = styled.div`
    width: 100%;
    margin-top: 40px;

    margin-bottom: 100px;

    > h5 {
        margin-top: 40px;

        color: red;
    }
`;

const OrderHeaders = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 30px;
    margin-bottom: 13px;

    > div {
        width: 25%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const NoOrders = styled.div`
    width: 100%;
    height: 50px;

    margin-top: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Buttons = styled.div`
    width: 100%;
    height: 62px;

    position: fixed;
    bottom: 10px;
    left: calc((100% - 500px) / 2);
    z-index: 2;

    > div {
        width: 100%;
        max-width: 500px;
        height: 100%;

        padding: 0 18px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media (max-width: 500px) {
        left: 0;
    }
`;

const SelectWrapper = styled.div`
    width: 90%;
    height: 40px;

    margin: 10px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 30px 0 10px; // This can help move the arrow to the left
    overflow: hidden;
    border: 1px solid #0069f4;
    border-radius: 12px;
`; //tksGPT4

const SelectToken = styled.select`
    width: 100%;

    padding-left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffffff;
    border-radius: 12px;
    border: none;

    font-size: 14px;

    background: transparent;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    option {
        font-size: 14px;

        background: #ffffff;
        color: #000000;

        cursor: pointer;
    }
`;
