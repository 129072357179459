import { InputContainer } from "./InputContainer";
import Input from "../Input";

import { useState, useEffect } from "react";
import styled from "styled-components";

export default function AddressStep({
    state,
    setState,
    cep,
    setCep,
    number,
    setNumber,
    complement,
    setComplement,
}) {
    useEffect(() => {
        setState(`CEP:${cep}, Número: ${number}, Complemento: ${complement}`);
    }, [cep, number, complement]);

    return (
        <InputContainer>
            <Input
                text={"Digite o seu CEP"}
                type={"tel"}
                state={cep}
                setState={setCep}
                subText={"Pode ser solicitado comprovação de residência."}
            />
            <NumberAndComContainer>
                <Input
                    width={"40%"}
                    text={"Número"}
                    type="tel"
                    state={number}
                    setState={setNumber}
                />
                <Input
                    width={"40%"}
                    text={"Complemento"}
                    type="text"
                    state={complement}
                    setState={setComplement}
                />
            </NumberAndComContainer>
        </InputContainer>
    );
}

const NumberAndComContainer = styled.div`
    width: 100%;

    padding: 30px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;
