import styled from "styled-components";

export default function ErrorMessage({ text }) {
    return <Message>{text}</Message>;
}

const Message = styled.div`
    width: 100%;
    height: 20px;

    color: red;
    font-size: 17px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
