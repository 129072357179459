import logo from "../../assets/angels_imagens/logo_branca_angels.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

import { HeaderContainer } from "../Headers/HeaderContainer";

export default function HomeHeader() {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <ReactSVG
                src={logo}
                beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 64px;height:64px;");
                }}
            />

            <MenuContainer onClick={() => navigate("/menu")}>
                <GiHamburgerMenu />
            </MenuContainer>
        </HeaderContainer>
    );
}

const MenuContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0069f4;

    cursor: pointer;

    > svg {
        color: #ffffff;
        font-size: 20px;
    }
`;
