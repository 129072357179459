import { useNavigate, useParams } from "react-router-dom";
import { returnBackUrl } from "../../utils/backUrl";
import { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { IoIosPlay } from "react-icons/io";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import axios from "axios";

import logo from "../../assets/angels_imagens/logo_azul_angels.svg";
import Container from "../../components/Container";
import ArrowAndLogoHeader from "../../components/Headers/ArrowAndLogoHeader";

export default function UserCourseWatch() {
    const navigate = useNavigate();
    const { courseId } = useParams();

    const [courseData, setCourseData] = useState(null);
    const [episodesLoading, setEpisodesLoading] = useState(true);

    useEffect(() => {
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const urlMentorships = `${returnBackUrl()}/product/course/episodes/${courseId}`;
        const getEpisodes = axios.get(urlMentorships, config);

        getEpisodes
            .then((res) => {
                setCourseData({ ...res.data });
                setEpisodesLoading(false);
            })
            .catch((err) => {
                setEpisodesLoading(false);
            });
    }, []);

    function getYouTubeID(url) {
        let videoID;

        if (url.includes("youtube.com")) {
            // If link is something like "https://www.youtube.com/watch?v=abc123xyz"
            videoID = url.split("v=")[1];
            let ampersandPosition = videoID.indexOf("&");
            if (ampersandPosition != -1) {
                videoID = videoID.substring(0, ampersandPosition);
            }
        } else if (url.includes("youtu.be")) {
            // If link is something like "https://youtu.be/abc123xyz"
            videoID = url.split(".be/")[1];
        }

        return videoID;
    } //tks GPT4

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    logo={logo}
                    color={"#095ece"}
                    onClickFunc={() => {
                        navigate("/yourbuyings/courses");
                    }}
                />

                {episodesLoading || !courseData ? (
                    <CenterDiv>
                        <Bars color="#fff" height={50} width={50} />
                    </CenterDiv>
                ) : (
                    <>
                        <TrailerContainer>
                            <iframe
                                src={`https://www.youtube.com/embed/${getYouTubeID(
                                    courseData.course.trailerLink
                                )}?color=white&modestbranding=1&rel=0&showinfo=0`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </TrailerContainer>
                        <TitleContainer>
                            <div>
                                <ReactSVG
                                    onClick={() => navigate("/home")}
                                    src={logo}
                                    beforeInjection={(svg) => {
                                        svg.setAttribute(
                                            "style",
                                            "width: 20px;height:20px;"
                                        );
                                    }}
                                />
                                <h2>SÉRIES</h2>
                            </div>
                            <h1>{courseData.course.title}</h1>
                            <h3>{courseData.course.description}</h3>
                            <EpisodesContainer>
                                <h1>Episódios</h1>
                                {courseData.episodes.map((episode, index) => (
                                    <Episode key={index}>
                                        <div>
                                            <iframe
                                                src={`https://www.youtube.com/embed/${getYouTubeID(
                                                    courseData.course
                                                        .trailerLink
                                                )}?rel=0&modestbranding=1&autohide=1&color=white`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                            <h1>
                                                {index + 1}. {episode.title}
                                            </h1>
                                        </div>
                                        <h2>{episode.description}</h2>
                                    </Episode>
                                ))}
                            </EpisodesContainer>
                        </TitleContainer>
                    </>
                )}
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    margin-bottom: 100px;
`;

const CenterDiv = styled.div`
    display: flex;
    justify-content: center;
`;

const TrailerContainer = styled.div`
    > iframe {
        width: 100%;
        height: 200px;
        border-radius: 12px;
    }
`;

const TitleContainer = styled.div`
    > div:first-child {
        display: flex;
        align-items: center;

        > h2 {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            /* identical to box height */

            letter-spacing: 3px;

            color: #b7b7b7;

            margin-left: 10px;
        }
    }

    > h1 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        letter-spacing: -0.25px;

        color: #ffffff;
    }

    > h3 {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.25px;

        color: #ffffff;
    }
`;

const EpisodesContainer = styled.div`
    margin-top: 20px;

    > h1 {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        letter-spacing: 0.25px;

        color: #e2e2e2;
    }
`;

const Episode = styled.div`
    margin-top: 20px;

    width: 100%;

    > div {
        display: flex;
        align-items: center;

        margin-bottom: 5px;
    }

    iframe {
        width: 250px;
        height: 170px;

        margin-right: 15px;
        border-radius: 12px;
    }

    h1 {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height */

        letter-spacing: -0.5px;

        color: #e2e2e2;
    }

    h2 {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #e2e2e2;

        letter-spacing: normal;
    }
`;
