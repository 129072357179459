import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";

import "./assets/reset.css";
import "./assets/styles.css";
import ChangeBackgroundColor from "./assets/ChangeBackgroundColor";

import { returnBackUrl } from "./utils/backUrl";
import SignInContext from "./contexts/SignInContext";
import BalanceContext from "./contexts/BalanceContext";

import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import OrderBook from "./pages/OrderBook/OrderBook";
import Benefits from "./pages/Benefit/Benefits";
import Home from "./pages/Home/Home";
import Deposit from "./pages/Deposit/Deposit";
import Menu from "./pages/Menu/Menu";
import MyOrders from "./pages/MyOrders/MyOrders";
import BenefitsHome from "./pages/Benefit/BenefitsHome";
import Withdraw from "./pages/Withdraw/Withdraw";
import InitialScreen from "./pages/InitialScreen/InitialScreen";
import Profile from "./pages/Profile/Profile";
import SellProducts from "./pages/Marketplace/SellProducts";
import CourseCreate from "./pages/Marketplace/Courses/CourseCreate";
import CourseEditSelect from "./pages/Marketplace/Courses/CourseEditSelect";
import CourseEdit from "./pages/Marketplace/Courses/CourseEdit";
import ProductsCreate from "./pages/Marketplace/Products/ProductsCreate";
import ProductEditSelect from "./pages/Marketplace/Products/ProductEditSelect";
import ProductClothingEdit from "./pages/Marketplace/Products/ProductClothingEdit";
import ProductObjectEdit from "./pages/Marketplace/Products/ProductObjectEdit";
import MentorshipCreate from "./pages/Marketplace/Mentorship/MentorshipCreate";
import MentorshipEditSelect from "./pages/Marketplace/Mentorship/MentorshipEditSelect";
import MentorshipEdit from "./pages/Marketplace/Mentorship/MentorshipEdit";
import ExperienceCreate from "./pages/Marketplace/Experiences/ExperienceCreate";
import ExperienceEditSelect from "./pages/Marketplace/Experiences/ExperienceEditSelect";
import ExperienceEdit from "./pages/Marketplace/Experiences/ExperienceEdit";
import UserProducts from "./pages/Buyings/UserProducts";
import UserCourses from "./pages/Buyings/UserCourses";
import UserMentorships from "./pages/Buyings/UserMentorships";
import UserExperiences from "./pages/Buyings/UserExperiences";
import BuyingsMain from "./pages/Buyings/BuyingsMain";
import UserCourseWatch from "./pages/Buyings/UserCourseWatch";
import PasswordRecovery from "./pages/PasswordRecovery/PasswordRecovery";

import socket from "./utils/webSocket";
import Dashboard from "./pages/Marketplace/Dashboard/Dashboard";
import UserTokens from "./pages/Buyings/UserTokens";

Modal.setAppElement(".root");

export default function App() {
    const [isLogged, setIsLogged] = useState(
        Boolean(localStorage.getItem("userData"))
    );
    const [refreshBalance, setRefreshBalance] = useState(true);
    const [realAmount, setRealAmount] = useState(0);
    const [lsbAmount, setLsbAmount] = useState(0);
    const [link3Amount, setLink3Amount] = useState(0);
    const [balanceIsLoading, setBalanceIsLoading] = useState(true);

    useEffect(() => {
        setBalanceIsLoading(true);
        if (!isLogged) {
            setBalanceIsLoading(false);
            setRealAmount(0);
            setLsbAmount(0);
            return;
        }

        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const getBalanceUrl = `${returnBackUrl()}/balance`;
        const getBalance = axios.get(getBalanceUrl, config);

        getBalance
            .then((res) => {
                setRealAmount(res.data.realAmount / 100);
                setLsbAmount(res.data.lsbAmount);
                setLink3Amount(res.data.link3Amount);
                setBalanceIsLoading(false);
            })
            .catch((res) => setBalanceIsLoading(false));

        socket.on("payment_update", () => {
            setRefreshBalance((prevState) => !prevState);
        });

        return () => {
            socket.off("payment_update");
        };
    }, [refreshBalance]);

    return (
        <BalanceContext.Provider
            value={{
                setRefreshBalance,
                lsbAmount,
                realAmount,
                balanceIsLoading,
                link3Amount,
            }}
        >
            <SignInContext.Provider value={{ isLogged, setIsLogged }}>
                <BrowserRouter>
                    <Routes>
                        {/* Auth */}
                        <Route
                            path="/sign-in"
                            element={
                                <>
                                    <SignIn />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />
                        <Route
                            path="/sign-up"
                            element={
                                <>
                                    <SignUp />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />

                        {/* Orderbook */}
                        <Route
                            path="/market"
                            element={
                                <>
                                    <OrderBook />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />

                        {/* Marketplace */}
                        <Route
                            path="/benefits/:benefitType/:benefitId"
                            element={
                                <>
                                    <Benefits />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />
                        <Route
                            path="/benefits"
                            element={
                                <>
                                    <BenefitsHome />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />

                        {/* User balance */}
                        <Route
                            path="/deposit"
                            element={
                                <>
                                    <Deposit />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />
                        <Route
                            path="/withdraw"
                            element={
                                <>
                                    <Withdraw />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />

                        {/* Main pages */}
                        <Route
                            path="/menu"
                            element={
                                <>
                                    <Menu />
                                    <ChangeBackgroundColor backgroundColor="#095ece" />
                                </>
                            }
                        />
                        <Route
                            path="/home"
                            element={
                                <>
                                    <Home />
                                    <ChangeBackgroundColor backgroundColor="#095ece" />
                                </>
                            }
                        />

                        {/* User Info */}
                        <Route
                            path="/user/orders"
                            element={
                                <>
                                    <MyOrders />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <>
                                    <Profile />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />

                        {/* Marketplace */}
                        <Route
                            path="/sell"
                            element={
                                <>
                                    <SellProducts />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />
                        <Route
                            path="/course/create"
                            element={<CourseCreate />}
                        />

                        <Route
                            path="/course/edit"
                            element={<CourseEditSelect />}
                        />

                        <Route
                            path="/course/edit/:courseId"
                            element={<CourseEdit />}
                        />

                        <Route
                            path="/product/create"
                            element={<ProductsCreate />}
                        />

                        <Route
                            path="/product/edit"
                            element={<ProductEditSelect />}
                        />

                        <Route
                            path="/product/clothing/edit/:productId"
                            element={<ProductClothingEdit />}
                        />

                        <Route
                            path="/product/object/edit/:productId"
                            element={<ProductObjectEdit />}
                        />

                        <Route
                            path="/mentorship/create"
                            element={<MentorshipCreate />}
                        />

                        <Route
                            path="/mentorship/edit"
                            element={<MentorshipEditSelect />}
                        />

                        <Route
                            path="/mentorship/edit/:mentorshipId"
                            element={<MentorshipEdit />}
                        />

                        <Route
                            path="/experience/create"
                            element={<ExperienceCreate />}
                        />

                        <Route
                            path="/experience/edit"
                            element={<ExperienceEditSelect />}
                        />

                        <Route
                            path="/experience/edit/:experienceId"
                            element={<ExperienceEdit />}
                        />

                        <Route path="/sell/dashboard" element={<Dashboard />} />

                        {/* Bought Products */}
                        <Route path="/yourbuyings" element={<BuyingsMain />} />

                        <Route
                            path="/yourbuyings/tokens"
                            element={<UserTokens />}
                        />

                        <Route
                            path="/yourbuyings/courses"
                            element={<UserCourses />}
                        />

                        <Route
                            path="/yourbuyings/courses/:courseId"
                            element={
                                <>
                                    <UserCourseWatch />
                                    <ChangeBackgroundColor backgroundColor="#000" />
                                </>
                            }
                        />

                        <Route
                            path="/yourbuyings/products"
                            element={<UserProducts />}
                        />

                        <Route
                            path="/yourbuyings/mentorships"
                            element={<UserMentorships />}
                        />

                        <Route
                            path="/yourbuyings/experiences"
                            element={<UserExperiences />}
                        />

                        {/* Password Recovery */}
                        <Route
                            path="/password"
                            element={
                                <>
                                    <PasswordRecovery />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />

                        {/* Initial Screen */}
                        <Route
                            path="/*"
                            element={
                                <>
                                    <InitialScreen />
                                    <ChangeBackgroundColor />
                                </>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </SignInContext.Provider>
        </BalanceContext.Provider>
    );
}
