import { Bars } from "react-loader-spinner";
import styled from "styled-components";

import AuthNavigation from "../../components/Navigation";
import Button from "../../components/Button";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import PasswordRequirement from "../../components/SignUp/PasswordRequirement";

export default function PasswordChange({
    code,
    password,
    confirmPassword,
    isLoading,
    setCode,
    setPassword,
    setConfirmPassword,
    onClickFunc,
    setPage,
    errorMessage,
}) {
    return (
        <Form>
            <h1>Crie sua nova senha</h1>
            <Input
                text="Código de verificação"
                subText="Informe o código que você recebeu no e-mail cadastrado."
                type="text"
                state={code}
                setState={setCode}
            />

            <Divider />

            <Input
                text="Digite sua nova senha"
                type="password"
                state={password}
                setState={setPassword}
            />
            <PasswordRequirementsContainer>
                <div>
                    <PasswordRequirement
                        password={password}
                        text="Letra maiúscula"
                        regex={/[A-Z]/}
                    />
                    <PasswordRequirement
                        password={password}
                        text="Caractere especial"
                        regex={/[\!@#\$%\^&\*\(\)\-_=\+[\]{};':"\\|,.<>\/?]/}
                    />
                </div>

                <div>
                    <PasswordRequirement
                        password={password}
                        text="Letra minúscula"
                        regex={/[a-z]/}
                    />
                    <PasswordRequirement
                        password={password}
                        text="Mín. 8 caracteres"
                        regex={/^.{8,}$/}
                    />
                </div>

                <div>
                    <PasswordRequirement
                        password={password}
                        text="Número"
                        regex={/[0-9]/}
                    />
                </div>
            </PasswordRequirementsContainer>

            <Divider />

            <Input
                text="Confirme sua nova senha"
                type="password"
                state={confirmPassword}
                setState={setConfirmPassword}
            />

            <Divider />

            {errorMessage ? (
                <ErrorMessage text={errorMessage} />
            ) : (
                <ErrorPlaceholder />
            )}

            <Divider />

            <Button
                text={
                    isLoading ? (
                        <Bars height="35px" color="#ffffff" />
                    ) : (
                        "Enviar"
                    )
                }
                onClickFunc={onClickFunc}
                background={"#0069F4"}
                fontColor={"#fff"}
            />

            <AuthNavigation text="Voltar" onClickFunc={() => setPage(0)} />
        </Form>
    );
}

const Form = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h1 {
        font-size: 22px;
        font-weight: 700;
        color: #000;
        margin-top: 100px;
        margin-bottom: 40px;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 10px 0;
`;

const PasswordRequirementsContainer = styled.div`
    width: 100%;
    height: 55px;

    display: flex;
    justify-content: space-between;

    > div {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    > div:last-child {
        justify-content: flex-end;
        width: 20%;
    }
`;

const ErrorPlaceholder = styled.div`
    width: 100%;
    height: 20px;
`;
