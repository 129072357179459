import { useNavigate, useParams } from "react-router-dom";
import { returnBackUrl } from "../../../utils/backUrl";
import { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import axios from "axios";

import logo from "../../../assets/angels_imagens/logo_azul_angels.svg";
import warning from "../../../assets/angels_imagens/confirmacao_de_acao.svg";

import ArrowAndLogoHeader from "../../../components/Headers/ArrowAndLogoHeader";
import AmountInput from "../../../components/Marketplace/Products/AmountInput";
import TwoButtonModal from "../../../components/Modals/TwoButtonModal";
import ToggleInput from "../../../components/Marketplace/ToggleInput";
import Container from "../../../components/Container";
import ImageInput from "../../../components/Marketplace/ImageInput";
import Input from "../../../components/Input";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/Button";

export default function MentorshipEdit() {
    const navigate = useNavigate();
    const { mentorshipId } = useParams();

    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [toggleAvailabilityModalIsOpen, setToggleAvailabilityModalIsOpen] =
        useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [requestIsLoading, setRequestIsLoading] = useState(false);

    const [toggleState, setToggleState] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [coverImage, setCoverImage] = useState([]);
    const [images, setImages] = useState([]);
    const [price, setPrice] = useState();
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const url = `${returnBackUrl()}/product/mentorship/${mentorshipId}`;
        const request = axios.get(url, config);

        request
            .then((res) => {
                setName(res.data.title);
                setDescription(res.data.description);

                setPrice(res.data.price);
                setToggleState(res.data.isAvailable);
                setAmount(res.data.amount);
            })
            .catch((err) => {});
    }, []);

    function confirmEditMentorship() {
        if (checkInputs()) {
            setConfirmModalIsOpen(true);
            return;
        }
    }

    function checkInputs() {
        if (!name) {
            setErrorMessage("O campo Nome é obrigatório.");
            return false;
        } else if (!description) {
            setErrorMessage("O campo Descrição é obrigatório.");
            return false;
        } else if (coverImage.length === 0) {
            setErrorMessage("O campo Imagem de venda é obrigatório.");
            return false;
        } else if (images.length === 0) {
            setErrorMessage("O campo Imagem de pôster é obrigatório.");
            return false;
        } else if (!price) {
            setErrorMessage("O campo Preço é obrigatório.");
            return false;
        } else if (isNaN(price)) {
            setErrorMessage("O campo Preço deve ser um número.");
            return false;
        } else if (isNaN(amount)) {
            setErrorMessage("O campo Quantidade deve ser um número.");
            return false;
        }

        return true;
    }

    function submitProductMentorshipEdit() {
        setRequestIsLoading(true);
        setConfirmModalIsOpen(false);
        setErrorMessage("");

        const formData = new FormData();
        formData.append("title", name);
        formData.append("description", description);
        formData.append("amount", amount);
        formData.append("coverImage", coverImage[0]);
        for (let i = 0; i < images.length; i++) {
            formData.append(`images`, images[i]);
        }
        formData.append("price", price);
        formData.append("isAvailable", toggleState);

        const body = formData;

        const url = `${returnBackUrl()}/product/mentorship/${mentorshipId}`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.put(url, body, config);

        request
            .then(() => {
                navigate("/sell");
            })
            .catch((error) => {
                setRequestIsLoading(false);
                setErrorMessage(error.response.data);
            });
    }

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    onClickFunc={() => navigate("/sell")}
                    color={"#095ece"}
                    logo={logo}
                />
                <Box>Edite sua mentoria</Box>

                <Divider />

                <ToggleInput
                    state={toggleState}
                    setState={setToggleAvailabilityModalIsOpen}
                    mainText={"Disponibilidade da mentoria"}
                    subText="Ative ou desative a sua mentoria."
                />

                <Divider />

                <Input
                    text={"Nome"}
                    subText={"Utilize no máximo 28 caracteres"}
                    type={"text"}
                    state={name}
                    setState={setName}
                    maxChar={28}
                />

                <Divider />

                <Input
                    text={"Descrição"}
                    subText={"Utilize no máximo 160 caracteres."}
                    type={"text"}
                    state={description}
                    setState={setDescription}
                    maxChar={160}
                />

                <Divider />

                <AmountInputsContainer>
                    <h1>Quantidade</h1>
                    <h2>Informe a quantidade de vagas disponíveis.</h2>
                    <div>
                        <AmountInput
                            subText={"Vagas disponíveis"}
                            state={amount}
                            setState={setAmount}
                        />
                    </div>
                </AmountInputsContainer>

                <Divider />

                <ImageInput
                    state={coverImage}
                    setState={setCoverImage}
                    text={"Imagem de venda"}
                    subText={"Selecione uma imagem em PNG com 440x200 px. "}
                    imageWidth={"440"}
                    imageHeight={"200"}
                />

                <Divider />

                <ImageInput
                    state={images}
                    setState={setImages}
                    text={"Imagem de pôster"}
                    subText={"Selecione até 3 imagens em PNG com 340x480 px. "}
                    imageWidth={"340"}
                    imageHeight={"480"}
                    imageAmount={3}
                />

                <Divider />

                <Input
                    text={"Preço (LSB)"}
                    subText={"O preço do seu produto deve ser em LSB."}
                    type={"number"}
                    state={price}
                    setState={setPrice}
                />

                <ErrorBox>
                    <ErrorMessage text={errorMessage} />
                </ErrorBox>

                <Button
                    text={
                        requestIsLoading ? (
                            <Bars height="35px" color="#ffffff" />
                        ) : (
                            "Salvar alterações"
                        )
                    }
                    onClickFunc={confirmEditMentorship}
                    background="#0069F4"
                    fontColor="#FFFFFF"
                    fontSize={"18px"}
                />

                <Divider />
            </Interface>

            <TwoButtonModal
                modalIsOpen={confirmModalIsOpen}
                setModalIsOpen={setConfirmModalIsOpen}
                modalInfo={{
                    imageSrc: warning,
                    mainText: "Deseja atualizar sua mentoria?",
                    subText:
                        "Certifique-se de os dados estão corretos antes de confirmar a alteração da sua mentoria.",
                    blueButtonFunc: () => {
                        setConfirmModalIsOpen(false);
                    },
                    blueButtonText: "Voltar",
                    whiteButtonFunc: () => {
                        submitProductMentorshipEdit();
                    },
                    whiteButtonText: "Alterar",
                }}
            />

            <TwoButtonModal
                modalIsOpen={toggleAvailabilityModalIsOpen}
                setModalIsOpen={setToggleAvailabilityModalIsOpen}
                modalInfo={{
                    imageSrc: warning,
                    mainText: toggleState
                        ? "Deseja desativar sua mentoria?"
                        : "Deseja ativar sua mentoria?",
                    subText: toggleState
                        ? "Atenção! Você está prestes a desativar sua mentoria. Caso essa seja a intenção, clique no botão ‘Desativar’ abaixo."
                        : "Atenção! Você está prestes a ativar sua mentoria. Caso essa seja a intenção, clique no botão ‘Ativar’ abaixo.",
                    blueButtonFunc: () => {
                        setToggleAvailabilityModalIsOpen(false);
                    },
                    whiteButtonText: toggleState ? "Desativar" : "Ativar",
                    whiteButtonFunc: () => {
                        setToggleState(!toggleState);
                        setToggleAvailabilityModalIsOpen(false);
                    },
                    blueButtonText: toggleState
                        ? "Manter disponível"
                        : "Manter inativo",
                }}
            />
        </Container>
    );
}

const Interface = styled.div``;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 20px 0;
`;

const ErrorBox = styled.div`
    margin: 20px 0;
`;

const Box = styled.div`
    width: 47%;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 11px;

    background-color: #0069f4;
    color: #ffffff;

    cursor: pointer;
`;

const AmountInputsContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        justify-content: space-between;
    }

    > h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        /* or 175% */

        color: #000000;

        margin-bottom: 8px;
    }

    > h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        color: #a9a9a9;

        margin-bottom: 20px;
    }
`;
