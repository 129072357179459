import styled from "styled-components";

export default function OrderLine({ justifyContent, children, index }) {
    return (
        <Line
            justifyContent={justifyContent}
            background={
                index % 2 === 0 ? "#ffffff" : "rgba(217, 217, 217, 0.2)"
            }
        >
            <Content>{children}</Content>
        </Line>
    );
}

const Line = styled.div`
    width: 100%;
    height: 35px;

    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent};
    background-color: ${(props) => props.background};

    font-size: 16px;
    color: #000000;
`;

const Content = styled.div`
    width: 80%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;
