import logo from "../../assets/angels_imagens/logo_preta_angels.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DebounceInput } from "react-debounce-input";
import { IoCheckmarkSharp } from "react-icons/io5";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import AvailableBalance from "../../components/DepositAndWithdraw/AvailableBalance";
import LogoAndMenuHeader from "../../components/Headers/LogoAndMenuHeader";
import TextBox from "../../components/DepositAndWithdraw/TextBox";
import SendOrder from "../../components/OrderBook/SendOrder";
import ErrorMessage from "../../components/ErrorMessage";
import Container from "../../components/Container";

import SignInContext from "../../contexts/SignInContext";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";

export default function Deposit() {
    const { isLogged } = useContext(SignInContext);
    const navigate = useNavigate();
    const FIVE_SECONDS = 5000;
    const THREE_SECONDS = 3000;

    const [amount, setAmount] = useState();
    const [formattedAmount, setFormattedAmount] = useState();
    const [depositErrorMessage, setDepositErrorMessage] = useState("");
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [copied, setCopied] = useState(false);
    const [depositIsLoading, setDepositIsLoading] = useState(false);

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }
    }, []);

    function sendDeposit() {
        setDepositErrorMessage("");
        setQrCodeUrl("");
        setDepositIsLoading(true);

        if (!isLogged) {
            return;
        }

        const url = `${returnBackUrl()}/balance/deposit`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const postOrder = axios.post(
            url,
            { amount: Number((formattedAmount * 100).toFixed(2)) },
            config
        );

        postOrder
            .then((res) => {
                setTimeout(() => {
                    setQrCodeUrl(res.data.qr_code_image);
                    setQrCode(res.data.qr_code_hash);
                    setCopied(false);
                    setDepositIsLoading(false);
                }, THREE_SECONDS);
            })
            .catch((err) => {
                setDepositErrorMessage(err.response.data);
                setDepositIsLoading(false);
            });
    }

    const handleMonetaryInput = (event) => {
        let valor = event.target.value;
        valor = valor.replace(/\D/g, ""); // Remove tudo que não for dígito
        valor = (parseInt(valor) / 100).toFixed(2); // Divide por 100 e fixa 2 casas decimais
        valor = valor.replace(".", ","); // Troca ponto por vírgula
        valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada 3 dígitos

        if (removeDecimalSeparator(valor) > 0) {
            setAmount(`${valor}`);
            setFormattedAmount(() => {
                return removeDecimalSeparator(valor) / 100;
            });
        } else {
            setAmount();
            setFormattedAmount();
        }
    }; //tks GPT4

    function removeDecimalSeparator(price) {
        const priceNoSymbol = price.replace(/[^0-9.,]+/g, "");
        const priceNoThousandsSeparator = priceNoSymbol.replace(/\./g, "");
        const priceNoDecimalSeparator = priceNoThousandsSeparator.replace(
            /,/g,
            ""
        );

        return priceNoDecimalSeparator;
    } //tks GPT4

    return (
        <Container>
            <div>
                <LogoAndMenuHeader
                    logo={logo}
                    color={"#ffffff"}
                    background={"#000000"}
                />
                <DepositDiv>
                    {qrCodeUrl ? (
                        <PixScreen>
                            <div>
                                <TextBox>Pagamento</TextBox>
                            </div>
                            <h3>
                                Acesse o aplicativo do banco para fazer o PIX no
                                valor de{" "}
                                {(Number(formattedAmount) + 3).toLocaleString(
                                    "pt-br",
                                    {
                                        style: "currency",
                                        currency: "BRL",
                                    }
                                )}{" "}
                            </h3>

                            <img
                                src={`data:image/jpeg;base64,${qrCodeUrl}`}
                                alt="qr code"
                            />

                            <CopyToClipboard
                                text={qrCode}
                                onCopy={() => {
                                    setCopied(true);
                                    setTimeout(() => {
                                        setCopied(false);
                                    }, FIVE_SECONDS);
                                }}
                            >
                                <Copy>
                                    {copied ? (
                                        <IoCheckmarkSharp />
                                    ) : (
                                        "Clique aqui para copiar o código do PIX"
                                    )}
                                </Copy>
                            </CopyToClipboard>

                            <h5>
                                Seu saldo será atualizado em instantes após a
                                confirmação do PIX
                            </h5>
                        </PixScreen>
                    ) : (
                        <SetValue>
                            <TextBox>Depósito</TextBox>

                            <AvailableBalance />

                            <Input>
                                <TextBox>Valor (R$): </TextBox>
                                <DebounceInput
                                    type="tel"
                                    debounceTimeout={200}
                                    value={amount}
                                    onChange={handleMonetaryInput}
                                    placeholder="Digite um valor em real"
                                />
                                <FeeWarning>
                                    <h4>
                                        Será gerada uma cobrança no valor de{" "}
                                        {!isNaN(formattedAmount)
                                            ? Number(
                                                  formattedAmount
                                              ).toLocaleString("pt-br", {
                                                  style: "currency",
                                                  currency: "BRL",
                                              })
                                            : Number(0).toLocaleString(
                                                  "pt-br",
                                                  {
                                                      style: "currency",
                                                      currency: "BRL",
                                                  }
                                              )}{" "}
                                        e será cobrada uma taxa de R$3,00 para
                                        cobrir os custos de transação.
                                    </h4>
                                </FeeWarning>
                                <ErrorMessage text={depositErrorMessage} />
                            </Input>
                        </SetValue>
                    )}
                </DepositDiv>

                {qrCodeUrl ? (
                    <></>
                ) : (
                    <SendOrder
                        sendOrderLoading={depositIsLoading}
                        onClickFunc={sendDeposit}
                        text={"CONFIRMAR DEPÓSITO"}
                    />
                )}
            </div>
        </Container>
    );
}

const DepositDiv = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;

        color: #000000;
    }
`;

const Input = styled.div`
    width: 100%;

    margin-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > h4 {
        width: 100%;

        font-size: 14px;
        color: #808080;
        text-align: center;
        width: 60%;
        line-height: 18px;

        margin-top: 10px;
    }

    > input {
        width: 100%;
        height: 40px;

        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        padding-left: 10px;
        font-size: 16px;

        display: flex;
        align-items: center;
    }

    > input:focus {
        outline: none;
    }
`;

const PixScreen = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: justify;

    > img {
        width: 60%;
    }

    > h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: center;

        margin-bottom: 20px;

        color: #000000;
    }

    > h2 {
        font-size: 14px;
        margin-top: 10px;

        text-align: center;
        color: #808080;
    }

    > h5 {
        font-size: 14px;
        text-align: center;
        color: #808080;

        width: 60%;

        margin-top: 10px;
    }

    > div:first-child {
        width: 100%;

        display: flex;
        justify-content: flex-start;
    }
`;

const SetValue = styled.div`
    width: 100%;

    padding: 0 20px;

    > h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;

        color: #000000;
    }
`;

const Copy = styled.div`
    width: 336px;
    height: 66px;

    padding: 15px;

    margin-top: 20px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0069f4;
    color: #ffffff;
    font-weight: 700;

    cursor: pointer;

    svg {
        font-size: 20px;

        cursor: pointer;
    }
`;

const FeeWarning = styled.div`
    width: 100%;
    height: 50px;

    margin-top: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    text-align: center;

    color: rgba(0, 0, 0, 0.4);
`;
