import { DebounceInput } from "react-debounce-input";
import styled from "styled-components";

export default function Input({
    width,
    text,
    subText,
    type,
    state,
    setState,
    placeholder,
    maxChar,
}) {
    function updateState(e) {
        const inputValue = e.target.value;

        if (text === "CPF:") {
            setState(formatCPF(inputValue));
            return;
        } else if (text === "Informe o seu telefone:") {
            setState(formatPhoneNumber(inputValue));
            return;
        } else if (text === "Digite o seu CEP") {
            setState(formatCEP(inputValue));
            return;
        }

        setState(inputValue);
        return;
    }

    function formatCPF(cpf) {
        // Remove any non-numeric characters
        cpf = cpf.replace(/\D/g, "");

        // Apply the CPF mask
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

        return cpf;
    } //tks GPT4

    function formatPhoneNumber(value) {
        value = value.replace(/\D/g, ""); // Remove any non-digit characters
        value = value.substring(0, 11); // Limit the number of characters to 11

        let formattedValue = "";
        for (let i = 0; i < value.length; i++) {
            if (i === 0) formattedValue += "(";
            if (i === 2) formattedValue += ") ";
            if (i === 7) formattedValue += "-";
            formattedValue += value[i];
        }

        return formattedValue;
    } //tks GPT4

    function formatCEP(value) {
        return value
            .replace(/\D/g, "") // Remove non-digit characters
            .replace(/^(\d{5})(\d)/, "$1-$2") // Insert the dash after the 5th digit
            .substring(0, 9); // Limit the input to 9 characters
    } //tks GPT4

    return (
        <InputField width={width}>
            <h3>{text}</h3>
            <h4>{subText}</h4>
            <DebounceInput
                placeholder={placeholder}
                value={state}
                type={type}
                onChange={updateState}
                maxLength={maxChar}
            />
        </InputField>
    );
}

Input.defaultProps = {
    width: "100%",
    maxChar: 1000,
};

const InputField = styled.div`
    width: ${(props) => props.width};

    margin-bottom: 10px;

    > input {
        width: 100%;
        height: 30px;

        font-size: 20px;
        color: #000000;

        border: none;
        border-bottom: 2px solid #000000;

        background-color: #ffffff;
    }

    > input:focus {
        outline: none;
    }

    > input::placeholder {
        color: #a9a9a9;
    }

    > h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    > h4 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 20px;
        color: #a9a9a9;
    }
`;
