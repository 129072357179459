import BalanceContext from "../../contexts/BalanceContext";
import { IoWalletOutline } from "react-icons/io5";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import { useContext } from "react";

export default function AvailableBalance() {
    const { realAmount, balanceIsLoading } = useContext(BalanceContext);

    return (
        <BalanceSection>
            {balanceIsLoading ? (
                <LoadingSection>
                    <Bars height="35px" color="#000000" />
                </LoadingSection>
            ) : (
                <>
                    <div>
                        <h2>Saldo disponível</h2> <IoWalletOutline />
                    </div>
                    <h1>
                        {parseFloat(realAmount).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}
                    </h1>
                </>
            )}
        </BalanceSection>
    );
}

const BalanceSection = styled.div`
    width: 100%;
    height: 120px;
    padding: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;

        color: #000000;
    }

    > h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        color: #000000;
    }

    > div {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: space-between;
    }
`;

const LoadingSection = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center !important;
    align-items: center;
`;
