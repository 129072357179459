import styled from "styled-components";

export default function Button({
    text,
    onClickFunc,
    background,
    border,
    fontColor,
    width,
    buttonVisibility,
    height,
    borderRadius,
    fontSize,
    fontWeight,
}) {
    return (
        <ButtonDiv
            width={width}
            onClick={onClickFunc}
            background={background}
            border={border}
            fontColor={fontColor}
            visibility={buttonVisibility}
            height={height}
            borderRadius={borderRadius}
            fontSize={fontSize}
            fontWeight={fontWeight}
        >
            {text}
        </ButtonDiv>
    );
}

Button.defaultProps = {
    width: "100%",
    height: "52px",
    buttonVisibility: "visible",
    border: "none",
    borderRadius: "12px",
    fontSize: "15px",
    fontWeight: "bold",
};

const ButtonDiv = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.fontColor};

    background: ${(props) => props.background};
    border: ${(props) => props.border};
    border-radius: ${(props) => props.borderRadius};

    visibility: ${(props) => props.visibility};

    cursor: pointer;

    > svg {
        color: #ffffff;
    }
`;
