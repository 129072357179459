import styled from "styled-components";

import Line from "./OrderLine";
import PriceBox from "./PriceBox";

export default function BuyingsList({ buyings, selectOrder, biggerArray }) {
    const BLUE = "#0069F4";
    const JUSTIFY_CONTENT = "flex-end";

    function renderEmptyLines() {
        const linesArray = [];

        biggerArray.map((item, index) => {
            if (buyings[index]) {
                return;
            } else {
                linesArray.push(
                    <Line
                        key={index}
                        index={index}
                        justifyContent={JUSTIFY_CONTENT}
                    ></Line>
                );
            }
        });

        return linesArray;
    }

    return (
        <BuyingsListContainer>
            <Line index={0} justifyContent={JUSTIFY_CONTENT}>
                <h5>Qtd</h5>
                <h5>Compra</h5>
            </Line>
            {buyings.length > 0 ? (
                buyings.map((buying, index) => {
                    return (
                        <Line
                            key={index}
                            index={index}
                            justifyContent={JUSTIFY_CONTENT}
                        >
                            <h5>{buying.availableAmount}</h5>
                            <PriceBox
                                order={buying}
                                background={BLUE}
                                onClickFunc={selectOrder}
                            >
                                {buying.unitPrice / 100}
                            </PriceBox>
                        </Line>
                    );
                })
            ) : (
                <></>
            )}

            {biggerArray.length > buyings.length ? (
                renderEmptyLines().map((item) => item)
            ) : (
                <></>
            )}
        </BuyingsListContainer>
    );
}

const BuyingsListContainer = styled.div`
    width: 50%;
    height: 100%;
`;
