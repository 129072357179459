import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

export default function DateInput({ selectedDate, setSelectedDate }) {
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    function formatDateAsString() {
        if (!selectedDate) return "";

        let date = new Date(selectedDate);

        let dateOptions = {
            weekday: "long",
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        };

        let timeOptions = {
            hour: "2-digit",
            minute: "2-digit",
        };

        let formattedDate = date.toLocaleDateString("pt-BR", dateOptions);
        let formattedTime = date.toLocaleTimeString("pt-BR", timeOptions);

        // Capitalize the first letter of the weekday
        formattedDate =
            formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

        return `${formattedDate}\nHorário: ${formattedTime}`;
    } //tks GPT4

    return (
        <Container>
            <h1>Data da experiência</h1>
            <h2>
                Informe o dia e horário. Caso a experiência se extender por mais
                de um dia, informe na descrição.
            </h2>
            <div className="date-picker-wrapper">
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy HH:mm"
                    customInput={
                        <CustomInput>Escolher data e hora</CustomInput>
                    }
                />

                <h2>{formatDateAsString()}</h2>
            </div>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;

    > h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    > h2 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 20px;
        color: #a9a9a9;
    }

    > div {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        > h2 {
            width: 80%;

            font-size: 14px;
            line-height: 16px;
            color: #000;
        }
    }
`;

const CustomInput = styled.div`
    width: 134px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #f1f0f5;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 11px;

    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 25px;
    /* identical to box height, or 227% */

    color: #000000;
`;
