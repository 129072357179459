import { useState, useContext, useEffect } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import axios from "axios";

import BalanceContext from "../../contexts/BalanceContext";
import SignInContext from "../../contexts/SignInContext";

import emailCheck from "../../assets/angels_imagens/popups/verificacao_email.svg";

import Container from "../../components/Container";
import LogoHeader from "../../components/Headers/OnlyLogoHeader";
import AuthNavigation from "../../components/Navigation";
import ErrorMessage from "../../components/ErrorMessage";
import PreviousPage from "../../components/SignUp/PreviousPage";
import Button from "../../components/Button";
import InputModal from "../../components/Modals/InputModal";

import NameStep from "../../components/SignUp/NameStep";
import EmailStep from "../../components/SignUp/EmailStep";
import CpfStep from "../../components/SignUp/CPFStep";
import PhoneStep from "../../components/SignUp/PhoneStep";
import AddressStep from "../../components/SignUp/AddressStep";
import PasswordStep from "../../components/SignUp/PasswordStep";
import ConfirmPasswordStep from "../../components/SignUp/ConfirmPasswordStep";

export default function SignUp() {
    const navigate = useNavigate();
    const { setIsLogged, isLogged } = useContext(SignInContext);
    const { setRefreshBalance } = useContext(BalanceContext);

    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [complement, setComplement] = useState("");
    const [number, setNumber] = useState("");
    const [cep, setCep] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [authCodeError, setAuthCodeError] = useState(false);
    const [emailAuthCode, setEmailAuthCode] = useState("");
    const [authCodeModalIsOpen, setAuthCodeModalIsOpen] = useState(false);

    useEffect(() => {
        if (isLogged) {
            navigate("/home");
        }
    }, []);

    const steps = [
        <NameStep state={fullName} setState={setFullName} />,
        <EmailStep state={email} setState={setEmail} />,
        <CpfStep state={cpf} setState={setCpf} />,
        <PhoneStep state={phone} setState={setPhone} />,
        <AddressStep
            state={address}
            setState={setAddress}
            cep={cep}
            setCep={setCep}
            number={number}
            setNumber={setNumber}
            complement={complement}
            setComplement={setComplement}
        />,
        <PasswordStep state={password} setState={setPassword} />,
        <ConfirmPasswordStep
            state={confirmPassword}
            setState={setConfirmPassword}
        />,
    ];

    function renderCurrentStep() {
        return steps[currentStep];
    }

    function nextPage() {
        const lsbRegex =
            /^[a-zA-Z0-9._%+-]+@(lsb|aluno\.lsb)(\.[a-zA-Z0-9._%+-]+)*$/;
        const cpfRegex = /^\d{3}.\d{3}.\d{3}-\d{2}$/;
        const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\!@#\$%\^&\*\(\)\-_=\+[\]{};':"\\|,.<>\/?]).{8,}$/;
        const cepRegex = /^[0-9]{5}-[0-9]{3}$/;

        if (currentStep === steps.length - 1) {
            return;
        } else if (currentStep === 0 && fullName === "") {
            setErrorMessage("Preencha o campo nome");
            return;
        } else if (currentStep === 1 && email === "") {
            setErrorMessage("Preencha o campo email");
            return;
        } else if (currentStep === 1 && !lsbRegex.test(email)) {
            setErrorMessage(
                "Somente emails @lsb ou @aluno.lsb são permitidos."
            );
            return;
        } else if (currentStep === 2 && cpf === "") {
            setErrorMessage("Preencha o campo CPF");
            return;
        } else if (currentStep === 2 && !cpfRegex.test(cpf)) {
            setErrorMessage("Preencha o campo CPF corretamente.");
            return;
        } else if (currentStep === 3 && phone === "") {
            setErrorMessage("Preencha o campo telefone");
            return;
        } else if (currentStep === 3 && !phoneRegex.test(phone)) {
            setErrorMessage("Preencha o campo telefone corretamente");
            return;
        } else if (currentStep === 4 && (cep === "" || number === "")) {
            setErrorMessage("Preencha o CEP e o número");
            return;
        } else if (currentStep === 4 && !cepRegex.test(cep)) {
            setErrorMessage("Informe o CEP corretamente");
            return;
        } else if (currentStep === 5 && password === "") {
            setErrorMessage("Preencha o campo senha");
            return;
        } else if (currentStep === 5 && !passwordRegex.test(password)) {
            setErrorMessage("A senha deve cumprir todos os requisitos");
            return;
        } else if (currentStep === 6 && confirmPassword === "") {
            setErrorMessage("Preencha o campo confirmação de senha");
            return;
        }

        setCurrentStep((prevState) => prevState + 1);
        setErrorMessage("");
    }

    function previousPage() {
        if (currentStep === 0) {
            return;
        }

        setCurrentStep((prevState) => prevState - 1);
    }

    function submitSignUp() {
        setIsLoading(true);

        const url = `${returnBackUrl()}/sign-up`;
        const body = {
            fullName,
            email,
            cpf,
            password,
            confirmPassword,
            phone,
            address,
            emailAuthCode,
        };

        const signUpPromise = axios.post(url, body);

        signUpPromise
            .then((res) => {
                const url = `${returnBackUrl()}/sign-in`;
                const body = { email, password };
                const signInPromise = axios.post(url, body);

                signInPromise
                    .then((res) => {
                        setIsLogged(true);
                        localStorage.setItem(
                            "userData",
                            JSON.stringify(res.data)
                        );
                        setRefreshBalance((prevState) => !prevState);
                        navigate("/home");
                    })
                    .catch(() => {
                        navigate("/home");
                    });
                setIsLoading(false);
            })
            .catch((err) => {
                if (
                    err.response.data.includes(
                        "Informe o código de verificação que foi enviado para o seu email."
                    )
                ) {
                    setAuthCodeModalIsOpen(true);
                    setIsLoading(false);
                    return;
                } else if (
                    err.response.data.includes(
                        "Código de verificação inválido."
                    )
                ) {
                    setAuthCodeError(true);
                    setIsLoading(false);
                    return;
                }

                setIsLoading(false);
                setErrorMessage(err.response.data);
                setCurrentStep(() => setStepOnError(err.response.data));
            });
    }

    function setStepOnError(message) {
        let step = 0;

        if (message.toLowerCase().includes("email")) {
            step = 1;
        } else if (message.toLowerCase().includes("cpf")) {
            step = 2;
        } else if (message.toLowerCase().includes("confirmação")) {
            step = 6;
        } else if (message.toLowerCase().includes("senha")) {
            step = 5;
        } else if (message.toLowerCase().includes("telefone")) {
            step = 3;
        } else if (message.toLowerCase().includes("cep")) {
            step = 4;
        }

        return step;
    }

    return (
        <Container>
            <Interface>
                <LogoHeader />

                <PreviousPage
                    changeStep={previousPage}
                    currentStep={currentStep}
                />

                {renderCurrentStep()}

                <ErrorMessage text={errorMessage} />

                {currentStep === steps.length - 1 ? (
                    <CommandsContainer>
                        <NextPage>
                            <Button
                                background="#0069F4"
                                border="1px solid #0069F4"
                                text={
                                    isLoading ? (
                                        <Bars height="35px" color="#ffffff" />
                                    ) : (
                                        "Concordar e Finalizar a criação da sua conta"
                                    )
                                }
                                fontColor="#ffffff"
                                onClickFunc={submitSignUp}
                            />
                        </NextPage>

                        <AuthNavigation
                            text={"Já possui uma conta? Entrar agora"}
                            onClickFunc={() => navigate("/sign-in")}
                        />
                    </CommandsContainer>
                ) : (
                    <CommandsContainer>
                        <Button
                            background="#0069F4"
                            border="1px solid #0069F4"
                            text="Próximo"
                            fontColor="#ffffff"
                            onClickFunc={nextPage}
                        />
                        <AuthNavigation
                            text={"Já possui uma conta? Entrar agora"}
                            onClickFunc={() => navigate("/sign-in")}
                        />
                    </CommandsContainer>
                )}
            </Interface>

            <InputModal
                isLoading={isLoading}
                modalIsOpen={authCodeModalIsOpen}
                setModalIsOpen={setAuthCodeModalIsOpen}
                inputState={emailAuthCode}
                setState={setEmailAuthCode}
                errorMessage={authCodeError ? "Código inválido." : ""}
                modalInfo={{
                    imageSrc: emailCheck,
                    whiteButtonText: "voltar",
                    blueButtonText: "Verificar",
                    whiteButtonFunc: () => {
                        setAuthCodeModalIsOpen(false);
                    },
                    blueButtonFunc: () => {
                        submitSignUp();
                    },
                    mainText: "Verificação de e-mail",
                    subText: `Insira abaixo o código de verificação que foi enviado para o seu e-mail.`,
                }}
            />
        </Container>
    );
}

const Interface = styled.div`
    height: calc(100vh - 260px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const CommandsContainer = styled.div`
    width: 100%;
    height: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const NextPage = styled.div`
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 35px;
    }
`;
