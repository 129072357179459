import { DebounceInput } from "react-debounce-input";
import styled from "styled-components";

import Button from "../Button";
import ErrorMessage from "../../components/ErrorMessage";

export default function CreateTransferControls({
    activeButton,
    setActiveButton,
    amount,
    setAmount,
    price,
    setPrice,
    setTotalValue,
    totalValue,
    priceErrorMessage,
    amountErrorMessage,
    formattedPrice,
    setFormattedPrice,
    marketFee,
}) {
    const BUY_FEE = 1.05;
    const SELL_FEE = 0.95;

    const handleMonetaryInput = (event) => {
        let valor = event.target.value;
        valor = valor.replace(/\D/g, ""); // Remove tudo que não for dígito
        valor = (parseInt(valor) / 100).toFixed(2); // Divide por 100 e fixa 2 casas decimais
        valor = valor.replace(".", ","); // Troca ponto por vírgula
        valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada 3 dígitos

        if (removeDecimalSeparator(valor) > 0) {
            setPrice(`${valor}`);
            setFormattedPrice(() => {
                return Number(removeDecimalSeparator(valor) / 100).toFixed(2);
            });
            setTotalValue(() => {
                return Number(
                    (removeDecimalSeparator(valor) / 100) * amount
                ).toFixed(2);
            });
        } else {
            setPrice(0);
            setFormattedPrice(0);
            setTotalValue(0);
        }
    }; //tks GPT4

    function handleAmountInput(e) {
        let valor = e.target.value;
        valor = valor.replace(/\D/g, "");
        valor = parseInt(valor, 10);

        if (isNaN(valor)) {
            setAmount(0);
            setTotalValue(0);
        } else {
            setAmount(valor);
            setTotalValue(valor * formattedPrice);
        }
    }

    function removeDecimalSeparator(price) {
        const priceNoSymbol = price.replace(/[^0-9.,]+/g, "");
        const priceNoThousandsSeparator = priceNoSymbol.replace(/\./g, "");
        const priceNoDecimalSeparator = priceNoThousandsSeparator.replace(
            /,/g,
            ""
        );

        return Number(priceNoDecimalSeparator);
    } //tks GPT4

    function getTotalValueWFee() {
        if (!totalValue) {
            return "";
        }

        if (activeButton === "BUY") {
            return totalValue * (1 + marketFee / 100).toFixed(2);
        } else {
            return totalValue * (1 - marketFee / 100).toFixed(2);
        }
    }

    return (
        <TransferInfo>
            <Buttons>
                <Button
                    text="COMPRAR"
                    onClickFunc={() => setActiveButton("BUY")}
                    background={activeButton === "BUY" ? "#0069F4" : "#FFFFFF"}
                    border={
                        activeButton === "BUY"
                            ? "none"
                            : "1px solid rgba(0, 0, 0, 0.1)"
                    }
                    fontColor={activeButton === "BUY" ? "#ffffff" : "#000000"}
                    width="173px"
                    height="32px"
                    borderRadius="8px"
                />
                <Button
                    text="VENDER"
                    onClickFunc={() => setActiveButton("SELL")}
                    background={activeButton === "SELL" ? "#0069F4" : "#FFFFFF"}
                    border={
                        activeButton === "SELL"
                            ? "none"
                            : "1px solid rgba(0, 0, 0, 0.1)"
                    }
                    fontColor={activeButton === "SELL" ? "#ffffff" : "#000000"}
                    width="173px"
                    height="32px"
                    borderRadius="8px"
                />
            </Buttons>

            <Input>
                <h5>Quantidade</h5>
                <DebounceInput
                    type="tel"
                    debounceTimeout={200}
                    value={amount}
                    onChange={handleAmountInput}
                />
                <ErrorMessage text={amountErrorMessage} />
            </Input>

            <Input>
                <h5>Preço unitário (R$)</h5>
                <DebounceInput
                    type="tel"
                    value={price}
                    onChange={handleMonetaryInput}
                />
                <ErrorMessage text={priceErrorMessage} />
            </Input>

            <Input>
                <h5>Valor total estimado (R$)</h5>
                <TotalAmountLabel>
                    {!isNaN(getTotalValueWFee()) ? (
                        getTotalValueWFee().toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })
                    ) : (
                        <></>
                    )}
                </TotalAmountLabel>
            </Input>
        </TransferInfo>
    );
}

const TransferInfo = styled.div`
    width: 100%;

    margin-bottom: 110px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Buttons = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;

    margin-top: 20px;
`;

const Input = styled.div`
    width: 100%;

    margin-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > h5 {
        margin-bottom: 10px;
        margin-left: 10px;
        font-size: 14px;
        color: #000000;
    }

    > input {
        width: 100%;
        height: 32px;

        padding-left: 10px;
        font-size: 16px;

        display: flex;
        align-items: center;

        background: #ffffff;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        &::placeholder {
            color: #949494;
        }
    }

    > div {
        width: 100%;
        margin: 5px 0%;
    }
`;

const TotalAmountLabel = styled.div`
    width: 100%;
    height: 32px;

    background: #f1f0f5;
    border-radius: 8px;

    padding-left: 10px;

    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #0069f4;
`;
