import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import shoppingCart from "../../assets/angels_imagens/menu/comprar_produtos.svg";
import bull from "../../assets/angels_imagens/menu/ir_para_corretora.svg";
import hat from "../../assets/angels_imagens/menu/ver_posicoes.svg";
import pig from "../../assets/angels_imagens/menu/depositar.svg";
import withdraw from "../../assets/angels_imagens/menu/sacar.svg";
import logoutImage from "../../assets/angels_imagens/turn_off.svg";
import exportData from "../../assets/angels_imagens/menu/extratos.svg";
import diamond from "../../assets/angels_imagens/menu/aquisicoes.svg";
import vender from "../../assets/angels_imagens/menu/vender.svg";

import TwoButtonModal from "../../components/Modals/TwoButtonModal";
import MenuHeader from "../../components/Menu/MenuHeader";
import MenuItem from "../../components/Menu/MenuItem";
import Container from "../../components/Container";

import SignInContext from "../../contexts/SignInContext";

export default function Menu() {
    const { setIsLogged, isLogged } = useContext(SignInContext);
    const navigate = useNavigate();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }
    }, []);

    function logout() {
        localStorage.removeItem("userData");
        setIsLogged(false);
        navigate("/");
    }

    return (
        <Container>
            <Interface>
                <MenuHeader setModalIsOpen={setModalIsOpen} />
                <MenuItem
                    icon={shoppingCart}
                    text="Comprar produtos"
                    navigateTo={"/benefits"}
                />
                <MenuItem
                    icon={bull}
                    text="Ir para a corretora"
                    navigateTo={"/market"}
                />
                <MenuItem
                    icon={hat}
                    text="Ver posições"
                    navigateTo={"/user/orders"}
                />
                <MenuItem
                    icon={diamond}
                    text="Aquisições"
                    navigateTo={"/yourbuyings"}
                />
                {/* <MenuItem
                    icon={diamond}
                    text="Seus tokens"
                    navigateTo={"/yourbuyings/tokens"}
                /> */}
                <MenuItem icon={pig} text="Depositar" navigateTo={"/deposit"} />
                <MenuItem
                    icon={exportData}
                    text="Extratos"
                    navigateTo={"/profile"}
                />
                <MenuItem icon={vender} text="Vender" navigateTo={"/sell"} />
                <MenuItem
                    icon={withdraw}
                    text="Sacar"
                    navigateTo={"/withdraw"}
                />
            </Interface>

            <TwoButtonModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                modalInfo={{
                    imageSrc: logoutImage,
                    whiteButtonText: "sair",
                    blueButtonText: "Permanecer",
                    whiteButtonFunc: logout,
                    blueButtonFunc: () => {
                        setModalIsOpen(false);
                    },
                    mainText: "Sair da Angels?",
                    subText: "Você tem certeza que deseja sair da Angels?",
                }}
                buttonActionFunc={logout}
            />
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;

    padding-top: 40px;
    margin-bottom: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #095ece;
`;
