import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { returnBackUrl } from "../../../utils/backUrl";

import logo from "../../../assets/angels_imagens/logo_azul_angels.svg";

import Container from "../../../components/Container";
import ArrowAndLogoHeader from "../../../components/Headers/ArrowAndLogoHeader";
import Button from "../../../components/Button";
import BenefitsHomeCard from "../../../components/Benefits/BenefitsHomeCard";

export default function ExperienceEditSelect() {
    const navigate = useNavigate();
    const carouselRef = useRef(null);

    const [products, setProducts] = useState([]);

    useEffect(() => {
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const url = `${returnBackUrl()}/product/experience/user`;
        const request = axios.get(url, config);

        request
            .then((res) => {
                setProducts([...res.data]);
            })
            .catch((err) => {});
    }, []);

    const scroll = (direction) => {
        if (direction === "left") {
            carouselRef.current.scrollBy({ left: -500, behavior: "smooth" });
        } else {
            carouselRef.current.scrollBy({ left: 500, behavior: "smooth" });
        }
    }; //tks GPT4

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    logo={logo}
                    color={"#095ece"}
                    onClickFunc={() => {
                        navigate("/experience/create");
                    }}
                />
                <Box>Edite suas experiências</Box>

                <Divider />

                <Button
                    text={"Experiências"}
                    onClickFunc={() => {}}
                    background={"#F1F0F5"}
                    border={"1px solid rgba(0, 0, 0, 0.1);"}
                    fontColor={"#000"}
                    width={"45%"}
                    height={"39px"}
                    fontSize={"17px"}
                />

                <div className="carousel-container">
                    <button
                        className="carousel-button-dark carousel-button-left"
                        onClick={() => scroll("left")}
                    >
                        &lt;
                    </button>

                    <div className="carousel" ref={carouselRef}>
                        {products.length !== 0 ? (
                            products.map((item, index) => (
                                <CardHolder
                                    key={index}
                                    onClick={() => {
                                        navigate(`/experience/edit/${item.id}`);
                                    }}
                                >
                                    <BenefitsHomeCard>
                                        <img
                                            src={item.coverImageURL}
                                            alt="product"
                                        />
                                    </BenefitsHomeCard>
                                </CardHolder>
                            ))
                        ) : (
                            <Empty>
                                <h1>Nenhum produto encontrado</h1>
                            </Empty>
                        )}
                    </div>

                    <button
                        className="carousel-button-dark carousel-button-right"
                        onClick={() => scroll("right")}
                    >
                        &gt;
                    </button>
                </div>
            </Interface>
        </Container>
    );
}

const Interface = styled.div``;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 10px 0;
`;

const Empty = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 18px;
        font-weight: 500;
        color: #000;
    }
`;

const Box = styled.div`
    width: 52%;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 11px;

    background-color: #0069f4;
    color: #ffffff;

    cursor: pointer;
`;

const CardHolder = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & + & {
        margin-left: 20px;
    }
`;
