import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";

import logo from "../../../assets/angels_imagens/logo_azul_angels.svg";

import SelectProductType from "../../../components/Marketplace/Products/SelectProductType";
import CreateAndEditSection from "../../../components/Marketplace/CreateAndEditSection";
import ArrowAndLogoHeader from "../../../components/Headers/ArrowAndLogoHeader";
import ProductClothingCreate from "./ProductClothingCreate";
import ProductObjectCreate from "./ProductObjectCreate";
import Container from "../../../components/Container";

export default function ProductsCreate() {
    const location = useLocation();
    const navigate = useNavigate();

    const [productType, setProductType] = useState("clothing");

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    logo={logo}
                    color={"#095ece"}
                    onClickFunc={() => {
                        navigate("/sell");
                    }}
                />

                <CreateAndEditSection
                    productType={"produto"}
                    navigateTo={"/product/edit"}
                />

                <SelectProductType
                    selected={productType}
                    setSelected={setProductType}
                />

                {location.pathname === "/product/create" ? (
                    <>
                        {productType === "clothing" ? (
                            <ProductClothingCreate />
                        ) : (
                            <ProductObjectCreate />
                        )}
                    </>
                ) : (
                    <></>
                )}
            </Interface>
        </Container>
    );
}

const Interface = styled.div``;
