import styled from "styled-components";

export default function DashboardProductCard({ product, setOpenedProduct }) {
    return (
        <Container>
            <div>
                <h1>Item: </h1>
                <h2>{product.productTitle}</h2>
            </div>
            <div>
                <h1>Comprador: </h1>
                <h2>{product.buyerName}</h2>
            </div>
            <div onClick={() => setOpenedProduct(product)}>Mais detalhes</div>
        </Container>
    );
}

const Container = styled.div`
    width: 100px;
    height: 127px;

    background: #0069f4;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
        0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 21px;

    padding: 8px 10px;
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    & + & {
        margin-left: 10px;
    }

    h1 {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.333333px;

        color: #ffffff;
    }

    h2 {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.333333px;

        color: #ffffff;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    > div:last-child {
        width: 79px;
        height: 20px;

        background: #0069f4;
        border: 0.5px solid #ffffff;
        border-radius: 9px;

        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.333333px;

        color: #ffffff;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`;
