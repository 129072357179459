import logo from "../../assets/angels_imagens/logo_preta_angels.svg";
import { DebounceInput } from "react-debounce-input";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import AvailableBalance from "../../components/DepositAndWithdraw/AvailableBalance";
import TransferConfirmation from "../../components/OrderBook/SendOrder";
import LogoAndMenuHeader from "../../components/Headers/LogoAndMenuHeader";
import TextBox from "../../components/DepositAndWithdraw/TextBox";
import OneButtonModal from "../../components/Modals/OneButtonModal";
import TwoButtonModal from "../../components/Modals/TwoButtonModal";
import BalanceContext from "../../contexts/BalanceContext";
import ErrorMessage from "../../components/ErrorMessage";
import InputModal from "../../components/Modals/InputModal";
import Container from "../../components/Container";

import twoFA from "../../assets/angels_imagens/popups/2FA.svg";
import error from "../../assets/angels_imagens/popups/comprar_erro.svg";
import confirm from "../../assets/angels_imagens/popups/processando_confirmada.svg";
import google from "../../assets/angels_imagens/popups/google_authenticator.svg";
import key from "../../assets/angels_imagens/popups/key.svg";
import phone2fa from "../../assets/angels_imagens/popups/verificacao.svg";
import success from "../../assets/angels_imagens/popups/sucesso.svg";

import SignInContext from "../../contexts/SignInContext";

export default function Withdraw() {
    const { setRefreshBalance } = useContext(BalanceContext);
    const { isLogged } = useContext(SignInContext);
    const navigate = useNavigate();

    const [oneButtonModalIsOpen, setOneButtonModalIsOpen] = useState(false);
    const [twoButtonModalIsOpen, setTwoButtonModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState("error");
    const [twoButtonModalType, setTwoButtonModalType] = useState("googleAuth");
    const [withdrawIsLoading, setWithdrawIsLoading] = useState(false);

    const [userKey, setUserKey] = useState("");
    const [amount, setAmount] = useState();
    const [formattedAmount, setFormattedAmount] = useState();
    const [amountError, setAmountError] = useState();
    const [keyError, setKeyError] = useState();

    const [inputModalIsOpen, setInputModalIsOpen] = useState(false);
    const [userAuthCode, setUserAuthCode] = useState("");
    const [authError, setAuthError] = useState(false);
    const [authIsLoading, setAuthIsLoading] = useState(false);

    const oneButtonModals = {
        error: {
            imageSrc: error,
            mainText: "Saldo insuficiente",
            subText: "Você não tem saldo suficiente para realizar esse saque",
            buttonText: "Voltar",
            buttonWidth: "80%",
            buttonBackground: "#0069F4",
            hideX: true,
            buttonActionFunc: () => {
                setOneButtonModalIsOpen(false);
            },
        },
        confirm: {
            imageSrc: confirm,
            mainText: "Solicitação confirmada",
            subText:
                "Sua solicitação foi confirmada. Dentro de algumas horas alguém da Angels entrará em contato com você para confirmar sua identidade.",
            buttonText: "Voltar para a home",
            buttonWidth: "80%",
            buttonBackground: "#0069F4",
            hideX: true,
            buttonActionFunc: () => {
                navigate("/home");
            },
        },
        require2FA: {
            imageSrc: twoFA,
            mainText: "Verificação em duas etapas",
            subText:
                "Para sua segurança, você precisa ativar a verificação de dois fatores antes de realizar o seu primeiro saque.",
            buttonText: "Ativar",
            buttonWidth: "80%",
            buttonBackground: "#0069F4",
            buttonActionFunc: activate2FA,
        },
        authActivated: {
            imageSrc: success,
            mainText: "Autenticador ativo",
            subText:
                "Parabéns, você tem uma conta mais segura. Para fazer o login utilize sempre o autenticador.",
            buttonText: "Confirmar saque",
            buttonWidth: "80%",
            buttonBackground: "#0069F4",
            buttonActionFunc: () => {
                sendWithdraw();
            },
        },
    };

    const twoButtonModals = {
        googleAuth: {
            imageSrc: google,
            whiteButtonText: "voltar",
            blueButtonText: "Próximo",
            whiteButtonFunc: () => {
                setTwoButtonModalIsOpen(false);
                setOneButtonModalIsOpen(true);
                setModalType("require2FA");
            },
            blueButtonFunc: () => {
                setTwoButtonModalType("key");
            },
            mainText: "Google Authenticator",
            subText: `Vá até a App Store ou a Google Play e baixe o app Google Authenticator.`,
        },
        key: {
            imageSrc: key,
            whiteButtonText: "voltar",
            blueButtonText: "Próximo",
            whiteButtonFunc: () => {
                setTwoButtonModalType("googleAuth");
            },
            blueButtonFunc: () => {
                setTwoButtonModalType("activationBegin");
            },
            mainText: "Chave criptográfica",
            subText: `Copie a chave criptográfica que foi enviada para o seu e-mail cadastrado na sua conta Angels.`,
        },
        activationBegin: {
            imageSrc: google,
            whiteButtonText: "voltar",
            blueButtonText: "Próximo",
            whiteButtonFunc: () => {
                setTwoButtonModalType("key");
            },
            blueButtonFunc: () => {
                setTwoButtonModalType("activationAddKey");
            },
            mainText: "Ativação do autenticador",
            subText:
                "Volte no app Google Authenticator e clique em 'Vamos começar'.",
        },
        activationAddKey: {
            imageSrc: google,
            whiteButtonText: "voltar",
            blueButtonText: "Próximo",
            whiteButtonFunc: () => {
                setTwoButtonModalType("activationBegin");
            },
            blueButtonFunc: () => {
                setTwoButtonModalType("activationAccount");
            },
            mainText: "Ativação do autenticador",
            subText:
                "Na sequência, selecione a opção ‘Inserir chave de configuração’.",
        },
        activationAccount: {
            imageSrc: google,
            whiteButtonText: "voltar",
            blueButtonText: "Próximo",
            whiteButtonFunc: () => {
                setTwoButtonModalType("activationAddKey");
            },
            blueButtonFunc: () => {
                setTwoButtonModalType("activationBaseKey");
            },
            mainText: "Ativação do autenticador",
            subText:
                "Digite 'Angels' como 'Nome da Conta' e cole a chave copiada do seu e-mail no campo 'Sua chave'.",
        },
        activationBaseKey: {
            imageSrc: google,
            whiteButtonText: "voltar",
            blueButtonText: "Próximo",
            whiteButtonFunc: () => {
                setTwoButtonModalType("activationAccount");
            },
            blueButtonFunc: () => {
                setTwoButtonModalType("usingAuthenticator");
            },
            mainText: "Ativação do autenticador",
            subText:
                "Mantenha a opção 'Tipo de chave' em  'Baseada no horário' e depois clique em adicionar.",
        },
        usingAuthenticator: {
            imageSrc: google,
            whiteButtonText: "voltar",
            blueButtonText: "Próximo",
            whiteButtonFunc: () => {
                setTwoButtonModalType("activationBaseKey");
            },
            blueButtonFunc: () => {
                setTwoButtonModalIsOpen(false);
                setInputModalIsOpen(true);
            },
            mainText: "Utilizando o autenticador",
            subText:
                "Clique e segure em cima do código de seis dígitos presente no autenticador para copiar e cole na próxima tela.",
        },
    };

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }
    }, []);

    function sendWithdraw() {
        if (!validateInputs()) {
            return;
        }

        setWithdrawIsLoading(true);

        const url = `${returnBackUrl()}/balance/withdraw`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const postOrder = axios.post(
            url,
            { amount: formattedAmount * 100, userKey },
            config
        );

        postOrder
            .then((res) => {
                setRefreshBalance((prevState) => {
                    return !prevState;
                });
                setAmount("");
                setFormattedAmount();
                setUserKey("");
                setWithdrawIsLoading(false);
                setModalType("confirm");
                setOneButtonModalIsOpen(true);
            })
            .catch((err) => {
                if (err.response.data.includes("verificação")) {
                    setWithdrawIsLoading(false);
                    setModalType("require2FA");
                    setOneButtonModalIsOpen(true);
                } else {
                    setRefreshBalance((prevState) => {
                        return !prevState;
                    });
                    setWithdrawIsLoading(false);
                    setModalType("error");
                    setOneButtonModalIsOpen(true);
                }
            });
    }

    function validateInputs() {
        setAmountError("");
        setKeyError("");

        if (isNaN(formattedAmount)) {
            setAmountError("Por favor, envie um valor para sacar.");
            return false;
        }

        if (formattedAmount < 100) {
            setAmountError("O valor mínimo para saque é R$100.");
            return false;
        }

        if (!userKey) {
            setKeyError("Por favor, envie uma chave PIX");
            return false;
        }

        return true;
    }

    const handleMonetaryInput = (event) => {
        let valor = event.target.value;
        valor = valor.replace(/\D/g, ""); // Remove tudo que não for dígito
        valor = (parseInt(valor) / 100).toFixed(2); // Divide por 100 e fixa 2 casas decimais
        valor = valor.replace(".", ","); // Troca ponto por vírgula
        valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada 3 dígitos

        if (removeDecimalSeparator(valor) > 0) {
            setAmount(`${valor}`);
            setFormattedAmount(() => {
                return removeDecimalSeparator(valor) / 100;
            });
        } else {
            setAmount(0);
            setFormattedAmount(0);
        }
    }; //tks GPT4

    function removeDecimalSeparator(price) {
        const priceNoSymbol = price.replace(/[^0-9.,]+/g, "");
        const priceNoThousandsSeparator = priceNoSymbol.replace(/\./g, "");
        const priceNoDecimalSeparator = priceNoThousandsSeparator.replace(
            /,/g,
            ""
        );

        return priceNoDecimalSeparator;
    } //tks GPT4

    function activate2FA() {
        setOneButtonModalIsOpen(false);

        const url = `${returnBackUrl()}/2FA/activate`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const activate2FARequest = axios.get(url, config);

        setTwoButtonModalIsOpen(true);
        setTwoButtonModalType("googleAuth");
    }

    function validate2FA() {
        setAuthError(false);
        setAuthIsLoading(true);

        const url = `${returnBackUrl()}/2FA/validate`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const validate2FARequest = axios.post(
            url,
            { totp: userAuthCode },
            config
        );

        validate2FARequest
            .then(() => {
                setInputModalIsOpen(false);
                setAuthIsLoading(false);
                setOneButtonModalIsOpen(true);
                setModalType("authActivated");
            })
            .catch(() => {
                setAuthError(true);
                setAuthIsLoading(false);
            });
    }

    return (
        <Container>
            <div>
                <LogoAndMenuHeader
                    logo={logo}
                    color={"#ffffff"}
                    background={"#000000"}
                />
            </div>

            <WithdrawDiv>
                <SetValue>
                    <TextBox>Saque</TextBox>

                    <AvailableBalance />

                    <Input>
                        <TextBox>Valor (R$): </TextBox>
                        <DebounceInput
                            type="tel"
                            debounceTimeout={200}
                            value={amount}
                            onChange={handleMonetaryInput}
                            placeholder="Digite um valor para sacar"
                        />
                        <ErrorMessage text={amountError} />
                    </Input>

                    <Input>
                        <TextBox>Chave PIX: </TextBox>

                        <DebounceInput
                            debounceTimeout={200}
                            value={userKey}
                            onChange={(e) => {
                                setUserKey(e.target.value);
                            }}
                        />
                    </Input>

                    <div>
                        <h5>
                            Certifique-se de enviar a chave correta e
                            correspondente ao CPF cadastrado na plataforma, não
                            será possível alterar posteriormente
                        </h5>
                    </div>
                    <ErrorMessage text={keyError} />
                </SetValue>
            </WithdrawDiv>

            <TransferConfirmation
                sendOrderLoading={withdrawIsLoading}
                onClickFunc={sendWithdraw}
                text={"CONFIRMAR SAQUE"}
            />

            <OneButtonModal
                modalIsOpen={oneButtonModalIsOpen}
                setModalIsOpen={setOneButtonModalIsOpen}
                modalInfo={oneButtonModals[modalType]}
            />

            <TwoButtonModal
                modalIsOpen={twoButtonModalIsOpen}
                setModalIsOpen={setTwoButtonModalIsOpen}
                modalInfo={twoButtonModals[twoButtonModalType]}
            />

            <InputModal
                isLoading={authIsLoading}
                modalIsOpen={inputModalIsOpen}
                setModalIsOpen={setInputModalIsOpen}
                inputState={userAuthCode}
                setState={setUserAuthCode}
                errorMessage={authError ? "Código inválido." : ""}
                modalInfo={{
                    imageSrc: phone2fa,
                    whiteButtonText: "voltar",
                    blueButtonText: "Verificar",
                    whiteButtonFunc: () => {
                        setInputModalIsOpen(false);
                        setTwoButtonModalIsOpen(true);
                        setTwoButtonModalType("usingAuthenticator");
                    },
                    blueButtonFunc: () => {
                        validate2FA();
                    },
                    mainText: "Verificação em duas etapas",
                    subText: `Insira abaixo o código de verificação do app Google Authenticator.`,
                }}
            />
        </Container>
    );
}

const WithdrawDiv = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 160px;

    > h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;

        color: #000000;
    }
`;

const Input = styled.div`
    width: 100%;

    margin-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > input {
        width: 100%;
        height: 40px;

        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        padding-left: 10px;
        font-size: 16px;

        display: flex;
        align-items: center;

        margin-bottom: 10px;
    }

    > input:focus {
        outline: none;
    }
`;

const SetValue = styled.div`
    width: 100%;
    padding: 0 20px;

    > h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;

        color: #000000;
    }

    > div:last-child {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 10px;
        margin-bottom: 10px;
    }

    h5 {
        font-size: 14px;
        color: #808080;
        text-align: center;
        line-height: 18px;
    }
`;

const ErrorMessageDiv = styled.div`
    width: 100%;
    padding: 0 20px;
    margin-top: 55px;
`;
