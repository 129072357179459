import styled from "styled-components";
import { IoIosClose } from "react-icons/io";

export default function ImageInput({
    state,
    setState,
    text,
    subText,
    width,
    imageWidth,
    imageHeight,
    imageAmount,
}) {
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]; // We only consider the first selected file

        if (!selectedFile) return; // No file selected

        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
            URL.revokeObjectURL(img.src); // Release the object URL after the image is loaded
            const width = img.width;
            const height = img.height;

            if (width % imageWidth === 0 && height % imageHeight === 0) {
                if (Array.isArray(state) && state.length < imageAmount) {
                    setState([...state, selectedFile]);
                } else if (
                    Array.isArray(state) &&
                    state.length === imageAmount
                ) {
                    const newState = [...state];
                    newState.shift(); // remove the first element
                    newState.push(selectedFile); // add the new image to the end
                    setState(newState);
                } else {
                    alert(`Você só pode enviar ${imageAmount} imagens`);
                }
            } else {
                alert(
                    `A imagem deve ter o tamanho ${imageWidth}x${imageHeight}, ou proporcional.`
                );
            }
        };

        img.onerror = () => {
            alert("Imagem inválida.");
        };
    }; //tks GPT4

    function getImageHeight() {
        if (imageHeight % 200 === 0) {
            return 100;
        } else if (imageHeight % 480 === 0) {
            return 240;
        } else {
            return 0;
        }
    }

    function getImageWidth() {
        if (imageWidth % 440 === 0) {
            return 220;
        } else if (imageWidth % 340 === 0) {
            return 170;
        } else {
            return 0;
        }
    }

    return (
        <InputField width={width}>
            <h3>{text}</h3>
            <h4>{subText}</h4>
            <ImagesContainer>
                {Array.isArray(state) && state.length > 0 ? (
                    state.map((image, index) => {
                        return (
                            <ImageBox
                                key={index}
                                imageHeight={`${getImageHeight()}px`}
                                imageWidth={`${getImageWidth()}px`}
                            >
                                <img
                                    src={
                                        typeof image === "string"
                                            ? image
                                            : image instanceof File
                                            ? URL.createObjectURL(image)
                                            : ""
                                    }
                                    alt="cover"
                                />
                                <DeleteImage
                                    onClick={() => {
                                        const newState = [...state];
                                        newState.splice(index, 1);
                                        setState(newState);
                                    }}
                                >
                                    <IoIosClose />
                                </DeleteImage>
                            </ImageBox>
                        );
                    })
                ) : (
                    <></>
                )}
            </ImagesContainer>
            <InputLabel>
                <h1>Carregar imagem</h1>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={handleFileChange}
                />
            </InputLabel>
        </InputField>
    );
}

ImageInput.defaultProps = {
    width: "100%",
    imageAmount: 1,
};

const InputField = styled.div`
    width: ${(props) => props.width};

    > h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    > h4 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 20px;
        color: #a9a9a9;
    }
`;

const ImagesContainer = styled.div`
    width: 100%;

    display: flex;

    overflow-x: scroll;
`;

const ImageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
        width: ${(props) => props.imageWidth};
        height: ${(props) => props.imageHeight};
        border-radius: 11px;
        margin-bottom: 5px;
    }

    > svg {
        font-size: 25px;
        margin-bottom: 15px;
        color: #0069f4;

        cursor: pointer;
    }

    &:not(:first-child) {
        margin-left: 15px;
    }
`;

const DeleteImage = styled.div`
    width: 25%;
    height: 23px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-size: 26px;

    background-color: #0069f4;
    border-radius: 8px;

    margin-bottom: 15px;
    cursor: pointer;
`;

const InputLabel = styled.label`
    display: inline-block;
    width: 131px;
    height: 35px;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    background: #f1f0f5;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 11px;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    color: #000;

    > input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    > h1 {
        font-weight: 400;
        font-size: 11px;
    }
`;
