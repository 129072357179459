import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import styled from "styled-components";

import Container from "../../components/Container";
import Button from "../../components/Button";
import LogoHeader from "../../components/Headers/OnlyLogoHeader";

import SignInContext from "../../contexts/SignInContext";

export default function InitialScreen() {
    const navigate = useNavigate();
    const { isLogged } = useContext(SignInContext);

    useEffect(() => {
        if (isLogged) {
            navigate("/home");
        }
    }, []);

    return (
        <Container>
            <Interface>
                <LogoHeader />
                <InteractionBox>
                    <h3>
                        Na Angels além de comprar e vender Link Coins (LSBs),
                        você troca LSBs por experiências, produtos, cursos e
                        mentorias.
                    </h3>
                    <div>
                        <Button
                            background="#ffffff"
                            border="1px solid #000000"
                            text="Acessar sua conta"
                            fontColor="#000000"
                            onClickFunc={() => navigate("/sign-in")}
                        />
                        <Button
                            background="#0069F4"
                            border="1px solid #0069F4"
                            text="Criar sua conta"
                            fontColor="#ffffff"
                            onClickFunc={() => navigate("/sign-up")}
                        />
                    </div>
                </InteractionBox>
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 184px !important;
`;

const InteractionBox = styled.div`
    width: 100%;
    height: 325px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
        width: 100%;
        height: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    > h3 {
        font-weight: bold;
        font-size: 20px;
        line-height: 35px;
        text-align: center;
    }
`;
