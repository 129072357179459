import styled from "styled-components";

export default function PriceBox({ children, background, onClickFunc, order }) {
    return (
        <Box
            visibility={!children ? "hidden" : "visible"}
            background={background}
            color={background === "#0069F4" ? "#ffffff" : "#000000"}
            onClick={() => {
                if (order) {
                    onClickFunc(order);
                }
            }}
        >
            {children ? children.toFixed(2) : ""}
        </Box>
    );
}

const Box = styled.div`
    min-width: 65px;
    height: 80%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 15px;
    color: ${(props) => props.color};

    background-color: ${(props) => props.background};
    border-radius: 6px;
    cursor: pointer;
`;
