import { returnBackUrl } from "../../../utils/backUrl";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import axios from "axios";

import ArrowAndLogoHeader from "../../../components/Headers/ArrowAndLogoHeader";
import Container from "../../../components/Container";
import ProductTypeDropdown from "../../../components/Marketplace/Dashboard/ProductTypeDropdown";
import DateIntervalSelector from "../../../components/Marketplace/Dashboard/DateIntervalSelector";
import {
    NumberContainer,
    IntegerPart,
    DecimalSeparator,
    DecimalPart,
} from "../../../components/PriceFormatting";
import PendingSelector from "../../../components/Marketplace/Dashboard/PendingSelector";
import DashboardProductCard from "../../../components/Marketplace/Dashboard/DashboardProductCard";
import DeliverProductModal from "../../../components/Marketplace/Dashboard/DeliverProductModal";

import logo from "../../../assets/angels_imagens/logo_azul_angels.svg";
import lsbLogo from "../../../assets/angels_imagens/vender/Suas vendas/logo_branca_linkcoin.svg";
import growthArrow from "../../../assets/angels_imagens/vender/Suas vendas/setinha_branca.svg";
import { DebounceInput } from "react-debounce-input";

export default function Dashboard() {
    const navigate = useNavigate();
    const carouselRef = useRef(null);

    const [productType, setProductType] = useState("Vendas totais");
    const [startDate, setStartDate] = useState(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        date.setHours(23, 59, 59, 999);
        return date;
    });

    const [sellingsData, setSellingsData] = useState(null);
    const [filteredSellingsData, setFilteredSellingsData] = useState(null);
    const [lsbAmount, setLsbAmount] = useState(0);
    const [unitsSold, setUnitsSold] = useState(0);

    const [displayPending, setDisplayPending] = useState(true);
    const [searchFilter, setSearchFilter] = useState("");

    const [openedProduct, setOpenedProduct] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (!startDate) return;

        const start = new Date(startDate.setHours(0, 0, 0, 0)).toISOString();
        const end = endDate
            ? new Date(endDate.setHours(23, 59, 59, 99)).toISOString()
            : new Date(startDate.setHours(23, 59, 59, 99)).toISOString();
        const category = getProductType();

        const url = `${returnBackUrl()}/sellingsData?startDate=${start}&endDate=${end}&category=${category}`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.get(url, config);

        request
            .then((response) => {
                setLsbAmount(() => {
                    let totalSellings = 0;
                    if (response.data.length === 0) return 0;
                    response.data.forEach((selling) => {
                        totalSellings += selling.price;
                    });
                    return totalSellings;
                });
                setUnitsSold(response.data.length);
                setSellingsData([...response.data]);
            })
            .catch((error) => {});
    }, [refresh, productType, startDate, endDate]);

    useEffect(() => {
        if (!sellingsData || sellingsData.length === 0) {
            setFilteredSellingsData([]);
            return;
        }

        if (displayPending) {
            const pendingFilter = sellingsData.filter(
                (selling) => selling.deliverCode
            );
            const searchFilterApplied = pendingFilter.filter(
                (selling) =>
                    selling.productTitle
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase()) ||
                    selling.buyerName
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
            );
            setFilteredSellingsData([...searchFilterApplied]);
        } else {
            const pendingFilter = sellingsData.filter(
                (selling) => !selling.deliverCode
            );
            const searchFilterApplied = pendingFilter.filter(
                (selling) =>
                    selling.productTitle
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase()) ||
                    selling.buyerName
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
            );
            setFilteredSellingsData([...searchFilterApplied]);
        }
    }, [sellingsData, displayPending, searchFilter]);

    function getProductType() {
        if (productType === "Produtos") {
            return "product";
        } else if (productType === "Mentorias") {
            return "mentorship";
        } else if (productType === "Experiências") {
            return "experience";
        } else if (productType === "Cursos") {
            return "course";
        } else {
            return "total";
        }
    }

    function getBalance() {
        return FormattedNumber(lsbAmount);
    }

    function FormattedNumber(value) {
        const [integerPart, decimalPart] = value.toString().split(".");

        return (
            <NumberContainer>
                <IntegerPart>{integerPart}</IntegerPart>
                <DecimalSeparator>.</DecimalSeparator>
                <DecimalPart>{decimalPart || "00"}</DecimalPart>
            </NumberContainer>
        );
    } //tks GPT4

    const scroll = (direction) => {
        if (direction === "left") {
            carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
        } else {
            carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
        }
    }; //tks GPT4

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    logo={logo}
                    color={"#095ece"}
                    onClickFunc={() => {
                        navigate("/sell");
                    }}
                />
                <Filters>
                    <DateIntervalSelector
                        startDate={startDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                    />

                    <ProductTypeDropdown
                        setState={setProductType}
                        state={productType}
                    />
                </Filters>

                <BalanceContainer>
                    <h2>Valor líquido</h2>
                    <div>
                        <h3>Link coin</h3>
                        <ReactSVG
                            src={lsbLogo}
                            beforeInjection={(svg) => {
                                svg.setAttribute(
                                    "style",
                                    "width: 25px; height: 100%;"
                                );
                            }}
                        />
                    </div>
                    <h1>LSB {getBalance()}</h1>
                    <Divider />
                    <div>
                        <h3>Vendas</h3>
                        <ReactSVG
                            src={growthArrow}
                            beforeInjection={(svg) => {
                                svg.setAttribute("style", "width: 25px;");
                            }}
                        />
                    </div>
                    <h1>
                        {unitsSold} <div>unidade(s)</div>
                    </h1>
                </BalanceContainer>

                <PendingSelector
                    displayPending={displayPending}
                    setDisplayPending={setDisplayPending}
                />

                <SearchFilter>
                    <ItemsCount>
                        <h1>Itens: </h1>
                        <h2>
                            {filteredSellingsData
                                ? filteredSellingsData.length
                                : 0}
                        </h2>
                    </ItemsCount>

                    <InputContainer>
                        <IoIosSearch />
                        <DebounceInput
                            placeholder="Pesquisar"
                            value={searchFilter}
                            onChange={(e) => setSearchFilter(e.target.value)}
                            debounceTimeout={300}
                        />
                    </InputContainer>
                </SearchFilter>

                <SellingsList>
                    <div className="carousel-container">
                        <button
                            className="carousel-button carousel-button-left"
                            onClick={() => scroll("left")}
                        >
                            &lt;
                        </button>

                        <div className="carousel" ref={carouselRef}>
                            {filteredSellingsData ? (
                                filteredSellingsData.map((item, index) => (
                                    <DashboardProductCard
                                        product={item}
                                        key={index}
                                        setOpenedProduct={setOpenedProduct}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                        </div>

                        <button
                            className="carousel-button carousel-button-right"
                            onClick={() => scroll("right")}
                        >
                            &gt;
                        </button>
                    </div>
                </SellingsList>
            </Interface>

            <DeliverProductModal
                product={openedProduct}
                setProduct={setOpenedProduct}
                setRefresh={setRefresh}
            />
        </Container>
    );
}

const Interface = styled.div``;

const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BalanceContainer = styled.div`
    width: 100%;
    height: 220px;

    margin-top: 10px;
    padding: 20px;
    padding-top: 35px;

    background: #0069f4;
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 21px;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    h1 {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;

        color: #ffffff;

        > div {
            display: inline;

            font-weight: 600;
            font-size: 22px;
            line-height: 16px;

            color: #ffffff;

            margin-bottom: 12px;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;

        color: #ffffff;

        margin-bottom: 12px;
    }

    h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;

        color: #ffffff;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 0px;
    margin-top: 15px;
`;

const SearchFilter = styled.div`
    width: 100%;

    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const InputContainer = styled.div`
    width: 210px;
    height: 40px;

    padding-left: 10px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background-color: #0069f4;

    font-weight: 700;
    font-size: 15px;
    line-height: 18px;

    color: #ffffff;

    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 14px;

    > svg {
        width: 20px;
        transform: rotate(90deg);
        margin-right: 5px;
    }

    > input {
        width: calc(100% - 35px);

        background: #0069f4;
        border: none;

        color: #ffffff;
        font-size: 15px;
        font-weight: 700;
    }

    > input:focus {
        outline: none;
    }

    > input::placeholder {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;

        color: #ffffff;
    }
`;

const ItemsCount = styled.div`
    width: 125px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding-left: 20px;

    background: #0069f4;
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 14px;

    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: #ffffff;

    > h2 {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;

        color: #ffffff;

        margin-left: 5px;
    }
`;

const SellingsList = styled.div``;
