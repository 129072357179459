import { useContext, useEffect, useState } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import logo from "../../assets/angels_imagens/logo_preta_angels.svg";

import LogoAndMenuHeader from "../../components/Headers/LogoAndMenuHeader";
import Container from "../../components/Container";
import Button from "../../components/Button";

import BalanceContext from "../../contexts/BalanceContext";

export default function UserTokens() {
    const { lsbAmount, link3Amount } = useContext(BalanceContext);
    const navigate = useNavigate();

    const [lastLsbTransactionValue, setLastLsbTransactionValue] = useState(0);
    const [lastLink3TransactionValue, setLastLink3TransactionValue] =
        useState(0);

    useEffect(() => {
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const getLastLsbUrl = `${returnBackUrl()}/orders/market/last/LSB`;
        const getLastLsbRequest = axios.get(getLastLsbUrl, config);
        getLastLsbRequest.then((response) => {
            setLastLsbTransactionValue(response.data.unitPrice);
        });

        const getLastLink3Url = `${returnBackUrl()}/orders/market/last/LINK3`;
        const getLastLink3Request = axios.get(getLastLink3Url, config);
        getLastLink3Request.then((response) => {
            setLastLink3TransactionValue(response.data.unitPrice);
        });
    }, []);

    return (
        <Container>
            <Interface>
                <LogoAndMenuHeader
                    logo={logo}
                    color={"#ffffff"}
                    background={"#000000"}
                />

                <Title>
                    <h1>Seus ativos</h1>
                </Title>

                <Tokens>
                    <LineTitle>
                        <div>Nome</div>
                        <div>Quantidade</div>
                        <div>Valor total</div>
                    </LineTitle>
                    <Line>
                        <div>LSB</div>
                        <div>{lsbAmount}</div>
                        <div>
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(
                                (lsbAmount * lastLsbTransactionValue) / 100
                            )}
                        </div>
                    </Line>
                    {/* <Line backgroundColor={"#F6F6F6"}>
                        <div>LINK3</div>
                        <div>{link3Amount}</div>
                        <div>
                            {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(
                                (link3Amount * lastLink3TransactionValue) / 100
                            )}
                        </div>
                    </Line> */}
                </Tokens>

                <Buttons>
                    <Button
                        text="Voltar"
                        background={"#ffffff"}
                        border={"1px solid #0069F4"}
                        fontColor={"#000000"}
                        width="172px"
                        height="52px"
                        borderRadius="8px"
                        onClickFunc={() => navigate("/yourbuyings")}
                    ></Button>

                    <Button
                        text=" Ir para a corretora"
                        background={"#0069F4"}
                        border={"#0069F4"}
                        fontColor={"#ffffff"}
                        width="172px"
                        height="52px"
                        borderRadius="8px"
                        onClickFunc={() => navigate("/market")}
                    ></Button>
                </Buttons>
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;

    padding: 0 10px;
`;

const Title = styled.div`
    width: 100%;

    display: flex;
    justify-content: flex-start;

    font-weight: 300;
    font-size: 24px;
    line-height: 27px;

    color: #000000;

    > h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
        color: #000000;
    }
`;

const Tokens = styled.div`
    width: 100%;
    margin: 40px 0;

    > h5 {
        margin-top: 40px;

        color: red;
    }
`;

const LineTitle = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    > div {
        width: 25%;
        height: 25px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    > div:first-child {
        justify-content: flex-start;
    }
`;

const Line = styled.div`
    width: 100%;
    height: 40px;

    background-color: ${(props) => props.backgroundColor || "#ffffff"};

    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
        text-align: center;
    }

    > div {
        width: 25%;
        height: 25px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    > div:first-child {
        justify-content: flex-start;
    }

    > div:last-child {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background-color: #f1f0f5;
    }
`;

const Buttons = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;
