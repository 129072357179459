export const terms = (
    <>
        <h1>1. Termos de Uso</h1>
        <h2>1.1 - Aceitação dos Termos</h2>
        <p>
            Ao acessar e utilizar a plataforma Angels, você concorda em cumprir
            e estar vinculado a estes Termos de Uso. Se você não concordar com
            os termos, por favor, não utilize a plataforma.
        </p>
        <h2>1.2 - Alterações nos Termos</h2>
        <p>
            A Angels reserva-se o direito de modificar ou atualizar estes Termos
            de Uso a qualquer momento, sem aviso prévio. Ao continuar a utilizar
            a plataforma após qualquer alteração, você concorda em cumprir e
            estar vinculado aos Termos de Uso atualizados.
        </p>
        <h2>1.3 - Cadastro e Contas</h2>
        <p>
            Para utilizar a plataforma Angels, você deve criar uma conta e
            fornecer informações pessoais verdadeiras, precisas e atualizadas. A
            Angels reserva-se o direito de suspender ou encerrar a sua conta
            caso detecte informações incorretas ou incompletas.
        </p>
        <h2>1.4 - Senhas e Segurança</h2>
        <p>
            Você é responsável por manter a confidencialidade da sua senha e por
            todas as atividades que ocorram na sua conta. A Angels não será
            responsável por quaisquer perdas ou danos decorrentes do seu não
            cumprimento desta obrigação.
        </p>
        <h2> 1.5 - Conformidade com Leis e Regulamentações</h2>
        <p>
            Os usuários devem cumprir todas as leis e regulamentações aplicáveis
            em relação ao uso da plataforma Angels, incluindo, mas não se
            limitando a, leis fiscais, leis de valores mobiliários e leis
            anti-lavagem de dinheiro.
        </p>
        <h2>1.6 - Restrições de Uso</h2>
        <p>
            Você concorda em não utilizar a plataforma Angels para fins ilegais
            ou proibidos por estes Termos de Uso. Você não deve tentar obter
            acesso não autorizado a sistemas ou redes conectados à plataforma
            Angels ou violar quaisquer medidas de segurança.
        </p>
        <h2>1.7 - Tarifas e Comissões</h2>
        <p>
            Os usuários são responsáveis pelo pagamento de todas as tarifas e
            comissões associadas ao uso da plataforma Angels, conforme detalhado
            na Política de Tarifas e Comissões.
        </p>
        <h2>1.8 - Riscos e Limitação de Responsabilidade</h2>
        <p>
            A negociação de ativos financeiros envolve riscos e pode resultar na
            perda total do seu investimento. A Angels não se responsabiliza por
            quaisquer perdas ou danos que você possa sofrer como resultado do
            uso da plataforma.
        </p>
        <h2>1.9 - Indenização</h2>
        <p>
            Você concorda em indenizar e isentar a Angels e seus diretores,
            funcionários e parceiros de qualquer reclamação, dano, obrigação,
            perda, responsabilidade, custo ou despesa decorrentes ou
            relacionados ao seu uso da plataforma Angels em violação destes
            Termos de Uso.
        </p>
        <h2>1.10 - Rescisão</h2>
        <p>
            A Angels reserva-se o direito de suspender ou encerrar a sua conta e
            o seu acesso à plataforma a qualquer momento e por qualquer motivo,
            sem aviso prévio.
        </p>
        <h2>1.11 - Legislação Aplicável e Jurisdição</h2>
        <p>
            Estes Termos de Uso serão regidos e interpretados de acordo com as
            leis do Brasil. Quaisquer disputas decorrentes destes Termos de Uso
            serão resolvidas exclusivamente pelos tribunais competentes do
            Brasil.
        </p>
        <h2>1.12 - Disposições Gerais</h2>
        <p>
            Se qualquer disposição destes Termos de Uso for considerada inválida
            ou inexequível, as demais disposições permanecerão em pleno vigor e
            efeito. A falha da Angels em exercer ou executar qualquer direito ou
            disposição destes Termos de Uso não constituirá uma renúncia a tal
            direito ou disposição.
        </p>
        <h2> 1.13 Contato</h2>
        <p>
            Se você tiver dúvidas ou preocupações relacionadas a estes Termos de
            Uso, entre em contato com a Angels por meio dos canais de suporte
            fornecidos no site.
        </p>
        <h1>2. Política de Privacidade</h1>
        <p>
            A Angels respeita a sua privacidade e está comprometida em proteger
            os dados pessoais dos usuários da plataforma. Esta Política de
            Privacidade explica quais informações coletamos, como as utilizamos,
            e quais são seus direitos e responsabilidades em relação a essas
            informações.
        </p>
        <h2> 2.1 - Informações Coletadas</h2>
        <p>
            A Angels coleta informações pessoais quando você se registra e
            utiliza a plataforma. Essas informações incluem, mas não se limitam
            a, nome, endereço, data de nascimento, CPF, e-mail, número de
            telefone e informações financeiras.
        </p>
        <h2>2.2 - Uso das Informações</h2>
        <p>
            A Angels utiliza as informações coletadas para fins como: a.
            Fornecer e gerenciar os serviços da plataforma; b. Verificar a
            identidade dos usuários e cumprir com as obrigações legais e
            regulatórias; c. Personalizar a experiência do usuário e melhorar a
            plataforma; d. Comunicar-se com os usuários sobre atualizações,
            promoções e eventos; e. Prevenir e investigar atividades
            fraudulentas ou suspeitas.
        </p>
        <h2> 2.3 - Compartilhamento de Informações</h2>
        <p>
            A Angels poderá compartilhar informações pessoais com terceiros nas
            seguintes situações: a. Para cumprir com obrigações legais ou
            regulatórias, como atender a solicitações de autoridades
            governamentais; b. Para proteger os direitos, propriedades e
            segurança da Angels, seus usuários e terceiros; c. Com prestadores
            de serviços que auxiliem na operação da plataforma e na prestação de
            serviços, como processadores de pagamento e provedores de serviços
            de análise; d. No caso de fusão, aquisição ou venda de ativos da
            Angels.
        </p>
        <h2>2.4 - Segurança das Informações</h2>
        <p>
            A Angels adota medidas de segurança apropriadas para proteger os
            dados pessoais dos usuários contra acesso não autorizado,
            divulgação, alteração ou destruição. Isso inclui a utilização de
            tecnologias de criptografia e medidas de proteção física e
            administrativa.
        </p>
        <h2>2.5 - Retenção de Dados</h2>
        <p>
            A Angels retém os dados pessoais dos usuários pelo tempo necessário
            para cumprir com as finalidades descritas nesta Política de
            Privacidade, a menos que exigido ou permitido por lei.
        </p>
        <h2>2.6 - Direitos dos Usuários</h2>
        <p>
            De acordo com a Lei Geral de Proteção de Dados (LGPD), os usuários
            têm o direito de acessar, corrigir, excluir ou limitar o uso de seus
            dados pessoais. Para exercer esses direitos, entre em contato com a
            Angels através dos canais de suporte fornecidos no site.
        </p>
        <h2> 2.7 - Alterações nesta Política de Privacidade</h2>
        <p>
            A Angels reserva-se o direito de modificar esta Política de
            Privacidade a qualquer momento. As alterações entrarão em vigor
            imediatamente após a publicação no site. Ao continuar a utilizar a
            plataforma após a publicação das alterações, você concorda com a
            Política de Privacidade atualizada.
        </p>
        <h2>2.8 - Contato</h2>
        <p>
            Se você tiver dúvidas ou preocupações relacionadas a esta Política
            de Privacidade, entre em contato com a Angels por meio dos canais de
            suporte fornecidos no site.
        </p>
        <h1>3. Política de Tarifas e Comissões</h1>
        <p>
            A Angels cobra tarifas e comissões pelos serviços oferecidos na
            plataforma. Esta Política de Tarifas e Comissões descreve as taxas
            aplicáveis e como elas são calculadas.
        </p>
        <h2>3.1 - Taxas de Depósito e Saque</h2>
        <p>
            As taxas de depósito e saque são cobradas quando os usuários
            depositam ou retiram fundos da plataforma Angels. Estas taxas são
            calculadas com base na taxa de câmbio utilizada na conversão de
            moedas, incluindo uma taxa de 6,38% semelhante ao IOF de compra de
            dólar para cobrir os custos operacionais e de processamento da
            plataforma.
        </p>
        <h2>3.2 - Taxas de Negociação</h2>
        <p>
            A Angels cobra uma comissão de 5% sobre as operações de compra e
            venda realizadas na plataforma. Essas taxas de negociação ajudam a
            cobrir os custos associados à manutenção da plataforma, incluindo
            taxas da Polygon Blockchain, taxas de parceiros de gateway, taxas de
            APIs de conversão de moeda, servidores, aplicativos de segurança do
            Google e AWS, e outros custos operacionais. É importante lembrar que
            a Angels é uma das únicas plataformas do mundo que registram de fato
            as transações na blockchain no tempo real em que as transações
            ocorrem.
        </p>
        <h2>3.3 - Outras Taxas</h2>
        <p>
            A Angels pode cobrar taxas adicionais por outros serviços oferecidos
            na plataforma, como transferências entre usuários, empréstimos e
            outros serviços especiais.
        </p>
        <h2>3.4 - Alterações nas Tarifas e Comissões</h2>
        <p>
            A Angels reserva-se o direito de modificar as tarifas e comissões a
            qualquer momento e por qualquer motivo, com aviso prévio aos
            usuários. As alterações entrarão em vigor imediatamente após a
            publicação no site. Ao continuar a utilizar a plataforma após a
            publicação das alterações, você concorda com a Política de Tarifas e
            Comissões atualizada.
        </p>
        <h2>3.5 - Sobre a base de cálculo das taxas</h2>
        <p>
            A Angels exibe em seu front-end os valores em dólares. Isso é feito
            para facilitar a visualização do valor da Link Coins (LSB), uma vez
            que os saldos dos usuários são mantidos em real, ou seja, na prática
            não são convertidos para o dólar. Além disso, a Angels é uma
            plataforma feita para ter uso global (seu back-end e arquitetura de
            software foi inteiro projetado para funcionar em todo o mundo) e,
            portanto, já inicia com visualização em dólar para que todos os
            usuários já se acostumem com a maneira que a plataforma de fato irá
            funcionar em um futuro próximo.
        </p>
        <h2>3.6 - Contato</h2>
        <p>
            Se você tiver dúvidas ou preocupações relacionadas a esta Política
            de Tarifas e Comissões, entre em contato com a Angels por meio dos
            canais de suporte fornecidos no site.
        </p>
        <h1>4. Política de KYC/AML</h1>
        <p>
            A Angels está comprometida em cumprir as leis e regulamentações
            brasileiras relativas à prevenção da lavagem de dinheiro (AML) e ao
            combate ao financiamento do terrorismo (CFT). Esta Política de
            KYC/AML descreve as medidas adotadas pela plataforma para garantir a
            conformidade com tais regulamentações.
        </p>
        <h2>4.1 - Identificação e Verificação do Cliente (KYC)</h2>
        <p>
            A Angels segue uma política rigorosa de Conheça seu Cliente (KYC)
            para garantir a identificação e verificação adequadas dos usuários
            da plataforma. Os usuários são obrigados a fornecer informações
            pessoais, como nome, endereço, data de nascimento, CPF, e-mail e
            número de telefone. Além disso, os usuários devem fornecer cópias de
            documentos de identificação emitidos pelo governo, como RG, CNH ou
            passaporte, e comprovantes de residência.
        </p>
        <h2>4.2 - Monitoramento das Transações</h2>
        <p>
            A Angels monitora continuamente as transações realizadas na
            plataforma para identificar atividades suspeitas ou incomuns. Se uma
            transação for identificada como suspeita, a Angels tomará as medidas
            necessárias para investigar e, se necessário, reportar às
            autoridades competentes.
        </p>
        <h2>4.3 - Treinamento e Conscientização dos Funcionários</h2>
        <p>
            A Angels oferece treinamento regular aos seus funcionários para
            garantir que eles estejam atualizados sobre as leis e
            regulamentações de AML e CFT, bem como sobre as melhores práticas
            para a identificação e prevenção de atividades suspeitas.
        </p>
        <h2>4.4 - Cooperação com as Autoridades</h2>
        <p>
            A Angels coopera com as autoridades competentes no Brasil e em
            outros países, conforme aplicável, no combate à lavagem de dinheiro
            e ao financiamento do terrorismo. A plataforma reportará atividades
            suspeitas às autoridades conforme exigido pela lei.
        </p>
        <h2>4.5 - Revisão e Atualização da Política</h2>
        <p>
            A Angels revisará e atualizará periodicamente esta Política de
            KYC/AML para garantir sua conformidade contínua com as leis e
            regulamentações aplicáveis.
        </p>
        <h2>4.6 - Contato</h2>
        <p>
            Se você tiver dúvidas ou preocupações relacionadas a esta Política
            de KYC/AML, entre em contato com a Angels por meio dos canais de
            suporte fornecidos no site.
        </p>
        <h1>5. Política de Segurança</h1>
        <p>
            A Angels está comprometida em proteger a segurança das informações e
            dos ativos dos usuários em nossa plataforma. Esta Política de
            Segurança descreve as medidas de segurança adotadas pela Angels para
            garantir a proteção dos dados e recursos dos usuários.
        </p>
        <h2>5.1 - Autenticação de Dois Fatores (2FA)</h2>
        <p>
            A Angels implementa a autenticação de dois fatores (2FA) para todos
            os usuários que desejam efetuar saques na plataforma. Essa medida
            adicional de segurança ajuda a garantir que apenas o titular da
            conta possa realizar transações de saque.
        </p>
        <h2>5.2 - Verificação de Identidade para Saques</h2>
        <p>
            Antes de efetuar um saque, os usuários devem enviar à Angels toda a
            documentação necessária para verificar sua identidade. Isso inclui
            documentos de identificação emitidos pelo governo, como RG, CNH ou
            passaporte, e comprovante de residência. A Angels revisará e
            verificará a documentação fornecida antes de autorizar o saque.
        </p>
        <h2>5.3 - Restrições de Transferência</h2>
        <p>
            Os usuários só podem transferir fundos para uma conta bancária que
            esteja em seu nome e cuja identidade corresponda à identidade
            verificada na plataforma Angels. Essa medida ajuda a garantir a
            segurança dos recursos dos usuários e a prevenir atividades
            fraudulentas.
        </p>
        <h2>
            5.4 - Parcerias com Google e Amazon para Proteção contra Ataques
        </h2>
        <p>
            A Angels utiliza diversos serviços de segurança fornecidos pelo
            Google e pela Amazon para proteger a plataforma contra ataques
            cibernéticos e garantir a integridade dos dados e recursos dos
            usuários. Essas medidas incluem, entre outras, proteção contra DDoS,
            criptografia de dados e monitoramento contínuo de ameaças.
        </p>
        <h2>5.5 - Atualizações de Segurança</h2>
        <p>
            A Angels monitora e atualiza regularmente seus sistemas e políticas
            de segurança para garantir a proteção contínua dos dados e recursos
            dos usuários. A plataforma também trabalha com especialistas em
            segurança para identificar e corrigir possíveis vulnerabilidades.
        </p>
        <h2> 5.6 - Contato</h2>
        <p>
            Se você tiver dúvidas ou preocupações relacionadas a esta Política
            de Segurança, entre em contato com a Angels por meio dos canais de
            suporte fornecidos no site.
        </p>
        <h1>6. Política de Risco da Plataforma Angels</h1>
        <p>
            A Angels tem como objetivo informar e educar os usuários sobre os
            riscos associados à negociação na plataforma. Esta Política de Risco
            descreve os principais riscos envolvidos na negociação e aconselha
            os usuários a considerarem sua situação financeira e tolerância ao
            risco antes de realizar investimentos.
        </p>
        <h2>6.1 - Risco de Mercado</h2>
        <p>
            O mercado de ações e criptomoedas pode ser altamente volátil e
            sujeito a flutuações significativas de preço. Os usuários devem
            estar cientes de que o valor de seus investimentos pode aumentar ou
            diminuir rapidamente, e não há garantia de retornos ou preservação
            do capital investido.
        </p>
        <h2>6.2 - Risco de Liquidez</h2>
        <p>
            A liquidez de um ativo se refere à facilidade com que pode ser
            comprado ou vendido no mercado sem afetar seu preço. Em alguns
            casos, a liquidez de um ativo pode ser baixa, o que pode dificultar
            a compra ou venda do ativo e resultar em perdas financeiras para os
            usuários.
        </p>
        <h2> 6.3 - Risco Operacional</h2>
        <p>
            Os usuários estão sujeitos a riscos operacionais, como falhas de
            sistema, interrupções de serviço e problemas de conectividade, que
            podem afetar a capacidade de negociar na plataforma Angels. Embora a
            Angels tome medidas para minimizar esses riscos, não é possível
            garantir que tais eventos não ocorram.
        </p>
        <h2> 6.4 - Risco Legal e Regulatório</h2>
        <p>
            As leis e regulamentações relacionadas a ações e criptomoedas estão
            sujeitas a alterações e podem afetar o valor e a negociabilidade dos
            ativos na plataforma Angels. Os usuários devem estar cientes desses
            riscos e considerar o impacto potencial em seus investimentos.
        </p>
        <h2> 6.5 - Risco de Cibersegurança</h2>
        <p>
            Apesar das medidas de segurança implementadas pela Angels, os
            usuários podem estar sujeitos a riscos de cibersegurança, como
            ataques de hackers e roubo de informações. É importante que os
            usuários sigam as recomendações de segurança da Angels e protejam
            suas informações pessoais e de conta.
        </p>
        <h2>6.6 - Considerações Finais</h2>
        <p>
            Os usuários devem considerar cuidadosamente sua situação financeira,
            objetivos de investimento e tolerância ao risco antes de negociar na
            plataforma Angels. A negociação de ações e criptomoedas envolve
            riscos significativos e pode não ser adequada para todos os
            investidores.
        </p>
        <h2> 6.7 - Contato</h2>
        <p>
            Se você tiver dúvidas ou preocupações relacionadas a esta Política
            de Risco, entre em contato com a Angels por meio dos canais de
            suporte fornecidos no site.
        </p>
    </>
);
