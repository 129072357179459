import styled from "styled-components";
import { ReactSVG } from "react-svg";

import checked from "../../assets/angels_imagens/popups/senha_requisito_completo.svg";
import unchecked from "../../assets/angels_imagens/popups/senha_requisito_faltando.svg";

export default function PasswordRequirement({ password, regex, text }) {
    function getSvg() {
        if (regex.test(password)) {
            return checked;
        } else {
            return unchecked;
        }
    }

    return (
        <PasswordRequirementContainer>
            <ReactSVG src={getSvg()} />
            <h1>{text}</h1>
        </PasswordRequirementContainer>
    );
}

const PasswordRequirementContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
        width: 20px;
        height: 20px;
        margin-right: 3px;
    }

    > h1 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #a9a9a9;
        margin-left: 2px;
    }
`;
