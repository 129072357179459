import { useEffect, useState, useRef, useContext } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { IoIosPlay } from "react-icons/io";
import styled from "styled-components";
import axios from "axios";

import BenefitHeader from "../../components/Benefits/BenefitsHeader";
import Container from "../../components/Container";
import Button from "../../components/Button";

import SignInContext from "../../contexts/SignInContext";

export default function UserCourses() {
    const { isLogged } = useContext(SignInContext);
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);

    const [productsLoading, setProductsLoading] = useState(true);

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }

        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const urlMentorships = `${returnBackUrl()}/product/course/user/buyings`;
        const getObjects = axios.get(urlMentorships, config);

        getObjects
            .then((res) => {
                setProducts([...res.data]);
                setProductsLoading(false);
            })
            .catch((err) => {
                setProductsLoading(false);
            });
    }, []);

    function getUserName() {
        const { fullName } = JSON.parse(localStorage.getItem("userData"));

        let firstName = fullName.split(" ")[0];
        firstName =
            firstName[0].toUpperCase() + firstName.slice(1).toLowerCase();

        return firstName;
    }

    return (
        <Container>
            <Interface>
                <Header>
                    <BenefitHeader />
                </Header>
                <Courses>
                    {productsLoading ? (
                        <Bars color="#000" height={50} width={50} />
                    ) : (
                        <>
                            {products.length === 0 ? (
                                <FullPage>
                                    <p>Você ainda não comprou nenhum curso </p>
                                    <Buttons>
                                        <Button
                                            onClickFunc={() =>
                                                navigate("/yourbuyings")
                                            }
                                            background="#FFFFFF"
                                            border="1px solid #0069F4;"
                                            fontColor="#000000"
                                            width={"43%"}
                                            text={"Voltar"}
                                        />
                                        <Button
                                            onClickFunc={() =>
                                                navigate("/benefits")
                                            }
                                            background="#0069F4"
                                            border="1px solid #0069F4;"
                                            fontColor="#ffffff"
                                            width={"53%"}
                                            text={"Comprar agora"}
                                        />
                                    </Buttons>
                                </FullPage>
                            ) : (
                                <>
                                    <MainCourse>
                                        <h1>Olá, {getUserName()}</h1>
                                        <CourseCard
                                            width={"95%"}
                                            height={"85%"}
                                            onClick={() =>
                                                navigate(
                                                    `/yourbuyings/courses/${products[0].courseId}`
                                                )
                                            }
                                        >
                                            <img
                                                src={
                                                    products[0].course
                                                        .ProductCoursesImages[0]
                                                        .imageURL
                                                }
                                            />
                                            <WatchButton
                                                width={"141px"}
                                                height={"37px"}
                                                fontSize={"16px"}
                                            >
                                                <IoIosPlay />
                                                <p>Assistir</p>
                                            </WatchButton>
                                        </CourseCard>
                                    </MainCourse>
                                    <OtherCourses>
                                        <h1>Seus cursos</h1>
                                        <div>
                                            {products.map((product, index) => {
                                                if (index === 0) {
                                                    return null;
                                                }
                                                return (
                                                    <CourseCard
                                                        width={"126px"}
                                                        height={"192px"}
                                                        onClick={() =>
                                                            navigate(
                                                                `/yourbuyings/courses/${product.courseId}`
                                                            )
                                                        }
                                                        key={index}
                                                    >
                                                        <img
                                                            src={
                                                                product.course
                                                                    .ProductCoursesImages[0]
                                                                    .imageURL
                                                            }
                                                        />
                                                        <WatchButton
                                                            width={"70px"}
                                                            height={"20px"}
                                                            fontSize={"12px"}
                                                        >
                                                            <IoIosPlay />
                                                            <p>Assistir</p>
                                                        </WatchButton>
                                                    </CourseCard>
                                                );
                                            })}
                                        </div>
                                    </OtherCourses>
                                </>
                            )}
                        </>
                    )}
                </Courses>
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    padding: 0 !important;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.99) 74.48%,
        #000000 82.81%
    );
`;

const Header = styled.div`
    padding: 0 18px;
`;

const Courses = styled.div`
    width: 100%;
    height: calc(100vh - 112px);

    padding: 0 18px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > p {
        font-size: 20px;
        color: #000;

        margin-top: 50px;
    }
`;

const MainCourse = styled.div`
    width: 100%;
    height: 65%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    > h1 {
        font-weight: 700;
        font-size: 23px;

        color: #0069f4;

        width: 100%;
        text-align: left;
        padding-left: 10px;
    }
`;

const OtherCourses = styled.div`
    width: 100%;
    height: 35%;
    padding-left: 10px;

    > div {
        display: flex;

        overflow-x: scroll;
    }

    > h1 {
        font-weight: 700;
        font-size: 23px;
        line-height: 27px;

        color: #0069f4;
        margin-bottom: 10px;
    }
`;

const Buttons = styled.div`
    width: 100%;

    margin-top: 50px;

    display: flex;
    justify-content: space-between;
`;

const CourseCard = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};

    border-radius: 12px;

    position: relative;

    display: flex;
    justify-content: center;

    flex-shrink: 0;

    > img {
        width: 100%;
        height: 100%;
        border-radius: 12px;

        object-fit: cover;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }
`;

const FullPage = styled.div`
    width: 100%;
    height: calc(100vh - 112px);
    padding: 0 18px;
`;

const WatchButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${(props) => props.width};
    height: ${(props) => props.height};

    background: #ffffff;
    border-radius: 4px;

    position: absolute;
    bottom: 20px;

    cursor: pointer;

    svg {
        margin-right: 5px;

        font-size: ${(props) => props.fontSize};
    }

    p {
        font-weight: 700;
        font-size: ${(props) => props.fontSize};

        color: #000000;
        text-align: center;
    }
`;
