import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

export default function MenuItem({ icon, text, navigateTo }) {
    const navigate = useNavigate();

    return (
        <ItemContainer onClick={() => navigate(`${navigateTo}`)}>
            <ReactSVG
                src={icon}
                beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 35px;height:35px;");
                }}
            />
            <h5>{text}</h5>
        </ItemContainer>
    );
}

const ItemContainer = styled.div`
    width: 100%;
    height: 74px;

    padding-left: 30px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: #0069f4;
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 21px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.333333px;

    color: #ffffff;

    cursor: pointer;

    svg {
        margin-right: 80px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;
