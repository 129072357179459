import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function CreateAndEditSection({ productType, navigateTo }) {
    const navigate = useNavigate();

    return (
        <Container>
            <Box>
                Crie{" "}
                {productType === "mentoria" || productType === "experiência"
                    ? "sua"
                    : "seu"}{" "}
                {productType}
            </Box>
            <Box onClick={() => navigate(navigateTo)}>
                Edite{" "}
                {productType === "mentoria" || productType === "experiência"
                    ? "suas"
                    : "seus"}{" "}
                {productType + "s"}
            </Box>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 30px;

    > div:nth-child(1) {
        background-color: #0069f4;
        color: #ffffff;
    }

    > div:nth-child(2) {
        background-color: #ffffff;
        border: 1px solid #0069f4;
        color: #000000;
    }
`;

const Box = styled.div`
    width: 49%;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 11px;

    cursor: pointer;
`;
