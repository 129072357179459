import { useEffect, useState, useContext } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import BalanceContext from "../../contexts/BalanceContext";
import SignInContext from "../../contexts/SignInContext";

import Container from "../../components/Container";
import LastTransactionAndBalance from "../../components/OrderBook/LastTransactionAndBalance";
import CreateTransferControls from "../../components/OrderBook/CreateTransferControls";
import OrderBookHeader from "../../components/OrderBook/OrderBookHeader";
import SellingsList from "../../components/OrderBook/SellingsList";
import BuyingsList from "../../components/OrderBook/BuyingsList";
import SendOrder from "../../components/OrderBook/SendOrder";
import OneButtonModal from "../../components/Modals/OneButtonModal";
import TwoButtonModal from "../../components/Modals/TwoButtonModal";

import confirmedPurchase from "../../assets/angels_imagens/popups/processando_confirmada.svg";
import buy from "../../assets/angels_imagens/popups/confirmar_ordem.svg";
import { Bars } from "react-loader-spinner";
import purchaseError from "../../assets/angels_imagens/popups/comprar_erro.svg";

export default function OrderBook() {
    const { setRefreshBalance, link3Amount } = useContext(BalanceContext);
    const { isLogged } = useContext(SignInContext);
    const navigate = useNavigate();

    const [refreshOrders, setRefreshOrders] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(true);

    const [activeToken, setActiveToken] = useState("LSB");

    const [marketFee, setMarketFee] = useState(0);

    const [lsbSellings, setLsbSellings] = useState([]);
    const [lsbBuyings, setLsbBuyings] = useState([]);
    const [lsbBiggerArray, setLsbBiggerArray] = useState(null);

    const [link3Sellings, setLink3Sellings] = useState([]);
    const [link3Buyings, setLink3Buyings] = useState([]);
    const [link3BiggerArray, setLink3BiggerArray] = useState(null);

    const [activeButton, setActiveButton] = useState("BUY");

    const [amount, setAmount] = useState();
    const [amountErrorMessage, setAmountErrorMessage] = useState("");

    const [price, setPrice] = useState();
    const [priceErrorMessage, setPriceErrorMessage] = useState("");
    const [formattedPrice, setFormattedPrice] = useState(0);

    const [totalValue, setTotalValue] = useState();

    const [twoButtonModalIsOpen, setTwoButtonModalIsOpen] = useState(false);
    const [oneButtonModalIsOpen, setOneButtonModalIsOpen] = useState(false);
    const [oneButtonModalType, setOneButtonModalType] = useState("processing");
    const [confirmMessage, setConfirmMessage] = useState("");
    const [confirmSubMessage, setConfirmSubMessage] = useState("");
    const [errorSubMessage, setErrorSubMessage] = useState("");

    const oneButtonModals = {
        processing: {
            imageSrc: "Bars",
            mainText: "Processando na chain",
            subText:
                "A sua ordem está sendo processada de maneira segura diretamente na blockchain descentralizada da Polygon.",
            buttonText: "",
            buttonWidth: "0px",
            buttonBackground: "#ffffff",
            hideX: true,
            hideButton: true,
        },
        confirmed: {
            imageSrc: confirmedPurchase,
            mainText: confirmMessage,
            subText: confirmSubMessage,
            buttonText: "Voltar para a corretora",
            buttonWidth: "80%",
            buttonActionFunc: () => {
                setOneButtonModalIsOpen(false);
            },
        },
        noFunds: {
            imageSrc: purchaseError,
            mainText: "Saldo insuficiente",
            subText: errorSubMessage,
            buttonText: errorSubMessage.includes("LSB")
                ? "Voltar para a corretora"
                : "Ir para o depósito",
            buttonWidth: "80%",
            buttonActionFunc: errorSubMessage.includes("LSB")
                ? () => {
                      setOneButtonModalIsOpen(false);
                  }
                : () => {
                      navigate("/deposit");
                  },
        },
    };

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }

        setLoadingOrders(true);

        const url = `${returnBackUrl()}/orders`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const getOrderBook = axios.get(url, config);

        getOrderBook
            .then((res) => {
                setLsbSellings([...res.data.sellings.lsbSellings]);
                setLsbBuyings([...res.data.buyings.lsbBuyings]);
                setLink3Sellings([...res.data.sellings.link3Sellings]);
                setLink3Buyings([...res.data.buyings.link3Buyings]);
                setLoadingOrders(false);
                setMarketFee(res.data.marketFee);

                if (
                    res.data.sellings.lsbSellings.length >
                    res.data.buyings.lsbBuyings.length
                ) {
                    setLsbBiggerArray([...res.data.sellings.lsbSellings]);
                } else {
                    setLsbBiggerArray([...res.data.buyings.lsbBuyings]);
                }

                if (
                    res.data.sellings.link3Sellings.length >
                    res.data.buyings.link3Buyings.length
                ) {
                    setLink3BiggerArray([...res.data.sellings.link3Sellings]);
                } else {
                    setLink3BiggerArray([...res.data.buyings.link3Buyings]);
                }
            })
            .catch();
    }, [refreshOrders]);

    function checkOrderInputs() {
        setAmountErrorMessage("");
        setPriceErrorMessage("");
        setOneButtonModalIsOpen(false);

        if (amount <= 0 || isNaN(amount)) {
            setAmountErrorMessage("Informe um valor maior do que 0.");

            return false;
        }
        if (formattedPrice <= 0 || isNaN(formattedPrice)) {
            setPriceErrorMessage("Informe um valor maior do que 0. ");

            return false;
        }

        return true;
    }

    function sendOrder() {
        setTwoButtonModalIsOpen(false);
        setOneButtonModalIsOpen(true);
        setOneButtonModalType("processing");

        let url = `${returnBackUrl()}/orders`;
        if (activeButton === "BUY") {
            url = url + "/buy";
        } else {
            url = url + "/sell";
        }

        const { token } = JSON.parse(localStorage.getItem("userData"));
        const body = {
            initialAmount: amount,
            unitPrice: (formattedPrice * 100).toFixed(0), //send price in cents
            type: activeButton,
            tokenSymbol: activeToken,
        };

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const postOrder = axios.post(url, body, config);

        postOrder
            .then((res) => {
                setRefreshOrders((prevState) => !prevState);
                setRefreshBalance((prevState) => !prevState);
                setOneButtonModalType("confirmed");
                setConfirmMessage(() => getConfirmMessage(res.data));
                setConfirmSubMessage(() => getConfirmSubMessage(res.data));
            })
            .catch((err) => {
                console.log(err.response);
                setRefreshBalance((prevState) => !prevState);
                setErrorSubMessage(err.response.data);
                setOneButtonModalType("noFunds");
            });
    }

    function selectOrder(order) {
        if (order.type === "BUY") {
            setActiveButton("SELL");
        } else {
            setActiveButton("BUY");
        }

        setAmount(Number(order.availableAmount));
        setPrice((order.unitPrice / 100).toString().replace(".", ","));
        setTotalValue(Number(order.availableAmount * (order.unitPrice / 100)));
        setFormattedPrice(Number((order.unitPrice / 100).toFixed(2)));
        setActiveToken(order.tokenSymbol);
    }

    function getConfirmMessage(transfers) {
        if (transfers.length === 0 || typeof transfers === "string") {
            return "Ordem registrada";
        }

        let cont = 0;
        transfers.map((transfer) => (cont += transfer.availableAmount));

        if (Number(cont.toFixed(2)) === amount) {
            return "Ordem totalmente executada";
        } else {
            return "Ordem parcialmente executada";
        }
    }

    function getConfirmSubMessage(transfers) {
        if (typeof transfers === "string") {
            return transfers;
        }

        if (transfers.length === 0) {
            return `Sua ordem foi registrada no ordebook e será executada quando encontrar ${
                activeButton === "BUY" ? "vendedor" : "comprador"
            }.`;
        }

        let tokenAmount = 0;
        let realAmount = 0;
        transfers.map((transfer) => {
            tokenAmount += transfer.availableAmount;
            realAmount += (transfer.availableAmount * transfer.unitPrice) / 100;

            realAmount = Number(realAmount.toFixed(2));
        });

        let message = `Você ${
            activeButton === "BUY" ? "comprou" : "vendeu"
        } ${tokenAmount} ${activeToken}'s por ${new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(realAmount)}.`;
        if (Number(tokenAmount.toFixed(2)) !== amount) {
            message += "O restante da sua ordem está registrado no orderbook.";
        }

        return message;
    }

    function getActiveBiggerArray() {
        return activeToken === "LSB" ? lsbBiggerArray : link3BiggerArray;
    }

    function getConfirmBuySubText() {
        let message =
            activeButton === "BUY"
                ? `Deseja confirmar a compra de ${amount} ${activeToken}'s por ${new Intl.NumberFormat(
                      "pt-BR",
                      {
                          style: "currency",
                          currency: "BRL",
                      }
                  ).format(formattedPrice)} cada?`
                : `Deseja confirmar a venda de ${amount} ${activeToken}'s por ${new Intl.NumberFormat(
                      "pt-BR",
                      {
                          style: "currency",
                          currency: "BRL",
                      }
                  ).format(formattedPrice)} cada?`;

        if (activeToken === "LINK3") {
            if (link3Amount > 0) {
                return (
                    message +
                    `                  Sua ordem buscará ordens de outros sócios ou ordens de não sócios ativas há mais de 48h`
                );
            } else {
                return (
                    message +
                    `                  Sua ordem buscará apenas ordens ativas há mais de 48h`
                );
            }
        }

        return message;
    }

    return (
        <Container>
            <Interface>
                <OrderBookHeader />
                <LastTransactionAndBalance activeToken={activeToken} />

                {/* <SelectWrapper>
                    <SelectToken
                        value={activeToken}
                        onChange={(event) => {
                            setActiveToken(event.target.value);
                        }}
                    >
                        <option value="LINK3">LINK3</option>
                        <option value="LSB">LSB</option>
                    </SelectToken>
                </SelectWrapper> */}

                {loadingOrders || !getActiveBiggerArray() ? (
                    <OrderBookContainer alignItens="center">
                        <Bars height={"80px"} color="#000000" />
                    </OrderBookContainer>
                ) : (
                    <OrderBookContainer alignItens="flex-start">
                        <BuyingsList
                            biggerArray={
                                activeToken === "LSB"
                                    ? lsbBiggerArray
                                    : link3BiggerArray
                            }
                            buyings={
                                activeToken === "LSB"
                                    ? lsbBuyings
                                    : link3Buyings
                            }
                            selectOrder={selectOrder}
                        />
                        <SellingsList
                            biggerArray={
                                activeToken === "LSB"
                                    ? lsbBiggerArray
                                    : link3BiggerArray
                            }
                            sellings={
                                activeToken === "LSB"
                                    ? lsbSellings
                                    : link3Sellings
                            }
                            selectOrder={selectOrder}
                        />
                    </OrderBookContainer>
                )}

                <CreateTransferControls
                    setActiveButton={setActiveButton}
                    activeButton={activeButton}
                    amount={amount}
                    setAmount={setAmount}
                    price={price}
                    setPrice={setPrice}
                    setRefreshOrders={setRefreshOrders}
                    totalValue={totalValue}
                    setTotalValue={setTotalValue}
                    priceErrorMessage={priceErrorMessage}
                    amountErrorMessage={amountErrorMessage}
                    formattedPrice={formattedPrice}
                    setFormattedPrice={setFormattedPrice}
                    marketFee={marketFee}
                />

                <SendOrder
                    text={
                        activeButton === "BUY"
                            ? "CONFIRMAR POSIÇÃO DE COMPRA"
                            : "CONFIRMAR POSIÇÃO DE VENDA"
                    }
                    onClickFunc={() => {
                        if (checkOrderInputs()) {
                            setTwoButtonModalIsOpen(true);
                        }
                    }}
                />
            </Interface>

            <OneButtonModal
                modalIsOpen={oneButtonModalIsOpen}
                setModalIsOpen={setOneButtonModalIsOpen}
                modalInfo={oneButtonModals[oneButtonModalType]}
            />

            <TwoButtonModal
                modalIsOpen={twoButtonModalIsOpen}
                setModalIsOpen={setTwoButtonModalIsOpen}
                buttonActionFunc
                modalInfo={{
                    imageSrc: buy,
                    whiteButtonText: "cancelar",
                    blueButtonText: "Confirmar",
                    whiteButtonFunc: () => {
                        setTwoButtonModalIsOpen(false);
                    },
                    blueButtonFunc: sendOrder,
                    mainText:
                        activeButton === "BUY"
                            ? "Confirmar compra"
                            : "Confirmar venda",
                    subText: getConfirmBuySubText(),
                }}
            />
        </Container>
    );
}

const Interface = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const OrderBookContainer = styled.div`
    width: 100%;
    height: 240px;

    display: flex;
    justify-content: center;
    align-items: ${(props) => props.alignItens};
    justify-content: center;

    overflow-y: scroll;
    z-index: 2;
`;

const SelectWrapper = styled.div`
    width: 95%;
    height: 40px;

    margin: 10px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 30px 0 10px; // This can help move the arrow to the left
    overflow: hidden;
    border: 1px solid #0069f4;
    border-radius: 12px;
`; //tksGPT4

const SelectToken = styled.select`
    width: 100%;

    padding-left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffffff;
    border-radius: 12px;
    border: none;

    font-size: 14px;

    background: transparent;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    option {
        font-size: 14px;

        background: #ffffff;
        color: #000000;

        cursor: pointer;
    }
`;
