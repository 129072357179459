import { useContext, useEffect, useState, useRef } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import axios from "axios";

import lsbLogo from "../../assets/angels_imagens/logo_azul_lsb.svg";
import shoppingCart from "../../assets/angels_imagens/menu/comprar_produtos.svg";
import bull from "../../assets/angels_imagens/menu/ir_para_corretora.svg";
import hat from "../../assets/angels_imagens/menu/ver_posicoes.svg";
import pig from "../../assets/angels_imagens/menu/depositar.svg";
import withdraw from "../../assets/angels_imagens/menu/sacar.svg";
import exportData from "../../assets/angels_imagens/menu/extratos.svg";
import diamond from "../../assets/angels_imagens/menu/aquisicoes.svg";
import sell from "../../assets/angels_imagens/menu/vender.svg";

import { returnBackUrl } from "../../utils/backUrl";
import BalanceContext from "../../contexts/BalanceContext";
import SignInContext from "../../contexts/SignInContext";

import Container from "../../components/Container";
import HomeHeader from "../../components/Home/HomeHeader";
import HomeCard from "../../components/Home/HomeCard";
import {
    NumberContainer,
    IntegerPart,
    DecimalSeparator,
    DecimalPart,
} from "../../components/PriceFormatting";

export default function Home() {
    const { isLogged } = useContext(SignInContext);
    const { realAmount, lsbAmount, balanceIsLoading, link3Amount } =
        useContext(BalanceContext);
    const navigate = useNavigate();
    const carouselRef = useRef(null);

    const [firstName, setFirstName] = useState("");

    const carouselCards = [
        {
            text: "Comprar produtos",
            icon: shoppingCart,
            navigateTo: "/benefits",
        },
        { text: "Ir para a corretora", icon: bull, navigateTo: "/market" },
        { text: "Ver suas posições", icon: hat, navigateTo: "/user/orders" },
        { text: "Aquisições", icon: diamond, navigateTo: "/yourbuyings" },
        // {
        //     text: "Seus tokens",
        //     icon: diamond,
        //     navigateTo: "/yourbuyings/tokens",
        // },
        { text: "Depositar", icon: pig, navigateTo: "/deposit" },
        { text: "Extratos", icon: exportData, navigateTo: "/profile" },
        { text: "Vender", icon: sell, navigateTo: "/sell" },
        { text: "Sacar", icon: withdraw, navigateTo: "/withdraw" },
    ];

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }

        setFirstName(() => {
            return getFirstName();
        });
    }, []);

    function getFirstName() {
        if (!isLogged) {
            return "";
        }

        const { fullName } = JSON.parse(localStorage.getItem("userData"));
        let primeiroNome = fullName.split(" ")[0];
        primeiroNome =
            primeiroNome.charAt(0).toUpperCase() +
            primeiroNome.slice(1).toLowerCase();

        return primeiroNome;
    } //tks GPT4

    function getBalanceOrLoading(currency) {
        if (balanceIsLoading) {
            return <Bars height="35px" color="#ffffff" />;
        }

        if (currency === "rs") {
            return parseFloat(realAmount).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        } else {
            return FormattedNumber(lsbAmount);
        }
    }

    function FormattedNumber(value) {
        const [integerPart, decimalPart] = value.toString().split(".");

        return (
            <NumberContainer>
                <IntegerPart>{integerPart}</IntegerPart>
                <DecimalSeparator>.</DecimalSeparator>
                <DecimalPart>{decimalPart || "00"}</DecimalPart>
            </NumberContainer>
        );
    } //tks GPT4

    const scroll = (direction) => {
        if (direction === "left") {
            carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
        } else {
            carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
        }
    }; //tks GPT4

    return (
        <Container>
            <Interface>
                <HomeHeader />

                <HomeSection height="15%">
                    <HomeCard
                        width="100%"
                        height="120px"
                        justifyContent="flex-end"
                    >
                        <h2>{firstName}</h2>
                    </HomeCard>
                </HomeSection>

                <HomeSection>
                    <HomeCard
                        width="100%;"
                        height="216px"
                        justifyContent="space-between"
                    >
                        <BalanceTitle svgFontSize="25px">
                            <h3>Saldo disponível</h3>
                            <IoWalletOutline />
                        </BalanceTitle>

                        <h4>Reais </h4>
                        <h1>{getBalanceOrLoading("rs")}</h1>

                        <BalanceTitle>
                            <h3>LSB</h3>
                            <ReactSVG
                                src={lsbLogo}
                                beforeInjection={(svg) => {
                                    svg.setAttribute("style", "width: 30px;");
                                }}
                            />
                        </BalanceTitle>
                        <h1>LSB {getBalanceOrLoading("lsb")}</h1>
                    </HomeCard>
                </HomeSection>

                <HomeSection>
                    <h5>O que você deseja?</h5>
                </HomeSection>

                <HomeSection>
                    <div className="carousel-container">
                        <button
                            className="carousel-button carousel-button-left"
                            onClick={() => scroll("left")}
                        >
                            &lt;
                        </button>

                        <div className="carousel" ref={carouselRef}>
                            {carouselCards.map((item, index) => (
                                <div key={index}>
                                    <HomeCard
                                        key={index}
                                        width="100px"
                                        height="127px"
                                        justifyContent="space-between"
                                        marginRight={
                                            index === carouselCards.length - 1
                                                ? "0"
                                                : "10px"
                                        }
                                        onClickFunc={() =>
                                            navigate(`${item.navigateTo}`)
                                        }
                                    >
                                        <ReactSVG
                                            src={item.icon}
                                            beforeInjection={(svg) => {
                                                svg.setAttribute(
                                                    "style",
                                                    "width: 25px;height:25px;"
                                                );
                                            }}
                                        />
                                        <h4>{item.text}</h4>
                                    </HomeCard>
                                </div>
                            ))}
                        </div>

                        <button
                            className="carousel-button carousel-button-right"
                            onClick={() => scroll("right")}
                        >
                            &gt;
                        </button>
                    </div>
                </HomeSection>
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;

    background-color: #095ece;
`;

const HomeSection = styled.div`
    width: 100%;
    height: ${(props) => props.height};

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    h1 {
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
    }

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
    }

    h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }

    h4 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
    }

    h5 {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;

        margin-left: 20px;
        color: #ffffff;
    }
`;

const BalanceTitle = styled.div`
    width: 100%;
    height: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        font-size: ${(props) => props.svgFontSize};
    }
`;
