import styled from "styled-components";

import Line from "./OrderLine";
import PriceBox from "./PriceBox";

export default function SellingsList({ sellings, selectOrder, biggerArray }) {
    const GRAY = "#F1F0F5";
    const JUSTIFY_CONTENT = "flex-start";

    function renderEmptyLines() {
        const linesArray = [];

        biggerArray.map((item, index) => {
            if (sellings[index]) {
                return;
            } else {
                linesArray.push(
                    <Line
                        key={index}
                        index={index}
                        justifyContent={JUSTIFY_CONTENT}
                    ></Line>
                );
            }
        });

        return linesArray;
    }

    return (
        <SellingsListContainer>
            <Line index={0} justifyContent={GRAY}>
                <h5>Venda</h5>
                <h5>Qtd</h5>
            </Line>
            {sellings.length > 0 ? (
                sellings.map((selling, index) => {
                    return (
                        <Line
                            key={index}
                            index={index}
                            justifyContent={JUSTIFY_CONTENT}
                        >
                            <PriceBox
                                order={selling}
                                background={GRAY}
                                onClickFunc={selectOrder}
                            >
                                {selling.unitPrice / 100}
                            </PriceBox>
                            <h5>{selling.availableAmount}</h5>
                        </Line>
                    );
                })
            ) : (
                <></>
            )}

            {biggerArray.length > sellings.length ? (
                renderEmptyLines().map((item) => item)
            ) : (
                <></>
            )}
        </SellingsListContainer>
    );
}

const SellingsListContainer = styled.div`
    width: 50%;
    height: 100%;
`;
