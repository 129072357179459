import styled from "styled-components";
import { useState } from "react";

import Input from "../Input";
import { InputContainer } from "./InputContainer";
import ScrollModal from "../Modals/ScrollModal";
import { terms } from "../../assets/TermsAndPolicies.js";

export default function ConfirmPasswordStep({ state, setState }) {
    const stepData = {
        type: "password",
        text: "Confirme a sua senha:",
        subText: "Ela precisa ser idêntica a senha que você criou.",
        state,
        setState,
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <InputContainer>
            <Input
                text={stepData.text}
                type={stepData.type}
                state={stepData.state}
                setState={stepData.setState}
                subText={stepData.subText}
            />

            <Warning>
                Clicando no botão abaixo você concorda com todas as{" "}
                <span onClick={() => setModalIsOpen(true)}>
                    políticas da Angels.
                </span>
            </Warning>

            <ScrollModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                modalInfo={{
                    title: "Termos e Políticas",
                    subtitle: "Data da última atualização: 08/04/2023",
                }}
                scrollableText={terms}
            />
        </InputContainer>
    );
}

const Warning = styled.h1`
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;

    color: #a9a9a9;

    margin-top: 30px;

    > span {
        font-weight: 700;
        cursor: pointer;
    }
`;
