import styled from "styled-components";

export default function HomeSection({
    children,
    width,
    height,
    justifyContent,
    marginRight,
    onClickFunc,
}) {
    return (
        <SectionContainer
            width={width}
            height={height}
            justifyContent={justifyContent}
            marginRight={marginRight}
            onClick={onClickFunc}
        >
            {children}
        </SectionContainer>
    );
}

const SectionContainer = styled.div`
    width: ${(props) => props.width} !important;
    height: ${(props) => props.height} !important;

    padding: 20px;
    margin-right: ${(props) => props.marginRight};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${(props) => props.justifyContent};

    background: #0069f4;
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 21px;

    color: #ffffff;
    cursor: pointer;
`;
