import { useEffect, useState, useRef, useContext } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import axios from "axios";

import BenefitsHomeCard from "../../components/Benefits/BenefitsHomeCard";
import BenefitHeader from "../../components/Benefits/BenefitsHeader";
import Container from "../../components/Container";
import Button from "../../components/Button";

import SignInContext from "../../contexts/SignInContext";

export default function UserMentorships() {
    const carouselRef = useRef(null);
    const { isLogged } = useContext(SignInContext);
    const navigate = useNavigate();
    const defaultScrollAmount = 400;

    const [products, setProducts] = useState([]);

    const [productsLoading, setProductsLoading] = useState(true);
    const [productOnFocus, setProductOnFocus] = useState(0);

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }

        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const urlMentorships = `${returnBackUrl()}/product/mentorship/user/buyings`;
        const getObjects = axios.get(urlMentorships, config);

        getObjects
            .then((res) => {
                setProducts([...res.data]);
                setProductsLoading(false);
            })
            .catch((err) => {
                setProductsLoading(false);
            });
    }, []);

    const scroll = (direction) => {
        if (direction === "left") {
            carouselRef.current.scrollBy({
                left: -defaultScrollAmount,
                behavior: "smooth",
            });
            setProductOnFocus((prevState) => {
                if (prevState > 0) {
                    return prevState - 1;
                } else {
                    return prevState;
                }
            });
        } else {
            carouselRef.current.scrollBy({
                left: defaultScrollAmount,
                behavior: "smooth",
            });
            setProductOnFocus((prevState) => {
                if (prevState !== products.length - 1) {
                    return prevState + 1;
                } else {
                    return prevState;
                }
            });
        }
    }; //tks GPT4

    function getButtonHiddenClass(direction) {
        if (products.length === 0) return "hidden";

        if (direction === "left" && productOnFocus === 0) {
            return "hidden";
        }

        if (direction === "right" && productOnFocus === products.length - 1) {
            return "hidden";
        }

        return "";
    }

    return (
        <Container>
            <Interface>
                <BenefitHeader />

                {productsLoading ? (
                    <Bars height="50px" color="#000000" />
                ) : (
                    <>
                        <ProductsTitle>
                            <div>Produtos</div>
                        </ProductsTitle>

                        <BenefitsContainer>
                            <div className="carousel-container">
                                <button
                                    className={`carousel-button carousel-button-left black-button ${getButtonHiddenClass(
                                        "left"
                                    )}`}
                                    onClick={() => scroll("left")}
                                >
                                    &lt;
                                </button>

                                <div className="carousel" ref={carouselRef}>
                                    {products.length > 0 ? (
                                        products.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <BenefitsHomeCard
                                                        marginRight={
                                                            index ===
                                                            products.length - 1
                                                                ? "0"
                                                                : "10px"
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                item.mentorship
                                                                    .coverImageURL
                                                            }
                                                        />
                                                    </BenefitsHomeCard>
                                                    <DeliverCodeContainer>
                                                        <p>
                                                            Código de retirada:{" "}
                                                            {item.deliverCode
                                                                ? item.deliverCode
                                                                : "Já retirado"}
                                                        </p>
                                                    </DeliverCodeContainer>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <>Nenhum produto encontrado</>
                                    )}
                                </div>

                                <button
                                    className={`carousel-button carousel-button-right black-button ${getButtonHiddenClass(
                                        "right"
                                    )}`}
                                    onClick={() => scroll("right")}
                                >
                                    &gt;
                                </button>
                            </div>
                        </BenefitsContainer>

                        <Buttons>
                            <Button
                                onClickFunc={() => navigate("/yourbuyings")}
                                background="#FFFFFF"
                                border="1px solid #0069F4;"
                                fontColor="#000000"
                                width={"48%"}
                                text={"Voltar"}
                            />
                            <Button
                                onClickFunc={() => navigate("/benefits")}
                                background="#0069F4"
                                border="1px solid #0069F4;"
                                fontColor="#ffffff"
                                width={"48%"}
                                text={"Comprar produtos"}
                            />
                        </Buttons>
                    </>
                )}
            </Interface>
        </Container>
    );
}

const Interface = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BenefitsContainer = styled.div`
    width: 340px;
`;

const ProductsTitle = styled.div`
    width: 340px;

    display: flex;
    justify-content: flex-start;

    > div {
        width: 152px;
        height: 39px;

        margin: 40px 0 20px 0;
        padding-left: 30px;

        background: #f1f0f5;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
            0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
            0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
            0px 0px 0.92228px rgba(0, 0, 0, 0.04);
        border-radius: 11px;

        display: flex;
        align-items: center;

        font-weight: 600;
        font-size: 19px;
        line-height: 25px;

        color: #000000;
    }
`;

const DeliverCodeContainer = styled.div`
    width: 100%;
    height: 20px;

    margin-top: 10px;
    padding-right: 12px;

    display: flex;
    justify-content: flex-end;

    p {
        text-align: left;
    }
`;

const Buttons = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
`;
