import { RotatingLines } from "react-loader-spinner";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { returnBackUrl } from "../../utils/backUrl";
import axios from "axios";

import BalanceContext from "../../contexts/BalanceContext";
import SignInContext from "../../contexts/SignInContext";

export default function LastTransactionAndBalance({ activeToken }) {
    const { realAmount, lsbAmount, link3Amount, balanceIsLoading } =
        useContext(BalanceContext);
    const location = useLocation();
    const { isLogged } = useContext(SignInContext);
    const navigate = useNavigate();

    const [lastTransaction, setLastTransaction] = useState(0);

    useEffect(() => {
        const url = `${returnBackUrl()}/orders/market/last/${activeToken}`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const getLast = axios.get(url, config);
        getLast.then((response) => {
            setLastTransaction(response.data);
        });
    }, [activeToken]);

    return (
        <NavbarContainer>
            <IoIosArrowBack onClick={() => navigate("/home")} />

            <BalanceSection>
                <Section
                    visibility={
                        location.pathname === "/market" ? "visible" : "hidden"
                    }
                >
                    <p>Último R$</p>
                    {balanceIsLoading ? (
                        <RotatingLines width="20px" strokeColor="#000000" />
                    ) : (
                        <h5>
                            {lastTransaction && lastTransaction.unitPrice
                                ? (lastTransaction.unitPrice / 100).toFixed(2)
                                : "--"}
                        </h5>
                    )}
                </Section>

                <Section visibility={"visible"}>
                    <p>Saldo {activeToken}</p>
                    {balanceIsLoading ? (
                        <RotatingLines width="20px" strokeColor="#000000" />
                    ) : (
                        <h5>
                            {isLogged
                                ? activeToken === "LSB"
                                    ? lsbAmount
                                    : link3Amount
                                : "--"}
                        </h5>
                    )}
                </Section>

                <Section visibility={"visible"}>
                    <p>Saldo R$</p>
                    {balanceIsLoading ? (
                        <RotatingLines width="20px" strokeColor="#000000" />
                    ) : (
                        <h5>
                            {isLogged ? Number(realAmount).toFixed(2) : "--"}
                        </h5>
                    )}
                </Section>
            </BalanceSection>
        </NavbarContainer>
    );
}

const NavbarContainer = styled.div`
    width: 100%;
    height: 45px;

    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > svg {
        font-size: 35px;
        color: #000000;

        cursor: pointer;
    }
`;

const BalanceSection = styled.div`
    width: 80%;
    height: 100%;

    display: flex;
    justify-content: space-between;

    p {
        font-weight: 400;
        font-size: 15px;
        display: flex;
        align-items: center;
        text-align: center;

        color: rgba(0, 0, 0, 0.4);
    }
`;

const Section = styled.div`
    visibility: ${(props) => props.visibility};

    width: 35%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 100%;
`;
