import styled from "styled-components";

export default function AuthNavigation({ text, onClickFunc }) {
    return <AuthText onClick={onClickFunc}>{text}</AuthText>;
}

const AuthText = styled.div`
    width: 100%;

    margin-top: 10px;

    color: #a9a9a9;
    font-size: 14px;

    text-align: center;

    cursor: pointer;
`;
