import styled from "styled-components";

export const HeaderContainer = styled.div`
    width: 100%;

    padding: 30px 0px;
    padding-bottom: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;
