import Input from "../Input";
import { InputContainer } from "./InputContainer";

export default function NameStep({ state, setState }) {
    const stepData = {
        type: "name",
        text: "Digite o nome completo:",
        subText: "Idêntico ao seu documento de identificação.",
        state,
        setState,
    };

    return (
        <InputContainer>
            <Input
                text={stepData.text}
                type={stepData.type}
                state={stepData.state}
                setState={stepData.setState}
                subText={stepData.subText}
            />
        </InputContainer>
    );
}
