import { returnBackUrl } from "../../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import { useState } from "react";
import axios from "axios";

import CreateAndEditSection from "../../../components/Marketplace/CreateAndEditSection";
import ArrowAndLogoHeader from "../../../components/Headers/ArrowAndLogoHeader";
import AmountInput from "../../../components/Marketplace/Products/AmountInput";
import ImageInput from "../../../components/Marketplace/ImageInput";
import DateInput from "../../../components/Marketplace/DateInput";
import ErrorMessage from "../../../components/ErrorMessage";
import Container from "../../../components/Container";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

import logo from "../../../assets/angels_imagens/logo_azul_angels.svg";

export default function ExperienceCreate() {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState("");
    const [images, setImages] = useState([]);
    const [coverImage, setCoverImage] = useState([]);
    const [price, setPrice] = useState("");
    const [date, setDate] = useState();

    const [errorMessage, setErrorMessage] = useState("");
    const [requestIsLoading, setRequestIsLoading] = useState(false);

    function checkInputs() {
        if (!title) {
            setErrorMessage("O campo Nome é obrigatório.");
            return false;
        } else if (!description) {
            setErrorMessage("O campo Descrição é obrigatório.");
            return false;
        } else if (!amount) {
            setErrorMessage(
                "O campo Quantidade é obrigatório e deve ser maior do que 0."
            );
            return false;
        } else if (isNaN(amount)) {
            setErrorMessage("O campo Quantidade deve ser um número.");
            return false;
        } else if (coverImage.length === 0) {
            setErrorMessage("O campo Imagem de venda é obrigatório.");
            return false;
        } else if (images.length === 0) {
            setErrorMessage("O campo Imagem de pôster é obrigatório.");
            return false;
        } else if (!price) {
            setErrorMessage("O campo Preço é obrigatório.");
            return false;
        } else if (isNaN(price)) {
            setErrorMessage("O campo Preço deve ser um número.");
            return false;
        } else if (!date) {
            setErrorMessage("O campo Data é obrigatório.");
            return false;
        }

        return true;
    }

    function submitExperienceCreation() {
        setRequestIsLoading(true);
        setErrorMessage("");

        if (!checkInputs()) {
            setRequestIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("amount", amount);
        formData.append("coverImage", coverImage[0]);
        for (let i = 0; i < images.length; i++) {
            formData.append(`images`, images[i]);
        }
        formData.append("price", price);
        const ISODate = new Date(date).toISOString();
        formData.append("date", ISODate);

        const body = formData;

        const url = `${returnBackUrl()}/product/experience`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.post(url, body, config);

        request
            .then(() => {
                navigate("/sell");
            })
            .catch((error) => {
                setRequestIsLoading(false);
                setErrorMessage(error.response.data);
            });
    }

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    logo={logo}
                    color={"#095ece"}
                    onClickFunc={() => {
                        navigate("/sell");
                    }}
                />

                <CreateAndEditSection
                    productType={"experiência"}
                    navigateTo={"/experience/edit"}
                />

                <Divider />

                <Input
                    text="Nome"
                    subText="Utilize no máximo 28 caracteres"
                    type="text"
                    state={title}
                    setState={setTitle}
                    maxChar={28}
                />

                <Divider />

                <Input
                    text="Descrição"
                    subText="Utilize no máximo 160 caracteres"
                    type="text"
                    state={description}
                    setState={setDescription}
                    maxChar={160}
                />

                <Divider />

                <DateInput selectedDate={date} setSelectedDate={setDate} />

                <Divider />

                <AmountInputsContainer>
                    <h1>Quantidade</h1>
                    <h2>Informe a quantidade de vagas disponíveis.</h2>
                    <div>
                        <AmountInput
                            subText={"Vagas disponíveis"}
                            state={amount}
                            setState={setAmount}
                            flexDirection={"row"}
                        />
                    </div>
                </AmountInputsContainer>

                <Divider />

                <ImageInput
                    state={coverImage}
                    setState={setCoverImage}
                    text={"Imagem de venda"}
                    subText={"Selecione uma imagem em PNG com 440x200 px. "}
                    imageWidth={"440"}
                    imageHeight={"200"}
                />

                <Divider />

                <ImageInput
                    state={images}
                    setState={setImages}
                    text={"Imagem de pôster"}
                    subText={"Selecione até 3 imagens em PNG com 340x480 px. "}
                    imageWidth={"340"}
                    imageHeight={"480"}
                    imageAmount={3}
                />

                <Divider />

                <Input
                    text="Preço (LSB)"
                    subText="O preço deve ser em LSB"
                    type="number"
                    state={price}
                    setState={setPrice}
                />

                <Divider />

                <ErrorBox>
                    <ErrorMessage text={errorMessage} />
                </ErrorBox>

                <Button
                    text={
                        requestIsLoading ? (
                            <Bars height="35px" color="#ffffff" />
                        ) : (
                            "Publicar experiência"
                        )
                    }
                    onClickFunc={submitExperienceCreation}
                    background="#0069F4"
                    fontColor="#FFFFFF"
                    fontSize={"18px"}
                />

                <Divider />
            </Interface>
        </Container>
    );
}

const Interface = styled.div``;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 20px 0;
`;

const AmountInputsContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        justify-content: space-between;
    }

    > h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        /* or 175% */

        color: #000000;

        margin-bottom: 8px;
    }

    > h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        color: #a9a9a9;

        margin-bottom: 20px;
    }
`;

const ErrorBox = styled.div`
    margin: 20px 0;
`;
