import styled from "styled-components";

export default function AmountInput({
    size,
    subText,
    state,
    setState,
    flexDirection,
}) {
    return (
        <Container flexDirection={flexDirection}>
            <p>
                <span>{size + "\n"}</span>
                {subText}
            </p>
            <input
                type="number"
                value={state}
                onChange={(e) => setState(e.target.value)}
            />
        </Container>
    );
}

AmountInput.defaultProps = {
    flexDirection: "column",
    size: " ",
};

const Container = styled.div`
    width: ${(props) => (props.flexDirection === "row" ? "150px" : "72px")};
    height: ${(props) => (props.flexDirection === "row" ? "30px" : "75px;")};

    display: flex;
    flex-direction: ${(props) => props.flexDirection};
    align-items: center;
    justify-content: space-between;

    > p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;

        color: #000000;

        text-align: ${(props) =>
            props.flexDirection === "row" ? "left" : "center"};
        line-height: ${(props) =>
            props.flexDirection === "row" ? "14px" : "16px"};
        margin-bottom: 5px;
    }

    span {
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;
        /* or 88% */

        color: #000000;
    }

    > input {
        width: 67px;
        height: 32px;

        background: #ffffff;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
