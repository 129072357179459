import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

import { HeaderContainer } from "../Headers/HeaderContainer";

export default function ArrowAndLogoHeader({ logo, color, onClickFunc }) {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <ArrowBackContainer color={color} onClick={onClickFunc}>
                <IoIosArrowBack />
            </ArrowBackContainer>

            <ReactSVG
                onClick={() => navigate("/home")}
                src={logo}
                beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 64px;height:64px;");
                }}
            />
        </HeaderContainer>
    );
}

const ArrowBackContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    > svg {
        color: ${(props) => props.color};
        font-size: 20px;
    }
`;
