import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";

export default function SectionCard({
    image,
    text,
    background,
    fontColor,
    navigateTo,
}) {
    const navigate = useNavigate();

    function getBottom() {
        if (text.includes("vendas")) {
            return "-30px";
        }

        if (text.includes("Ativos")) {
            return "-12px";
        }

        return "10px";
    }

    return (
        <Container
            background={background}
            fontColor={fontColor}
            onClick={() => {
                navigate(navigateTo);
            }}
        >
            <Title>
                <h1>{text}</h1>
            </Title>
            <Body>
                <ReactSVG
                    src={image}
                    beforeInjection={(svg) => {
                        svg.setAttribute(
                            "style",
                            `width: 120px;height:120px; position: absolute; right:30px; bottom: ${getBottom()};`
                        );
                    }}
                />
            </Body>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;

    cursor: pointer;
    > div {
        background-color: ${(props) => props.background};
        color: ${(props) => props.fontColor};
    }
`;

const Title = styled.div`
    margin-bottom: 15px;

    width: 50%;
    height: 35px;

    display: flex;
    align-items: center;

    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
        0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
        0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
        0px 0px 0.92228px rgba(0, 0, 0, 0.04);
    border-radius: 11px;

    padding-left: 25px;

    > h1 {
        font-weight: 500;
        font-size: 18px;
    }
`;

const Body = styled.div`
    width: 100%;
    height: 84px;

    border-radius: 16px;

    position: relative;
`;
