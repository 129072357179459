import { Bars } from "react-loader-spinner";
import styled from "styled-components";

export default function SendOrder({ onClickFunc, sendOrderLoading, text }) {
    return (
        <SendOrderContainer onClick={onClickFunc}>
            {sendOrderLoading ? (
                <Bars height={"40px"} color="#ffffff" />
            ) : (
                <h3>{text}</h3>
            )}
        </SendOrderContainer>
    );
}

const SendOrderContainer = styled.div`
    width: 100%;
    max-width: 500px;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #0069f4;
    border-radius: 36px 36px 0 0;

    font-size: 20px;
    color: #ffffff;

    position: fixed;
    bottom: 0px;
    left: calc((100% - 500px) / 2);
    z-index: 2;

    cursor: pointer;

    @media (max-width: 500px) {
        left: 0;
    }
`;
