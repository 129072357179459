import { Bars } from "react-loader-spinner";
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import Modal from "react-modal";

import Button from "../Button";
import { DebounceInput } from "react-debounce-input";
import ErrorMessage from "../ErrorMessage";

export default function InputModal({
    modalIsOpen,
    setModalIsOpen,
    modalInfo,
    inputState,
    setState,
    errorMessage,
    isLoading,
}) {
    return (
        <Modal
            isOpen={modalIsOpen}
            overlayClassName="modal-overlay"
            className="modal-content"
        >
            <CloseModal>
                <div
                    onClick={() => {
                        setModalIsOpen(false);
                    }}
                >
                    <IoIosClose />
                </div>
            </CloseModal>

            <SVGContainer>
                <ReactSVG src={modalInfo.imageSrc} />
            </SVGContainer>

            <ModalText>
                <h1>{modalInfo.mainText}</h1>
                <h2>{modalInfo.subText}</h2>
                <DebounceInput
                    value={inputState}
                    onChange={(e) => setState(e.target.value)}
                    type="tel"
                />
                <ErrorMessage text={errorMessage} />
            </ModalText>

            <Buttons>
                <Button
                    onClickFunc={modalInfo.whiteButtonFunc}
                    background="#FFFFFF"
                    border="1px solid #0069F4;"
                    fontColor="#000000"
                    width={"40%"}
                    text={modalInfo.whiteButtonText}
                />
                <Button
                    onClickFunc={modalInfo.blueButtonFunc}
                    background="#0069F4"
                    border="1px solid #0069F4;"
                    fontColor="#ffffff"
                    width={"55%"}
                    text={
                        isLoading ? (
                            <Bars height="35px" color="#ffffff" />
                        ) : (
                            modalInfo.blueButtonText
                        )
                    }
                />
            </Buttons>
        </Modal>
    );
}

const CloseModal = styled.div`
    width: 100%;

    display: flex;
    justify-content: flex-end;

    cursor: pointer;

    svg {
        font-size: 35px;
        color: #051532;
    }
`;

const SVGContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalText = styled.div`
    width: 100%;

    text-align: center;

    > h1 {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        color: #051532;
    }

    > h2 {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        opacity: 0.6;
    }

    > input {
        width: 100%;
        height: 35px;

        font-size: 20px;
        color: #000000;

        border: none;
        border-bottom: 2px solid #000000;

        background-color: #ffffff;
    }

    > input:focus {
        outline: none;
    }
`;

const Buttons = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
`;
