import styled from "styled-components";

import Button from "../../Button";

export default function SelectProductType({ selected, setSelected }) {
    return (
        <Container>
            <h1>Tipo de produto</h1>
            <h2>Selecione o tipo de produto que você deseja criar.</h2>
            <div>
                <Button
                    text="Roupas"
                    onClickFunc={() => {
                        setSelected("clothing");
                    }}
                    background={selected === "clothing" ? "#0069F4" : "#ffffff"}
                    border={
                        selected === "clothing" ? "none" : "1px solid #0069F4"
                    }
                    fontColor={selected === "clothing" ? "#ffffff" : "#000000"}
                    width={"100px"}
                    height={"35px"}
                    borderRadius={"11px"}
                    fontSize={"14px"}
                    fontWeight={"400"}
                />
                <Button
                    text="Objetos"
                    onClickFunc={() => {
                        setSelected("objects");
                    }}
                    background={selected === "clothing" ? "#ffffff" : "#0069F4"}
                    border={
                        selected === "clothing" ? "1px solid #0069F4" : "none"
                    }
                    fontColor={selected === "clothing" ? "#000000" : "#ffffff"}
                    width={"100px"}
                    height={"35px"}
                    borderRadius={"11px"}
                    fontSize={"14px"}
                    fontWeight={"400"}
                />
            </div>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    > h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        /* or 175% */

        color: #000000;

        margin-bottom: 8px;
    }

    > h2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        color: #a9a9a9;
    }

    > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        padding: 25px 55px;
    }
`;
