import { returnBackUrl } from "../../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

import CourseCreateMain from "./CourseCreateMain";
import CourseCreateEpisodes from "./CourseCreateEpisodes";
import TwoButtonModal from "../../../components/Modals/TwoButtonModal";
import warning from "../../../assets/angels_imagens/confirmacao_de_acao.svg";

export default function CourseCreate() {
    const navigate = useNavigate();

    const [step, setStep] = useState(0);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [coverImage, setCoverImage] = useState([]);
    const [posterImage, setPosterImage] = useState([]);
    const [price, setPrice] = useState();
    const [trailerLink, setTrailerLink] = useState("");
    const [episodes, setEpisodes] = useState([
        { title: "", description: "", link: "" },
    ]);

    const [errorMessage, setErrorMessage] = useState("");
    const [requestIsLoading, setRequestIsLoading] = useState(false);

    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

    function publishCourse() {
        setConfirmModalIsOpen(false);
        setErrorMessage("");
        setRequestIsLoading(true);
        if (!checkInputs()) {
            setRequestIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("images", posterImage[0]);
        formData.append("coverImage", coverImage[0]);
        formData.append("title", name);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("trailer", trailerLink);
        formData.append("episodes", JSON.stringify(episodes));

        const body = formData;

        const url = `${returnBackUrl()}/product/course`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.post(url, body, config);

        request
            .then(() => {
                navigate("/sell");
            })
            .catch((error) => {
                setRequestIsLoading(false);
            });
    }

    function confirmPublishCourse() {
        setConfirmModalIsOpen(true);
    }

    function checkInputs() {
        if (!name) {
            setErrorMessage("O nome é obrigatório.");
            return false;
        } else if (!description) {
            setErrorMessage("A descrição é obrigatória.");
            setStep(0);
            return false;
        } else if (!coverImage) {
            setErrorMessage("A imagem de venda é obrigatória.");
            setStep(0);
            return false;
        } else if (!posterImage) {
            setErrorMessage("A imagem de pôster é obrigatória.");
            setStep(0);
            return false;
        } else if (!price || isNaN(price)) {
            setErrorMessage("O preço é obrigatório.");
            setStep(0);
            return false;
        } else if (!trailerLink) {
            setErrorMessage("O link do trailer é obrigatório.");
            setStep(0);
            return false;
        } else if (episodes.length === 0) {
            setErrorMessage("É necessário adicionar pelo menos um episódio.");
            return false;
        }

        episodes.forEach((episode, index) => {
            if (!episode.title) {
                setErrorMessage(
                    `O título do episódio ${index + 1} é obrigatório.`
                );
                return false;
            } else if (!episode.description) {
                setErrorMessage(
                    `A descrição do episódio ${index + 1} é obrigatória.`
                );
                return false;
            } else if (!episode.link) {
                setErrorMessage(
                    `O link do episódio ${index + 1} é obrigatório.`
                );
                return false;
            }
        });

        return true;
    }

    return (
        <>
            {step === 0 ? (
                <CourseCreateMain
                    name={name}
                    description={description}
                    coverImage={coverImage}
                    posterImage={posterImage}
                    price={price}
                    trailerLink={trailerLink}
                    setName={setName}
                    setDescription={setDescription}
                    setCoverImage={setCoverImage}
                    setPosterImage={setPosterImage}
                    setPrice={setPrice}
                    setTrailerLink={setTrailerLink}
                    setStep={setStep}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            ) : (
                <CourseCreateEpisodes
                    setEpisodes={setEpisodes}
                    setStep={setStep}
                    episodes={episodes}
                    confirmPublishCourse={confirmPublishCourse}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    requestIsLoading={requestIsLoading}
                />
            )}

            <TwoButtonModal
                modalIsOpen={confirmModalIsOpen}
                setModalIsOpen={setConfirmModalIsOpen}
                modalInfo={{
                    imageSrc: warning,
                    mainText: "Deseja publicar seu curso?",
                    subText:
                        "Certifique-se de ter adicionado todos os episódios antes de confirmar a publicação do seu curso.",
                    blueButtonFunc: () => {
                        setConfirmModalIsOpen(false);
                    },
                    blueButtonText: "Voltar",
                    whiteButtonFunc: () => {
                        publishCourse();
                    },
                    whiteButtonText: "Publicar",
                }}
            />
        </>
    );
}
