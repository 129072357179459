import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import AuthNavigation from "../../components/Navigation";
import Button from "../../components/Button";
import Input from "../../components/Input";

export default function RequestCode({
    email,
    setEmail,
    isLoading,
    requestCode,
}) {
    const navigate = useNavigate();

    return (
        <Form>
            <h1>Esqueceu sua senha?</h1>
            <Input
                text="Informe o seu e-mail"
                subText="Você receberá um código para redefinir sua senha."
                type="text"
                state={email}
                setState={setEmail}
            />
            <Button
                text={
                    isLoading ? (
                        <Bars height="35px" color="#ffffff" />
                    ) : (
                        "Enviar"
                    )
                }
                onClickFunc={requestCode}
                background={"#0069F4"}
                fontColor={"#fff"}
            />

            <AuthNavigation
                text="Lembrou sua senha? Entre agora!"
                onClickFunc={() => navigate("/")}
            />
        </Form>
    );
}

const Form = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 22px;
        font-weight: 700;
        color: #000;
        margin-top: 100px;
        margin-bottom: 40px;
    }
`;
