import { returnBackUrl } from "../../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import { useState } from "react";
import axios from "axios";

import AmountInput from "../../../components/Marketplace/Products/AmountInput";
import ImageInput from "../../../components/Marketplace/ImageInput";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";

export default function ProductClothingCreate() {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [pAmount, setPAmount] = useState(0);
    const [mAmount, setMAmount] = useState(0);
    const [gAmount, setGAmount] = useState(0);
    const [ggAmount, setGGAmount] = useState(0);
    const [coverImage, setCoverImage] = useState([]);
    const [images, setImages] = useState([]);
    const [price, setPrice] = useState("");
    const [ncm, setNcm] = useState("");

    const [requestIsLoading, setRequestIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function checkInputs() {
        if (!title) {
            setErrorMessage("O campo Nome é obrigatório.");
            return false;
        } else if (!description) {
            setErrorMessage("O campo Descrição é obrigatório.");
            return false;
        } else if (!pAmount) {
            setErrorMessage("O campo Quantidade do tamanho P é obrigatório.");
            return false;
        } else if (isNaN(pAmount)) {
            setErrorMessage(
                "O campo Quantidade do tamanho P deve ser um número."
            );
            return false;
        } else if (!mAmount) {
            setErrorMessage("O campo Quantidade do tamanho M é obrigatório.");
            return false;
        } else if (isNaN(mAmount)) {
            setErrorMessage(
                "O campo Quantidade do tamanho M deve ser um número."
            );
            return false;
        } else if (!gAmount) {
            setErrorMessage("O campo Quantidade do tamanho G é obrigatório.");
            return false;
        } else if (isNaN(gAmount)) {
            setErrorMessage(
                "O campo Quantidade do tamanho G deve ser um número."
            );
            return false;
        } else if (!ggAmount) {
            setErrorMessage("O campo Quantidade do tamanho GG é obrigatório.");
            return false;
        } else if (isNaN(ggAmount)) {
            setErrorMessage(
                "O campo Quantidade do tamanho GG deve ser um número."
            );
            return false;
        } else if (coverImage.length === 0) {
            setErrorMessage("O campo Imagem de venda é obrigatório.");
            return false;
        } else if (images.length === 0) {
            setErrorMessage("O campo Imagem de pôster é obrigatório.");
            return false;
        } else if (!price) {
            setErrorMessage("O campo Preço é obrigatório.");
            return false;
        } else if (isNaN(price)) {
            setErrorMessage("O campo Preço deve ser um número.");
            return false;
        } else if (!ncm) {
            setErrorMessage("O campo Código NCM é obrigatório.");
            return false;
        }

        return true;
    }

    function submitProductClothingCreation() {
        setRequestIsLoading(true);
        setErrorMessage("");

        if (!checkInputs()) {
            setRequestIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("amountP", pAmount);
        formData.append("amountM", mAmount);
        formData.append("amountG", gAmount);
        formData.append("amountGG", ggAmount);
        formData.append("coverImage", coverImage[0]);
        for (let i = 0; i < images.length; i++) {
            formData.append(`images`, images[i]);
        }
        formData.append("price", price);
        formData.append("ncm", ncm);

        const body = formData;

        const url = `${returnBackUrl()}/product/clothing`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const request = axios.post(url, body, config);

        request
            .then(() => {
                navigate("/sell");
            })
            .catch((error) => {
                setRequestIsLoading(false);
                setErrorMessage(error.response.data);
            });
    }

    return (
        <Container>
            <Input
                text="Nome"
                subText="Utilize no máximo 28 caracteres"
                type="text"
                state={title}
                setState={setTitle}
                maxChar={28}
            />

            <Divider />

            <Input
                text="Descrição"
                subText="Utilize no máximo 160 caracteres"
                type="text"
                state={description}
                setState={setDescription}
                maxChar={160}
            />

            <Divider />

            <AmountInputsContainer>
                <h1>Tamanhos e quantidades</h1>
                <h2>Informe a quantidade disponível por tamanho.</h2>
                <div>
                    <AmountInput
                        size={"P"}
                        subText={"Quantidade"}
                        state={pAmount}
                        setState={setPAmount}
                    />
                    <AmountInput
                        size={"M"}
                        subText={"Quantidade"}
                        state={mAmount}
                        setState={setMAmount}
                    />
                    <AmountInput
                        size={"G"}
                        subText={"Quantidade"}
                        state={gAmount}
                        setState={setGAmount}
                    />
                    <AmountInput
                        size={"GG"}
                        subText={"Quantidade"}
                        state={ggAmount}
                        setState={setGGAmount}
                    />
                </div>
            </AmountInputsContainer>

            <Divider />

            <ImageInput
                state={coverImage}
                setState={setCoverImage}
                text={"Imagem de venda"}
                subText={"Selecione uma imagem em PNG com 440x200 px. "}
                imageWidth={"440"}
                imageHeight={"200"}
            />

            <Divider />

            <ImageInput
                state={images}
                setState={setImages}
                text={"Imagem de pôster"}
                subText={"Selecione até 3 imagens em PNG com 340x480 px. "}
                imageWidth={"340"}
                imageHeight={"480"}
                imageAmount={3}
            />

            <Divider />

            <Input
                text="Preço (LSB)"
                subText="O preço deve ser em LSB"
                type="number"
                state={price}
                setState={setPrice}
            />

            <Divider />

            <Input
                text="Código NCM"
                subText="Este código é específico para cada tipo de produto. Caso você não saiba, consulte o seu departamento financeiro ou de contabilidade"
                type="text"
                state={ncm}
                setState={setNcm}
            />

            <ErrorBox>
                <ErrorMessage text={errorMessage} />
            </ErrorBox>

            <Button
                text={
                    requestIsLoading ? (
                        <Bars height="35px" color="#ffffff" />
                    ) : (
                        "Publicar produto"
                    )
                }
                onClickFunc={submitProductClothingCreation}
                background="#0069F4"
                fontColor="#FFFFFF"
                fontSize={"18px"}
            />

            <Divider />
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 20px 0;
`;

const AmountInputsContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        justify-content: space-between;
    }

    > h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        /* or 175% */

        color: #000000;

        margin-bottom: 8px;
    }

    > h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */

        color: #a9a9a9;

        margin-bottom: 20px;
    }
`;

const ErrorBox = styled.div`
    margin: 20px 0;
`;
