import { useState, useEffect, useContext } from "react";
import { DebounceInput } from "react-debounce-input";
import { returnBackUrl } from "../../utils/backUrl";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import styled from "styled-components";
import axios from "axios";

import logo from "../../assets/angels_imagens/logo_preta_angels.svg";
import email from "../../assets/angels_imagens/popups/verificacao_email.svg";

import SignInContext from "../../contexts/SignInContext";

import LogoAndMenuHeader from "../../components/Headers/LogoAndMenuHeader";
import TextBox from "../../components/DepositAndWithdraw/TextBox";
import Container from "../../components/Container";
import ErrorMessage from "../../components/ErrorMessage";
import Button from "../../components/Button";
import OneButtonModal from "../../components/Modals/OneButtonModal";

export default function Profile() {
    const navigate = useNavigate();
    const { isLogged } = useContext(SignInContext);

    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isLogged) {
            navigate("/");
        }
    }, []);

    const handleDateChange = (e, setDate) => {
        let value = e.target.value;
        const onlyNumbers = value.replace(/[^\d]/g, "");
        const formattedValue = [];

        for (let i = 0; i < onlyNumbers.length; i++) {
            if (i === 2 || i === 4) {
                formattedValue.push("/");
            }
            formattedValue.push(onlyNumbers[i]);
        }

        setDate(formattedValue.join(""));
    }; //tks GPT4

    function submitDataExport() {
        setErrorMessage("");

        if (!checkDateInputs()) {
            return;
        }

        setIsLoading(true);
        const initial = parseBrazilianDate(initialDate, "initial");
        const final = parseBrazilianDate(finalDate, "final");

        const url = `${returnBackUrl()}/financialData`;
        const { token } = JSON.parse(localStorage.getItem("userData"));
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const body = {
            startDate: initial,
            endDate: final,
        };

        const exportDataRequest = axios.post(url, body, config);
        exportDataRequest
            .then((response) => {
                setModalIsOpen(true);
                setIsLoading(false);
            })
            .catch((error) => {
                setErrorMessage(error.response.data);
                setIsLoading(false);
            });
    }

    function checkDateInputs() {
        const initial = parseBrazilianDate(initialDate, "initial");
        const final = parseBrazilianDate(finalDate, "final");

        if (!initial || !final) {
            setErrorMessage("Utilize o formato dd/mm/aaaa");
            return false;
        }

        if (initial >= final) {
            setErrorMessage("A data inicial deve ser anterior a data final");
            return false;
        }

        return { initial, final };
    }

    function parseBrazilianDate(dateString, dateType) {
        const [day, month, year] = dateString.split("/").map(Number);

        if (!day || !month || !year || year.toString().length !== 4) {
            setErrorMessage("Utilize o formato dd/mm/aaaa");
            return;
        }
        if (dateType === "initial") {
            return new Date(year, month - 1, day, 0, 0, 0);
        }

        return new Date(year, month - 1, day, 23, 59, 59);
    }

    return (
        <Container>
            <Interface>
                <LogoAndMenuHeader
                    logo={logo}
                    color={"#ffffff"}
                    background={"#000000"}
                />
                <TextBox width="100%" height="66px">
                    Extrato de transações
                </TextBox>

                <DateInputs>
                    <div>
                        <TextBox>Data inicial</TextBox>
                        <Input>
                            <DebounceInput
                                type="tel"
                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                value={initialDate}
                                onChange={(e) =>
                                    handleDateChange(e, setInitialDate)
                                }
                            />
                        </Input>
                    </div>
                    <div>
                        <TextBox>Data final</TextBox>
                        <Input>
                            <DebounceInput
                                type="tel"
                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                value={finalDate}
                                onChange={(e) =>
                                    handleDateChange(e, setFinalDate)
                                }
                            />
                        </Input>
                    </div>
                </DateInputs>
                <ErrorMessage text={errorMessage} />

                <Button
                    text={
                        isLoading ? (
                            <Bars height="35px" color="#ffffff" />
                        ) : (
                            "SOLICITAR EXTRATO"
                        )
                    }
                    onClickFunc={submitDataExport}
                    background={"#0069F4"}
                    border={"#0069F4"}
                    fontColor={"#ffffff"}
                    width={"100%"}
                    height={"66px"}
                    borderRadius={"12px"}
                />
            </Interface>

            <OneButtonModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                modalInfo={{
                    imageSrc: email,
                    mainText: "Solicitação confirmada",
                    subText:
                        "Sua solicitação foi confirmada. Você receberá no seu e-mail cadastrado o seu extrato de transações.",
                    buttonText: "Voltar para a home",
                    buttonWidth: "80%",
                    buttonActionFunc: () => {
                        navigate("/home");
                    },
                }}
            />
        </Container>
    );
}

const Interface = styled.div`
    width: 100%;
    height: 65vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const DateInputs = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: center;

        width: 40%;
    }
`;

const Input = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > input {
        width: 100%;
        height: 40px;

        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        padding-left: 10px;
        font-size: 16px;

        display: flex;
        align-items: center;
    }

    > input:focus {
        outline: none;
    }
`;
