import logo from "../../assets/angels_imagens/logo_azul_angels.svg";
import { HeaderContainer } from "../Headers/HeaderContainer";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

export default function InsideBenefitHeader({ benefit }) {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <HeaderStyles>
                <IoIosArrowBack
                    onClick={() => {
                        navigate("/benefits");
                    }}
                />
            </HeaderStyles>

            <HeaderStyles>
                <h1>{benefit.title ? benefit.title : ""}</h1>
            </HeaderStyles>

            <HeaderStyles>
                <ReactSVG
                    src={logo}
                    beforeInjection={(svg) => {
                        svg.setAttribute("style", "width: 64px;height:64px;");
                    }}
                />
            </HeaderStyles>
        </HeaderContainer>
    );
}

const HeaderStyles = styled.div`
    > svg {
        color: #0069f4;
        font-size: 24px;
    }

    > h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;

        color: #000000;
    }
`;
