import styled from "styled-components";

const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        padding: 0 18px;
        width: 100%;
        max-width: 500px;
    }
`;

export default Container;
