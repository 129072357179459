import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { useRef, useState } from "react";
import styled from "styled-components";

import BenefitsHomeCard from "./BenefitsHomeCard";

export default function BenefitCarousel({ benefits, title }) {
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const defaultScrollAmount = 400;

    const [productOnFocus, setProductOnFocus] = useState(0);

    const scroll = (direction) => {
        if (direction === "left") {
            carouselRef.current.scrollBy({
                left: -defaultScrollAmount,
                behavior: "smooth",
            });
            setProductOnFocus((prevState) => {
                if (prevState > 0) {
                    return prevState - 1;
                } else {
                    return prevState;
                }
            });
        } else {
            carouselRef.current.scrollBy({
                left: defaultScrollAmount,
                behavior: "smooth",
            });
            setProductOnFocus((prevState) => {
                if (prevState !== benefits.length - 1) {
                    return prevState + 1;
                } else {
                    return prevState;
                }
            });
        }
    }; //tks GPT4

    function getButtonHiddenClass(direction) {
        if (!benefits || benefits.length === 0) return "hidden";

        if (direction === "left" && productOnFocus === 0) {
            return "hidden";
        }

        if (direction === "right" && productOnFocus === benefits.length - 1) {
            return "hidden";
        }

        return "";
    }

    return (
        <BenefitsContainer>
            <ProductsTitle>
                <div>{title}</div>
            </ProductsTitle>
            {benefits ? (
                <div className="carousel-container">
                    <button
                        className={`carousel-button carousel-button-left black-button ${getButtonHiddenClass(
                            "left"
                        )}`}
                        onClick={() => scroll("left")}
                    >
                        &lt;
                    </button>

                    <div className="carousel" ref={carouselRef}>
                        {benefits.length > 0 ? (
                            benefits.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <BenefitsHomeCard
                                            marginRight={
                                                index === benefits.length - 1
                                                    ? "0"
                                                    : "10px"
                                            }
                                        >
                                            <img
                                                src={item.coverImageURL}
                                                onClick={() =>
                                                    navigate(
                                                        `/benefits/${item.type}/${item.id}`
                                                    )
                                                }
                                            />
                                        </BenefitsHomeCard>
                                    </div>
                                );
                            })
                        ) : (
                            <>Nenhum produto encontrado</>
                        )}
                    </div>

                    <button
                        className={`carousel-button carousel-button-right black-button ${getButtonHiddenClass(
                            "right"
                        )}`}
                        onClick={() => scroll("right")}
                    >
                        &gt;
                    </button>
                </div>
            ) : (
                <Bars height="35px" color="#ffffff" />
            )}
        </BenefitsContainer>
    );
}

const BenefitsContainer = styled.div`
    width: 340px;
`;

const ProductsTitle = styled.div`
    width: 340px;

    display: flex;
    justify-content: flex-start;

    > div {
        width: 152px;
        height: 39px;

        margin: 40px 0 20px 0;
        padding-left: 30px;

        background: #f1f0f5;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 22.1347px 29.513px rgba(0, 0, 0, 0.04),
            0px 14.7565px 22.1347px rgba(0, 0, 0, 0.04),
            0px 3.68912px 7.37824px rgba(0, 0, 0, 0.04),
            0px 0px 0.92228px rgba(0, 0, 0, 0.04);
        border-radius: 11px;

        display: flex;
        align-items: center;

        font-weight: 600;
        font-size: 19px;
        line-height: 25px;

        color: #000000;
    }
`;
