import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";

import logo from "../../../assets/angels_imagens/logo_azul_angels.svg";

import CreateAndEditSection from "../../../components/Marketplace/CreateAndEditSection";
import ArrowAndLogoHeader from "../../../components/Headers/ArrowAndLogoHeader";
import Container from "../../../components/Container";
import Input from "../../../components/Input";
import ImageInput from "../../../components/Marketplace/ImageInput";
import Button from "../../../components/Button";
import ErrorMessage from "../../../components/ErrorMessage";

export default function CourseCreateMain({
    name,
    setName,
    description,
    setDescription,
    coverImage,
    setCoverImage,
    posterImage,
    setPosterImage,
    price,
    setPrice,
    trailerLink,
    setTrailerLink,
    setStep,
    errorMessage,
    setErrorMessage,
}) {
    const navigate = useNavigate();

    function checkInputsAndGoToNextStep() {
        setErrorMessage("");

        if (!name) {
            setErrorMessage("O nome é obrigatório.");
            return;
        } else if (!description) {
            setErrorMessage("A descrição é obrigatória.");
            return;
        } else if (!coverImage) {
            setErrorMessage("A imagem de venda é obrigatória.");
            return;
        } else if (!posterImage) {
            setErrorMessage("A imagem de pôster é obrigatória.");
            return;
        } else if (!price) {
            setErrorMessage("O preço é obrigatório.");
            return;
        } else if (!trailerLink) {
            setErrorMessage("O link do trailer é obrigatório.");
            return;
        }

        setStep(1);
    }

    return (
        <Container>
            <Interface>
                <ArrowAndLogoHeader
                    onClickFunc={() => navigate("/sell")}
                    color={"#095ece"}
                    logo={logo}
                />
                <CreateAndEditSection
                    productType={"curso"}
                    navigateTo={"/course/edit"}
                />

                <Input
                    text={"Nome"}
                    subText={"Utilize no máximo 28 caracteres"}
                    type={"text"}
                    state={name}
                    setState={setName}
                    placeholder={""}
                    maxChar={28}
                />

                <Divider />

                <Input
                    text={"Descrição"}
                    subText={"Utilize no máximo 160 caracteres."}
                    type={"text"}
                    state={description}
                    setState={setDescription}
                    placeholder={""}
                    maxChar={160}
                />

                <Divider />

                <ImageInput
                    state={coverImage}
                    setState={setCoverImage}
                    text={"Imagem de venda"}
                    subText={"Selecione uma imagem em PNG com 440x200 px. "}
                    imageWidth={"440"}
                    imageHeight={"200"}
                />

                <Divider />

                <ImageInput
                    state={posterImage}
                    setState={setPosterImage}
                    text={"Imagem de pôster"}
                    subText={"Selecione uma imagem em PNG com 340x480 px. "}
                    imageWidth={"340"}
                    imageHeight={"480"}
                />

                <Divider />

                <Input
                    text={"Preço (LSB)"}
                    subText={"O preço deve ser em LSB."}
                    type={"tel"}
                    state={price}
                    setState={setPrice}
                    placeholder={""}
                />

                <Divider />

                <Input
                    text={"Link do trailer"}
                    subText={
                        "Carregue o vídeo no Youtube, coloque como não listado e cole o link abaixo. A thumbnail do Youtube será a capa do seu vídeo."
                    }
                    type={"text"}
                    state={trailerLink}
                    setState={setTrailerLink}
                    placeholder={""}
                />

                <ErrorBox>
                    <ErrorMessage text={errorMessage} />
                </ErrorBox>

                <Button
                    text="Próximo"
                    onClickFunc={checkInputsAndGoToNextStep}
                    background="#0069F4"
                    border="1px solid #0069F4"
                    fontColor="#FFFFFF"
                />

                <Divider />
            </Interface>
        </Container>
    );
}

const Interface = styled.div``;

const Divider = styled.div`
    width: 100%;
    height: 1px;

    margin: 20px 0;
`;

const ErrorBox = styled.div`
    margin: 20px 0;
`;
