import Input from "../Input";
import { InputContainer } from "./InputContainer";

export default function PhoneStep({ state, setState }) {
    const stepData = {
        type: "tel",
        text: "Informe o seu telefone:",
        subText: "Nós entraremos em contato com você através dele.",
        state,
        setState,
    };

    return (
        <InputContainer>
            <Input
                text={stepData.text}
                type={stepData.type}
                state={stepData.state}
                setState={stepData.setState}
                subText={stepData.subText}
                placeholder="(00) 00000-0000"
            />
        </InputContainer>
    );
}
